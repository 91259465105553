import React, { memo, useReducer, MemoExoticComponent, FunctionComponent, useState, useEffect, useRef, useCallback } from 'react';
import { chan, fixed, go, IChan, IStream, putAsync } from 'csp-with-ts';
import { compose, map } from 'transducist';
import { observer } from 'mobx-react-lite';
import { Button } from '@material-ui/core';
import useStyles from './MaterialFormPopupStyles';
import { IFormDropdownInputProps } from '../../components/filter/FormDropdown';
import { IDateTimeInputProps } from '../../components/FormInputs/DateTimeInput';
import { IGenericInputProps } from '../../components/FormInputs/GenericInput';
import { startProcessByDefinitionKey } from '../../api/transactionServer';
import { convertToISO, formEditReducer } from '../../utils/utils';
import GenericModal from '../GenericModal';
import LocationPicker from '../locationPicker/LocationPicker';
import { GET_BLOCK_UNITS, GET_FLOOR_UNITS, GET_UNIT_UNITS } from '../../utils/queries';
import { useGetState } from '../../components/filter/UseAccordionHook';
import { CloseButton, Button as MantineButton, Chip, Badge } from '@mantine/core'
import { IFormField, ITicketType, IUser } from '../../models/Ticket';
import AssigneeList from '../../components/assignees/AssigneeList';
import { IMSTMap, IAnyType } from 'mobx-state-tree';
import { IconX } from "@tabler/icons-react";
import RichTextDescription from '../../components/richText/RichTextDescInput'
import { transformMap } from '../../utils/constants';
import { IMultiSelectDropdownInputProps } from '../../components/FormInputs/MultiSelectDropdownInput';


function sanitizeState(fields: IFormField[], state: { [K: string]: any; }) {
  return fields.reduce((acc, { id, type }) => (state[id] && Array.isArray(state[id]) && !state[id].length) ? acc : ({ ...acc, [id]: state[id] ? transformMap(type)['transform'] ? transformMap(type)['transform'](state[id]) : state[id] : "" }), {});
}

const init = (fields): { [K: string]: string; } => Object.keys(fields).reduce((acc, k) => ({ ...acc, [k]: "" }), {}) as { [K: string]: string; };
const init2 = (fields) => {
  return fields.reduce((acc, { id, type }) => ({ ...acc, [id]: type === "upload" ? [] : "" }), {});
};

function simpleReducer(state: { [K: string]: string; }, action: { type: string; payload: { field: string; input: string; type: string }; }) {
  return { ...state, [action.payload.field]: action.payload.type === "date" ? convertToISO(action.payload.input) : action.payload.input };
}

const simpleStartFormReducer = (state, action) => action.type === 'RESET' ? { ...action.payload } : { ...state, [action.payload.field]: action.payload.type === "date" ? convertToISO(action.payload.input) : action.payload.input };


const Icon = memo(({ onRemove }: { onRemove: () => void; }) =>
  <div
    style={{
      display: "flex",
    }}
    onMouseDown={onRemove}
  >
    <IconX size={14} />
  </div >);



export interface INewTicketPopup {
  properties: string[];
  /* types: IProcessDefinition[]; */
  lg?: boolean;
  onClose(): void;
  userId: string;
  customerId: string;
  refreshTickets(): Promise<any>;
  projectId: string;
  priorities: { id: string; name: string; }[];
  coreFields: { [K: string]: any; };
  ticketType: ITicketType;
  allowedWatchers: IUser[];
  watchersMap: IMSTMap<IAnyType>;
  categories: string[];
};

export interface IDropdownOptionItem {
  id: string,
  name: string
}

export interface ICustomerDropdownData {
  [K: string]: IDropdownOptionItem[]
}

function isDisabled(state: { [K: string]: string | string[]; }, fields: { [K: string]: any; }): boolean {
  if (Object.keys(fields).filter(k => fields[k].isMandatory == true).every(f => state[f]?.length)) { return false; }
  return true;
}

async function startProcess({ customerId, userId, state, processDefinitionKey, projectId, startForm, locationState, watchers }: { processDefinitionKey: string; customerId: string; userId: string; state: { [K: string]: string; }; projectId: string; startForm?: { [K: string]: any; }; locationState: { id: string; name: string; }[]; watchers: string[]; }) {
  /* createdAt: moment().toISOString()  */
  try {
    return await startProcessByDefinitionKey(startForm ? { userId, tenant: customerId, processDefinitionKey, projectId, processDetails: { ...state, type: processDefinitionKey, location: locationState.map(({ id }) => id), watchers }, processStartForm: startForm, } : { userId, tenant: customerId, processDefinitionKey, projectId, processDetails: { ...state, type: processDefinitionKey, location: locationState.map(({ id }) => id), watchers } });
  }
  catch (err) {
    console.error(err);
  }
}

function useSplit<T extends IStream, S extends IStream, R extends IStream>(ch: IChan<T>, p: (val: T) => boolean, tValMap: (val: T) => S, fValMap: (val: T) => R) {
  const tch = useRef<IChan<T, S>>(chan(fixed(1), compose(map(tValMap))) as IChan<T, S>);
  const fch = useRef<IChan<T, R>>(chan(fixed(1), compose(map(fValMap))) as IChan<T, R>);
  useEffect(() => {
    const proc = go`<! ${ch} ${function* split() {
      while (true) {
        const val: T = yield; if (p(val)) { putAsync<T, S>(tch.current, val); } else { putAsync<T, R>(fch.current, val); }
      }
    }}`;
    return () => { proc(); ch.close(); tch.current.close(); fch.current.close(); };
  }, []);
  return [tch.current, fch.current];
}

const partitioner = ({ type }) => type === "state";
const payloader = ({ payload }) => payload;

export const extractOptionExpressionName = (optionsExpression: string | undefined): string => {
  const variableString = optionsExpression && optionsExpression.match(/\$(.*)/) || "" //$customerList
  return variableString && variableString[1]
}

const panels = [
  { id: 'block', name: 'Block', query: GET_BLOCK_UNITS, queryVariable: "block", optionValue: "block" },
  { id: 'floor', name: 'Floor', query: GET_FLOOR_UNITS, queryVariable: "floor", optionValue: "floor" },
  { id: 'unit', name: 'Unit', query: GET_UNIT_UNITS, queryVariable: "" }];

const NewTicketPopup = ({ ticketType, lg, onClose, userId, customerId, refreshTickets, projectId, priorities, properties, coreFields, allowedWatchers, watchersMap, categories }: INewTicketPopup) => {
  const [state, dispatch] = useReducer(simpleReducer, coreFields, init);
  const [startFormState, startFormDispatch] = useReducer(formEditReducer, ticketType.startForm.slice(), init2);
  const [watchers, setWatchers] = useState<string[]>([]);
  const classes = useStyles({ isLg: !!lg });
  const [toggle, setToggle] = useState<{ isOpen: boolean; variant: 'watchers' | 'location' }>({ isOpen: false, variant: 'watchers' });
  const ch = useRef(chan<{ type: string; payload: any; }>());
  const [ch1, ch2] = useSplit<{ type: string; payload: any; }, any, any>(ch.current, partitioner, payloader, payloader);
  const locationState = useGetState(ch1);
  const currentPanel = useGetState(ch2);
  const disabled = isDisabled({ ...state, ...sanitizeState(ticketType.startForm.slice() || [], startFormState), location: locationState, watchersCC: watchers, }, { ...coreFields, ...(ticketType.startForm.slice() || []).reduce((acc, { id, required, ...rest }) => ({ ...acc, [id]: { id, isMandatory: required, ...rest } }), {}) });
  const handleAssigneeUser = useCallback((userId: string) => {
    setWatchers(st => {
      const ind = st.indexOf(userId);
      return ind !== -1 ? st.slice(0, ind).concat(st.slice(Math.min(ind + 1, st.length))) :
        [...st, userId];
    });
  }, [allowedWatchers]);
  useEffect(() => {
    ticketType
      .setStartForm()
    /* .then(() => {
     *   startFormDispatch({ type: 'RESET', payload: ticketType.startForm.slice() });
     * }); */
  }, []);

  return (
    <div className={classes.mainDiv}>
      <GenericModal
        isOpen={toggle.isOpen}
        title={toggle.variant === 'watchers' ? "Add Watchers" : `Location Picker ${currentPanel ? `— Now Selecting ${currentPanel}` : ""}`}
        onClose={() => { setToggle(t => ({ ...t, isOpen: false })); }}
        actions={[
          { type: "Close", onClick: () => { setToggle(t => ({ ...t, isOpen: false })); } }]}
      >
        {toggle.variant === "watchers" ?
          (
            <AssigneeList
              userId=""
              handleAssigneeUser={handleAssigneeUser}
              options={allowedWatchers}
              multiSelect={true}
              state={watchers}
            />)
          :
          (
            <LocationPicker
              ch={ch.current}
              panels={panels}
              init={locationState || []}
            />
          )
        }
      </GenericModal>
      <div className={classes.newRequestPopupTitleDiv}>
        <span className={classes.newRequestPopupTitle}>New Request - {ticketType.title}</span>
        {/* <div className={classes.newRequestPopupClose} onClick={onClose}><span className={classes.closeHover}>Close</span></div> */}
        <div className={classes.newRequestPopupClose}><CloseButton color='red' title='Close' onClick={onClose} /></div>
      </div>
      <div className={classes.handleContainer} style={{ borderLeft: '0.01em solid #999999', borderRight: '0.01em solid #999999', background: "#F6F6F6", padding: '1em 4em' }}>
        <div className={classes.handleScrollLayer} style={{ marginBottom: '10%' }}>
          {Object.entries(coreFields).map(([k, { name, type, description, isMandatory, ...rest }]) => {
            /* Hardcode status to be always hidden. Maybe add in check for adhoc
               if we want to choose status in the start form */
            if (!!rest.skip) { return null; }
            if (k === "processStatus") { return null; }
            if (!!rest.hidden && !rest.isMandatory) { return null; }
            if (type === "dropdown" || type === "radio-buttons" || type === "multiselect") {
              // TODO: Enable form dropdown to work properly with id,name tuples.
              const Component: MemoExoticComponent<FunctionComponent<IFormDropdownInputProps>> = rest.Component as unknown as MemoExoticComponent<FunctionComponent<IFormDropdownInputProps>>;
              /* options={k === "property" ? properties : k === "type" ? types : []} */
              return (
                <div key={k} className={classes.fieldData}>
                  {/* <div className={classes.ActivityName}>
                      {name.toUpperCase()}
                      </div> */}
                  <Component
                    field={k}
                    description={description}
                    dispatch={dispatch}
                    state={state[k]}
                    options={k === "priority" ? priorities : k === "propertyId" ? properties : k === "category" ? categories : []}
                    label={name}
                    disabled={false}
                    required={isMandatory}
                    size='xs'
                    icon={coreFields[k] && coreFields[k].icon || null}
                    overrideSort={k === "priority" }
                  />
                </div>
              );
            }
            else {
              const Component: MemoExoticComponent<FunctionComponent<IGenericInputProps | IDateTimeInputProps>> = rest.Component as MemoExoticComponent<FunctionComponent<IGenericInputProps | IDateTimeInputProps>>;
              if (k == 'description') {
                return (
                  <div key={k} className={classes.fieldData}>
                    <RichTextDescription
                      field={k}
                      description={description}
                      dispatch={dispatch}
                      state={state[k]}
                      label={name}
                      disabled={false}
                      type={type}
                      required={isMandatory}
                      size='xs'
                      icon={coreFields[k] && coreFields[k].icon || null}
                      bubbleToolbar={true}
                      placeholder={"description"}
                    />
                  </div>
                )
              }
              else {
                return (
                  <div key={k} className={classes.fieldData}>
                    {/* <div className={classes.ActivityName}>
                        {name.toUpperCase()}
                        </div> */}
                    <Component
                      field={k}
                      description={description}
                      dispatch={dispatch}
                      state={state[k]}
                      label={name}
                      disabled={false}
                      type={type}
                      required={isMandatory}
                      isDueDate={true}
                      size='xs'
                      icon={coreFields[k] && coreFields[k].icon || null}
                    />
                  </div>
                );
              }
            }
          })}
          {!!coreFields["watchersCC"]?.hidden ? null :
            <div className={classes.fieldData}>
              <div
                style={{ marginTop: '0.5em', marginBottom: '0.5em', fontSize: '0.8525em', color: '#585858', fontWeight: 600 }}
              >
                {coreFields["watchersCC"]?.name || "Add Watchers (CC):"} {!!coreFields["watchersCC"]?.isMandatory ? <span className={classes.requiredAsterisk}> *</span> : null}
              </div>
              <span style={{ display: "flex", flexWrap: "wrap" }}>{
                watchers?.map((id) => <Badge
                  key={id}
                  size={"md"}
                  styles={{
                    rightSection: {
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                      borderRadius: 15,
                      borderWidth: 1,
                      borderStyle: "solid",
                      borderColor: "#9D9D9D",
                      "&:hover": {
                        borderRadius: 15,
                        borderColor: "#FFF",
                        backgroundColor: "#9D9D9D",
                      }
                    }
                  }}
                  rightSection={
                    <Icon
                      onRemove={() => { setWatchers(st => st.filter(v => v !== id)) }}
                    />}
                >
                  {watchersMap.get(id)?.name || id}
                </Badge>)
              }
              </span>
              <MantineButton variant="default" onClick={() => { setToggle(() => ({ isOpen: true, variant: 'watchers' })); }} size='xs' color={"#585858"} sx={{ fontSize: '0.7525em' }}>
                Select People to notify
              </MantineButton>
            </div>
          }
          {!!coreFields["location"]?.hidden ? null : <div className={classes.fieldData}>
            <div
              // className={classes.ActivityName}
              style={{ marginTop: '0.5em', marginBottom: '0.5em', fontSize: '0.8525em', color: '#585858', fontWeight: 600 }}
            >
              {coreFields["location"]?.name || "Location"} {!!coreFields["location"]?.isMandatory ? <span className={classes.requiredAsterisk}> *</span> : null}
            </div>
            {locationState?.length ?
              <span style={{ display: "flex", flexWrap: "wrap" }}>{locationState?.map(({ name }) => <Chip key={name} radius={"lg"} checked={false}>{name}</Chip>)}</span>
              // <span>{locationState?.map(({ name }) => name).join(',')}</span>
              : null
            }
            <MantineButton variant="default" onClick={() => { setToggle(() => ({ isOpen: true, variant: 'location' })); }} size='xs' color={"#585858"} sx={{ fontSize: '0.7525em' }}>
              Click to choose location
            </MantineButton>
          </div>}
          {ticketType.startForm.map(({ id, name, type, options, readOnly, required, optionsExpression, placeholder, ...rest }) => {
            const tM = transformMap(type);
            if (type === "dropdown" || type === "radio-buttons" || type === "multiselect") {
              // TODO: Enable form dropdown to work properly with id,name tuples.
              const Component: MemoExoticComponent<FunctionComponent<IFormDropdownInputProps | IMultiSelectDropdownInputProps>> = tM.Component as MemoExoticComponent<FunctionComponent<IFormDropdownInputProps | IMultiSelectDropdownInputProps>>;
              /* options={k === "property" ? properties : k === "type" ? types : []} */
              return (
                <div key={id} className={classes.fieldData}>
                  {/* <div className={classes.ActivityName}>
                      {name.toUpperCase()}
                      </div> */}
                  <Component
                    field={id}
                    dispatch={startFormDispatch}
                    state={startFormState[id]}
                    options={options.slice() || []}
                    label={name}
                    disabled={!!readOnly}
                    required={required}
                    size='xs'
                    icon={coreFields[id] && coreFields[id].icon || null}
                  />
                </div>
              );
            }
            else {
              const Component: MemoExoticComponent<FunctionComponent<IGenericInputProps | IDateTimeInputProps>> = tM.Component as MemoExoticComponent<FunctionComponent<IGenericInputProps | IDateTimeInputProps>>;
              return (
                <div key={id} className={classes.fieldData}>
                  {/* <div className={classes.ActivityName}>
                      {name.toUpperCase()}
                      </div> */}
                  <Component
                    field={id}
                    dispatch={startFormDispatch}
                    state={startFormState[id]}
                    label={name}
                    disabled={!!readOnly}
                    type={type === "datetime" ? "datetime-local" : type}
                    required={required}
                    size='xs'
                    icon={coreFields[id] && coreFields[id].icon || null}
                    params={rest.params}
                  />
                </div>
              );
            }
          })}
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "column", paddingTop: "1em", flexGrow: 1, flexShrink: 0, boxShadow: "0px -10px 10px -7px rgba(0,0,0,0.12)", background: '#FFF', border: '0.01em solid #999999', }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ flexShrink: 1, display: "flex", paddingBottom: "1em", justifyContent: "center" }}>{
            disabled ? (<span style={{ color: "red", fontSize: '0.9em' }}>You can only submit the form when all mandatory fields are filled.</span>) : null}</div>
          <div className={classes.submitCancelDiv}>
            <Button
              disabled={disabled}
              className={classes.submitbutton}
              onClick={() => startProcess({ userId, customerId, watchers, state, processDefinitionKey: ticketType.typeId, projectId, locationState: locationState || [], startForm: Object.keys(startFormState).length ? sanitizeState(ticketType.startForm, startFormState) : undefined }).then(() => setTimeout(() => { refreshTickets(); onClose(); }, 200))}
            >
              CREATE
            </Button>
          </div>
        </div>
      </div>
    </div >
  );
};

export default observer(NewTicketPopup);
