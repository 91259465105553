import React, { memo, Dispatch, ReducerAction } from 'react';
import { DatePickerInput, DateTimePicker } from '@mantine/dates';

export interface IDateTimeInputProps {
  field: string;
  dispatch: Dispatch<ReducerAction<any>>;
  state: string;
  type: string;
  label: string;
  disabled?: boolean;
  required?: boolean;
  description?: string;
  size?: string;
  isDueDate?: boolean;
  icon?: JSX.Element;
  coreField?: boolean;
  params?: {[K: string] : any} | null | undefined
};

const calculateMinDate = ({ state, isDueDate }: { state: string, isDueDate: boolean }): Date | undefined => {
  if (state && isDueDate) {
    if (new Date(state).getTime() >= new Date().getTime()) {
      return new Date()
    }
    return new Date(state)
  }
  else if (!state && isDueDate) { return new Date() }
  return undefined
}

const DateTimeInput = ({ field, dispatch, state, type, label, disabled, required, description, size, isDueDate = false, icon, coreField }: IDateTimeInputProps) => {
  if (type == "datetime") {
    //TODO: Revert prop value to defaultValue if you face any bugs.
    return (
      <DateTimePicker
        label={label}
        // description={description || "Some description of the field"}
        translate={"Pick a date and time"}
        placeholder='Pick a date and time'
        disabled={disabled}
        required={required}
        previousDisabled={true}
        minDate={calculateMinDate({ state, isDueDate })}
        onChange={(event) => {
          dispatch({ type: 'REPLACE_DATE_TIME', payload: { field, type, input: event?.toISOString() } });
        }}
        value={state && state.length ? new Date(state) : undefined}
        size={size || 'sm'}
        sx={{
          ["& .mantine-DateTimePicker-label"]: { fontSize: coreField ? '0.7em' : '0.8525em', color: '#585858', fontWeight: coreField ? 500 : 600 },
          ["& .mantine-DateTimePicker-input"]: { marginTop: coreField ? '-4px' : '0.5em', marginBottom: coreField ? 0 : '0.5em', borderBottom: '0.0525em solid #005eff', borderTop: 0, borderRight: 0, borderLeft: 0, borderRadius: 0, paddingRight: coreField ? '1.8em' : '1.8575rem', '&[data-disabled]': { backgroundColor: '#FFF', border: 0, opacity: 1, color: '#585858', cursor: 'not-allowed' }, '&[data-with-icon]': { paddingLeft: coreField ? '1.8em' : '1.8575rem' } },
          ["& .mantine-DateTimePickerInput-icon"]: { justifyContent: coreField ? 'flex-start' : 'center' },
        }}
        popoverProps={{ position: 'bottom' }}
        icon={icon}
      />)
  }
  else {
    return (
      <DatePickerInput
        label={label}
        placeholder={"Pick a date"}
        disabled={disabled}
        required={required}
        minDate={calculateMinDate({ state, isDueDate })}
        onChange={(event) => {
          dispatch({ type: 'REPLACE_DATE_TIME', payload: { field, type, input: event?.toISOString() || "" } });
        }}
        value={state && state.length ? new Date(state) : null} // Changed from undefined to null to ensure the clear icon functionality works as expected.
        size={size || 'sm'}
        sx={{
          ["& .mantine-DatePickerInput-label"]: { fontSize: coreField ? '0.7em' : '0.8525em', color: '#585858', fontWeight: coreField ? 500 : 600 },
          ["& .mantine-DatePickerInput-input"]: { marginTop: coreField ? '-4px' : '0.5em', marginBottom: coreField ? 0 : '0.5em', borderBottom: '0.0525em solid #005eff', borderTop: 0, borderRight: 0, borderLeft: 0, borderRadius: 0, paddingRight: coreField ? '1.8em' : '1.8575rem', '&[data-disabled]': { backgroundColor: '#FFF', border: 0, opacity: 1, color: '#585858', cursor: 'not-allowed' }, '&[data-with-icon]': { paddingLeft: coreField ? '1.8em' : '1.8575rem' } },
          ["& .mantine-DatePickerInput-icon"]: { justifyContent: coreField ? 'flex-start' : 'center' },
        }}
        popoverProps={{ position: 'bottom' }}
        icon={icon}
        clearable
      />
    )
  }
};

export default memo(DateTimeInput);
