import { ActionIcon, Tooltip } from '@mantine/core';
import { IconCopy } from '@tabler/icons-react';
import React, { memo } from 'react';

const Copy = ({ copy, copied }: { copy(): void; copied: boolean; }) => (
  <div style={{ display: "flex", padding: "1em" }}>
    <Tooltip label={copied ? "Copied" : "Copy"}>
      <ActionIcon onClick={copy} color={copied ? "green" : "indigo"}>
        {copied ?
          <IconCopy width={'1em'} height={'1em'} color='green'/> :
          <IconCopy width={'1em'} height={'1em'}/>}
      </ActionIcon>
    </Tooltip>
  </div>
);

export default memo(Copy);
