import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  root: { display: "flex", flexDirection: "column", height: "100%", width: "100%", 
  // paddingTop: 20 
},
  title: {
    fontSize: "1.7em",
    display: "flex",
    justifyContent: "flex-start",
    fontWeight: 700,
    color: "#2F3E6C",
    padding: "0px 20px"
  },
  linkStyle: {
    pointer: "cursor",
    height: "100%",
    width: "100%",
    textDecoration: "none",
    color: "#005eff",
    "&:hover": {
      pointer: "cursor",
      fontWeight: 700,
      textDecoration: "underline"
    }
  },
  newRequestButton: {
    fontSize: ".95em",
    fontWeight: "bold",
    fontStyle: "normal",
    // backgroundColor: "#005eff",
    backgroundColor: "#005eff",
    color: "#ffffff",
    // width: "14%",
    // height: "3.7em",
    zIndex: 100,
    // position: "absolute",
    broderRadius: 0,
    // right: "1.5%",
    "&:hover": {
      backgroundColor: "#1a6fff",
    }
  },
  applyDisabled: {
    fontSize: ".75em",
    fontWeight: "normal",
    fontStyle: "normal",
    backgroundColor: "#eaeaea",
    color: "#ffffff",
    zIndex: 100,
    // width: "14%",
    height: "3.7em",
    position: "absolute",
    right: "1.5%",

  },
  tabBar: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "flex-end"
  },
  popupRoot: {
    width: "100%",
    height: "inherit",
    display: "flex",
    justifyContent: "flex-start",
    flexBasis: "auto",
    flexDirection: "column"
  },
  popup: {
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
    flexBasis: "auto",
    flexDirection: "row",
    flexGrow: 9,
    overflow: "overlay",
    borderLeft: "0.01em solid #E9E9E9",
    borderRight: "0.01em solid #E9E9E9",
    borderBottom: "0.01em solid #E9E9E9",
  },
  initialsIcon: {
    background: "#099bdd",
    color: "white",
    opacity: 1,
    display: "inline-block",
    fontWeight: "bold",
    borderRadius: "50%",
    verticalAlign: "middle",
    marginRight: "0.5em",
    width: 30,
    height: 30,
    textAlign: "center",
    lineHeight: "2.5em",
    fontSize: "0.85em"
  },
  paper: {
    position: 'relative',
    top: "1%",
    fontSize: "0.85rem",
    color: "#585858",
    // width: 200,
    // opacity: 0.8,
    // height: 200,
    padding: theme.spacing(2),
    transition: 'transform 0.3s',
    '&:hover': {
      // transform: 'scale(1.1)',
      // fontWeight: 600,
      // border: "1px solid #C0C0C0",
      // opacity: 1,
      color: "#000000",
      background: "#ededed",
    },
    "&.MuiPaper-elevation3" : {
      boxShadow: "none"
    },
    cursor: 'pointer'
  },
  arrow: {
    position: 'absolute',
    bottom: -20,
    left: '50%',
    transform: 'translateX(-50%)',
    width: 0,
    height: 0,
    borderLeft: '10px solid transparent',
    borderRight: '10px solid transparent',
    borderTop: '20px solid #333',
    transition: 'transform 0.3s',
    opacity: 0,
  },
  paperHovered: {
    '& $arrow': {
      transform: 'translateX(-50%) rotate(180deg)',
      opacity: 1,
    },
  },
  assigneeName: {
    fontSize: "1em",
  },
  assigneeTitle: {
    fontSize: "0.675em",
    color: "#A9A9A9"
  },
  refreshTasks : {
    display: 'flex', 
    alignSelf: 'center', 
    margin: '0em 1em', 
    opacity: 0.8,
    color: '#585858', 
    width: '1.75em',
    height: '1.75em',
    // transition: 'transform 0.3s',
    '&:hover' : {
      // transform: 'rotate(-360deg)',
      cursor: 'pointer'
    }
  },
  specialFilter : {
    display: 'flex', 
    alignSelf: 'center', 
    marginLeft: '1em', 
    opacity: 0.8,
    color: '#585858', 
    width: '1.75em',
    height: '1.75em',
    '&:hover' : {
      cursor: 'pointer'
    }
  },
  sFChip : {
    fontSize: '0.7525em',
    color: '#005eff',
    marginRight: '0.5em'  
  }
}));

export default useStyles;

