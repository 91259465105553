import React, { memo } from 'react'
import { IconDownload, IconVersions, IconUser } from '@tabler/icons-react';
import moment from 'moment';
import { Tooltip } from '@mantine/core';
import { formatFileSize } from '../../../utils/utils';

const FileHistoryCard = ({ file, onDownload, usersMap }) => {
  return (
    <div style={{ display: "flex", justifyContent: 'space-between', color: '#585858', fontSize: '0.9em', alignItems: 'center', padding: 0, }} key={file.version}>
     
      <div style={{ display: 'flex', flexDirection: 'column', flex: 3, minWidth: 250 }}>
        {/* Filename */}
        <div style={{ display: "flex", fontSize: '1em',  }}>
          <span style={{ wordBreak: 'break-word', overflowWrap: 'break-word', maxWidth: '85%' }}>{file.original_file_name}</span>
        </div>
        {/* Time */}
        <div style={{ display: "flex", alignItems: 'center', marginTop: '0.5em' }}>
          <span style={{ opacity: 0.85, fontSize: '0.8em' }}>{moment(file.created_at).add(5, 'h').add(30, 'm').format("DD MMM YYYY hh:mm A")}</span>
        </div>
      </div>
      
      {/* Meta section */}
      <div style={{ display: "flex", flexDirection: 'column', flex: 2, fontSize: '0.8em', marginRight: '1em' }}>
        
        <div style={{ display: 'flex', justifyContent: 'space-between', }}>
          {/* author */}
          <div style={{ display: 'flex', alignItems: 'center', marginRight: '1.5em' }} >
            <Tooltip label={'Author'}><IconUser style={{ color: '#005eff', cursor: 'pointer', marginRight: '0.25em' }} width={'1.3em'} height={'1.3em'} /></Tooltip>
              <span> { usersMap ? usersMap[file.user_id] : file.user_id} </span>
            </div>
            {/* version */}
            <div style={{ display: 'flex', alignItems: 'center', marginRight: '1.5em'}} >
            <Tooltip label={`Version ${file.version}`}><IconVersions style={{ color: '#005eff', cursor: 'pointer', marginRight: '0.25em' }} width={'1.3em'} height={'1.3em'} /></Tooltip>
            <span>V{file.version} </span>
            </div>
             <div style={{ display: 'flex'}}>
              {/* size */}
             <div style={{ display: 'flex', alignItems: 'center', marginRight: '1em' }}>
            <Tooltip label={'Size'}><span>{formatFileSize(file.size)}</span></Tooltip>
            </div>
            {/* download */}
            <div style={{ display: 'flex', alignItems: 'center',  }} onClick={() => onDownload(file.version, file)}>
              <Tooltip label={`Download V${file.version}`}><IconDownload style={{ color: '#005eff', cursor: 'pointer', marginRight: '0.25em' }} width={'1.3em'} height={'1.3em'} /></Tooltip>
            </div>
             </div>
        </div>
      </div>
    </div>
  )
}

export default memo(FileHistoryCard)