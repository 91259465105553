import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
    iconStyles: { width: '1.3em', height: '1.3em', paddingRight: '0.35em', color: '#005eff', },
    iconDivStyles: { display: "flex", justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer', '&:hover': { color: '#005eff' } },
    fileTypeTextDiv: {
        display: 'flex',        
        alignSelf: 'center',
        background: '#f9f9f9'
    },
    fileTypeText: {
        fontSize: '0.7575em',
        fontWeight: 700,
        display: 'flex',
    },
    fileIcon: {
        width: '2em',
        height: '2em',
        opacity: 0.2
    }, 
}));

export default useStyles;