import { types, flow, Instance } from 'mobx-state-tree';
import { generatePDF, pollPdf } from '../api/Auth';
import { getPdf } from '../utils/utils';
import { ChecklistReportsDownload } from './MultiSelect';
import { DownloadStatuses as DownloadState } from './enums';
import { shareEmail, shareWhatsapp } from '../api/downloadReports';
import moment from 'moment';

const __Q_LIMIT = 20;

const DownloadPdf = types.model({
    type: "pdf",
    fileName: types.identifier,
    url: types.maybeNull(types.string),
    title: types.maybeNull(types.string),
    status: types.frozen<DownloadState>(DownloadState.PENDING),
    project: types.maybeNull(types.string),
    timeStamp: types.string
}).volatile(_ => ({ loading: false }))
    .views(_ => ({ get extension() { return ".pdf"; } }))
    .actions(self => ({
        setStatus(status: DownloadState) { self.status = status; },
        getPdf() {
            if (self.url) {
                getPdf(self.url, self.title || 'invalid.pdf')
                self.status = DownloadState.DONE;
            }
        }
    })).actions(self => ({
        poll: flow(function* poll() {
            if (self.status === DownloadState.PENDING) {
                try {
                    self.loading = true;
                    // const { status } = yield checkReportStatus(self.fileName);
                    const { status } = yield pollPdf(self.fileName);
                    if (status === "DONE") {
                        self.setStatus(DownloadState.SUCCESS);
                    }
                    else if (status === "ERROR") {
                        self.setStatus(DownloadState.FAILURE);
                    }
                }
                catch (error) { self.setStatus(DownloadState.FAILURE); }
                finally { self.loading = false; }
            }
        }),
        share: flow(function* share(emails: string[], data: MessageDataType) {
            if (self.url && self.status !== DownloadState.PENDING && self.status !== DownloadState.FAILURE) {
                try {
                    const { message } = yield shareEmail(self.url, emails, data);
                    return { status: 200, message: `Email: ${message}` };
                }
                catch (error) {
                    self.setStatus(DownloadState.FAILURE);
                    console.error('errr', error);
                    const responsePayload = { status: 401, ...error.response.data };
                    throw responsePayload;
                }

            }
        }),
        shareBoth: flow(function* share(emails: string[], whatsApp: number, data: MessageDataType) {
            if (self.url && self.status !== DownloadState.PENDING && self.status !== DownloadState.FAILURE) {
                let responsePayload = { status: 401, message: "" }
                try {
                    if (emails.length > 0 && whatsApp) {
                        const { message } = yield shareEmail(self.url, emails, data);
                        const { payload: { message: whatsAppMessage } } = yield shareWhatsapp(self.url, whatsApp, data)
                        responsePayload = { status: 200, message: `Email: ${message} Whatsapp: ${whatsAppMessage}` }
                    } else if (emails.length > 0) {
                        const { message } = yield shareEmail(self.url, emails, data);
                        responsePayload = { status: 200, message: `Email: ${message}` }
                    } else if (whatsApp) {
                        const { payload: { message: whatsAppMessage } } = yield shareWhatsapp(self.url, whatsApp, data)
                        responsePayload = { status: 200, message: `Whatsapp: ${whatsAppMessage}` }
                    } else {
                        responsePayload = { status: 500, message: `Invalid Request` }
                    }
                    // console.log('responseMessage', responseMessage);
                    return responsePayload;
                }
                catch (error) {
                    // self.setStatus(DownloadState.FAILURE); 
                    // return responseMessage = `Error! Something went wrong. ${error} `;
                    console.log(error);

                    responsePayload = { ...error.response.data }
                    throw responsePayload
                }

            }
        })
    }));
// .actions(self => ({
//     download: flow(function* dnld(page: { pageURL: string; projectInfo: { [K: string]: any; }; projectName: string; reportTitle: string; }) {
//         if (self.status === DownloadState.IDLE || DownloadState.SUCCESS) {
//             // if (self.path !== page.pageURL) {
//             self.path = page.pageURL;
//             self.status = DownloadState.PENDING;
//             self.loading = true;
//             try {
//                 self.title = page.reportTitle;
//                 const data = yield generatePDF(page);
//                 self.status = DownloadState.SUCCESS;
//                 self.url = process.env.REACT_APP_PDF_API_URL + data.data.reXportPath.substr(data.data.reXportPath.lastIndexOf('/') + 1);
//             }
//             catch (err) { console.log(err); self.status = DownloadState.FAILURE; }
//             finally {
//                 self.loading = false;
//             }
//             // }
//         }
//     })
// }));
function downloadDispatch({ type }) {
    switch (type) {
        case 'pdf': return DownloadPdf;
        case 'checklist': return ChecklistReportsDownload;
        default: throw new Error("Incorrect type of Download model:" + type);
    }
}

export const Download = types.union({ dispatcher: downloadDispatch }, DownloadPdf, ChecklistReportsDownload);

export const DownloadQ = types.model({
    downloadQ: types.array(Download),
    loading: false
}).views(self => ({
    get isBusy() {
        return self.downloadQ.length > 0 ? self.downloadQ.some(({ status }) => status === DownloadState.PENDING) : false;
    }
})).actions(self => ({
    addToDownloadQ(fileName: string, url: string, title: string, project: string) {
        if (self.downloadQ.length >= __Q_LIMIT) { self.downloadQ.shift(); }
        self.downloadQ.push({ fileName, url, title, type: "pdf", project, timeStamp: moment().toISOString() });
    }
})).actions(self => ({
    download: flow(function* dnlPdf(page: { pageURL: string; projectInfo: { [K: string]: any; }; projectName: string; reportTitle: string; }) {
        try {
            self.loading = true;
            const data = yield generatePDF(page);
            const url = process.env.REACT_APP_PDF_API_URL + data.data.reXportPath.substr(data.data.reXportPath.lastIndexOf('/') + 1);
            self.addToDownloadQ(data.data.fileName, url, page.reportTitle, page.projectName);
            self.loading = false;
        } catch (err) { console.log(err); self.loading = false; throw err; }
    }),
    pollStatuses() {
        if (self.downloadQ.length === 0) { return; }
        for (let dwnld of self.downloadQ) {
            if (dwnld.status === DownloadState.PENDING) { dwnld.poll(); }
        }
    },
    cleanQ() {
        if (self.downloadQ.length === 0) { return; }
        // const newQ = Array.from(self.downloadQ.filter(({ status }) => status !== DownloadState.DONE));
        const newQ = Array.from(self.downloadQ.filter(({ status, timeStamp }) => moment().diff(moment(timeStamp), 'd') < 3 && status !== DownloadState.FAILURE));
        self.downloadQ.replace(newQ);
    }
})).actions(self => ({
    afterAttach() {
        const storg = localStorage.getItem('pdfReportsQ');
        if (!storg) { return; }
        const arr = JSON.parse(storg);
        if (Array.isArray(arr) && arr.length) {
            self.downloadQ.push(...arr);
        }
    }
}));

export type MessageDataType = {
    subject: string,
    body: string
}

export interface IDownloadPdf extends Instance<typeof DownloadPdf> { };
export type IDownload = Instance<typeof Download>;
export interface IDownloadQ extends Instance<typeof DownloadQ> { };
