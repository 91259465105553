import React from 'react'

const InitPage = () => {
  return (
    <div>
      <span>Please choose an API from left section to begin!</span>
    </div>
  )
}

export default InitPage