import { makeStyles } from "@material-ui/core/styles";
import tickIcon from "../../assets/images/Tick-White.svg";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: 800,
    height: "inherit",
    padding: "0em 1em"
  },
  panels: {
    display: "flex",
    flexDirection: "row",
    /* height: "inherit", */
    /* height: 600, */
    justifyContent: "space-between",
    flexBasis: "auto",
    minHeight: 200,
    height: "60%",
    padding: "1em",
    border: "0.01em solid #999999",
  },
  panelRoot: {
    display: "flex",
    /* height: "100%", */
    // width: "100%",
    flexDirection: "column",
    // backgroundColor: "#EAEAEA",
    // borderLeft: "0.01em solid #999999",
    // borderRight: "0.01em solid #999999",
    // borderBottom: "0.01em solid #999999",
    border: "0.01em solid #999999",
    width: "32%",
    background: "#EDEDED"
  },
  panelTitle: {
    display: "flex",
    fontSize: "1.2em",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
    padding: "0.5em",
    borderBottom: "0.01em solid #999999",
    fontWeight: 600,
    background: "#FFFFFF"
  },
  panel: {
    display: "flex",
    backgroundColor: "white",
    /* height: 600, */
    overflow: "overlay",
    flexDirection: "column",
    height: "100%",
  },
  buttonPane: {
    display: "flex",
    backgroundColor: "#EDEDED",
    overflow: "overlay",
    flexDirection: "column",
    height: "100%",
    flexGrow: 1,
    justifyContent: "center"
  },
  button: {
    display: "flex",
    fontSize: ".8em",
    color: "white",
    background: " #005eff",
    textAlign: "center",
    flexDirection: "column",
    justifyContent: "center",
    alignSelf: "center",
    cursor: "pointer",
    fontWeight: 600,
    /* width: "60px", */
    maxWidth: "60%",
    // border: "2px solid",
    height: "33px",
    borderRadius: "0px",
    "&:hover": {
      backgroundColor: "#1a6fef",
      color: "#faf9f9"
    },
    "&:disabled": {
      backgroundColor: "#d1d1d1",
      color: "#808080"
    },
  },
  addLocationButtonDiv: {
    display: "flex",
    justifyContent: "center",
    padding: "1em 0em"
  },
  selectionDiv: {
    display: "flex",
    height: "10em",
    flexDirection: "column"
  },
  selectionArea: {
    height: "100%",
    overflow: "auto",
    backgroundColor: "white",
    display: "flex",
    justifyContent: "flex-start",
    // borderColor: "#3e3e3e",
    // borderWidth: 2,
    // borderStyle: "solid",
    border: "0.01em solid #999999",
  },
  selectionAreaNone: {
    height: "100%",
    overflow: "auto",
    backgroundColor: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // borderColor: "#3e3e3e",
    // borderWidth: 2,
    // borderStyle: "solid",
    border: "0.01em solid #999999",
  },
  listItem: {
    display: "flex",
    width: "100%",
    cursor: "pointer",
    alignItems: "center",
    height: "2.3em",
    justifyContent: "flex-start",
    marginTop: '1%',
    fontSize: "0.9em"
  },
  icon: {
    backgroundColor: "unset",
    borderRadius: "1px !important",
    border: "1px solid #005eff !important",
    width: 14,
    height: 14
  },
  multiSelectScenario: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    height: "100%",
    color: "#585858",
    fontSize: "0.8em"
  },
  selectionGridColumns: {
    padding: "1em 1.2em"
  },
  chip: {
    padding: "0.1em"
  },
  checkedIcon: {
    backgroundColor: "#005eff",
    borderRadius: "1px !important",
    "&:before": {
      display: "block !important",
      width: 21,
      height: 21,
      backgroundImage:
        `url(${tickIcon})`,
      marginLeft: "-5.7px !important",
      marginTop: "-5.7px !important",
      content: '""',
    }
  },
}));

export default useStyles;
