import { MRT_Cell, MRT_Column, MRT_Row } from 'mantine-react-table';
import moment from 'moment';
import React from 'react';
import { IconAlertCircle } from '@tabler/icons-react'; 
import { Tooltip } from '@mantine/core';

const DateCell = ({ column: { id }, cell, row }: { column: MRT_Column<any>, cell: MRT_Cell<any>; row: MRT_Row<any> }) =>{
  const isDelayed = row.original.delayed
  if(id === 'dueDate') {
    if(isDelayed) {
      return <div style={{display: 'flex', alignItems: 'center'}}><span style={{marginRight: '0.5em'}}>{ cell.getValue() ? moment(row.original[id]).format('DD-MMM-YYYY') : '-'}</span><Tooltip label={"Delayed"}><IconAlertCircle size={18} color="red"/></Tooltip></div>
    } else {
      return <span>{ cell.getValue() ? moment(row.original[id]).format('DD-MMM-YYYY') : '-'}</span>
    }
  } else {
    return cell.getValue() ? moment(row.original[id]).format('DD-MMM-YYYY hh:mm A') : '-'
  }
  // cell.getValue() ? moment(row.original[id]).format(id === 'dueDate' ? 'DD-MMM-YYYY' : 'DD-MMM-YYYY hh:mm A') : '-';
}
export default DateCell;
