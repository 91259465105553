import * as React from "react";
import { useLocation, useParams } from 'react-router';
import { useObserver } from 'mobx-react-lite';
import { useStore } from '../models/ProvideModel';
import { SnapshotOut, getEnv } from "mobx-state-tree";
import { eGraphType } from "../models/Common";

// * Mapping Url to mst
// ? better soultion ?
// no. Besht solution
export interface IGenericElementProps extends React.PropsWithChildren<{}> {
  section: string;
  screen?: string;
  graphType?: SnapshotOut<typeof eGraphType>;
  spaceType?: string;
};

const UrlToMST = React.memo(({ children, screen, graphType, section, spaceType }: IGenericElementProps): React.ReactElement => {
  const store = useStore();
  const params = useParams<any>();
  const { pathname, search } = useLocation();
  React.useEffect(() => { getEnv(store).mixpanel.track(section, { pathname, genPath: store.params.path }); }, [pathname, section]);
  React.useEffect(() => { store.params.setParams({ ...params, section, screen: screen || params['screen'], graphType: graphType || params['graphType'] || 'brickGraph', spaceType: spaceType || params['spaceType'] || 'None' }); }, [screen, section, graphType, Object.values(params)]);
  React.useEffect(() => {
    const filters = new URLSearchParams(search);
    let snapshot = {};
    for (const key of filters.keys()) {
      if (!snapshot[key]) {
        const val = filters.getAll(key);
        if (key === 'status' || key === 'tower')
          snapshot[key] = val;
        else
          snapshot[key] = val[0];
      }
    }
    if (Object.keys(snapshot).length === 0)
      store.filters.clearFilters();
    else
      store.filters.patchFilters({ ...snapshot });
  }, [search]);
  return useObserver(() => <> {React.Children.only(children)} </>);
});

export default React.memo(UrlToMST);
