import React, { useEffect, useState, useMemo, FunctionComponent } from "react";
import { ITower } from '../../models/Tower';
import { useStore } from '../../models/ProvideModel';
import { useLocation, useHistory, useParams } from "react-router";
import { useObserver } from 'mobx-react-lite';
import LoadingSpinner from '../../components/loadingSkelaton/LoadingSpinner';
import moment from "moment";
import { compose, transduce, map, filter, toArray } from "transducist";
import { ApolloClient } from "apollo-client";
import { NormalizedCacheObject } from "apollo-cache-inmemory";
import client from "../../utils/apolloClient";
import { GET_STEP_BY_UNIT_ACTIVITY_IDD, GET_STEP_BY_UNIT_ACTIVITY_IDD_HEADERS } from "../../utils/queries";
import FatTable from "../../components/fatTable/FatTable";
import TableMultiselect from "../../components/fatTable/TableMultiselect";
import TableDate from "../../components/fatTable/TableDate";
import DefaultCells from "../../components/DefaultCells";
import StepNameDetails from "../../components/StepNameDetails";
import { useSingleValueURLParam } from "../../utils/hooks";
import { RenderedDate, StatusWithDelayed } from '../../components/table/Commonfunctions';
import useStyles from '../../components/fatTable/FatTableStyle'
import { Breadcrumbs, Grid } from "@material-ui/core";
import PhotoSubmit from "../../components/stepInfo/PhotoSubmit";
import PhotoSubmitNew from "../../components/stepInfo/PhotoSubmitNew";
// * Component Renders Activity Per Block - Activity Info Page
const __DEFAULT = 100;

interface IQueryFilterParams {
  where: Record<string, any>;
  order_by: { [k: string]: "asc" | "desc" }[];
}
interface IQueryPageParams {
  limit: number;
  offset: number;
}

type IQueryParams = IQueryFilterParams & Partial<IQueryPageParams>;

export interface IColumnOptions {
  id: string;
  name: string;
  filter?: Function;
  Component?: FunctionComponent<{ [K: string]: any; }>;
  Cell?: FunctionComponent;
  print?: boolean;
  value?: string;
  isVisible?: boolean;
  accessor?: string;
  minWidth?: number;
  minHeight?: number;
  headerObj?: string;
  excelExport?: Function;
  isExcelField?: boolean;
  data?: { id: string; name: string; }[];
}

export function useCustomQueryParameters(
  CurrentStepcolumns: IColumnOptions[],
  filters: URLSearchParams,
  ua?: string
) {
  // const { blockId } = useParams<any>();               // Use when block_id is a fk
  const [param, setParam] = useState<IQueryParams>();
  // const value = store.unitActivityDetails?.steps[0]?.unit_activity_id
  useEffect(() => {
    const sort = filters.getAll("sort");
    const descSort = filters.getAll("sortDesc");
    let variables: IQueryParams = {
      where: { unit_activity_id: { _eq: ua } },
      // order_by: [{ step_number: "asc" }],
      order_by: [{ actual_start: "asc" }],
    };
    for (const { id: field, filter } of CurrentStepcolumns) {
      if (sort.length > 0 && sort.includes(field)) {
        variables["order_by"].push({ [field]: "asc" });
      }
      if (descSort.length > 0 && descSort.includes(field)) {
        variables["order_by"].push({ [field]: "desc" });
      }
      const value = filters.getAll(field);
      if (value.length > 0) {
        if (filter) {
          variables["where"] = filter === snagBool ? {
            ...(variables["where"] || {}),
            ...filter.apply(null, value)
          } : {
              ...(variables["where"] || {}),
              [field]: { ...filter.apply(null, value) },
            };
        } else {
          if (value.length === 1) {
            const singleVal = value[0];
            variables["where"] = {
              ...(variables["where"] || {}),
              [field]: { _eq: singleVal },
            };
          } else {
            variables["where"] = {
              ...(variables["where"] || {}),
              [field]: { _in: [...value] },
            };
          }
        }
      }
    }
    setParam({ ...variables });
  }, [filters]);
  return param;
}


function delta(input1: string, input2: string): { _lt: string; _gt: string } {
  if (!(input1 && input2)) {
    throw new Error("date type filters must define a range");
  }
  const dates = [input1, input2].map((d) => moment(d));
  return {
    _lt: moment.max(...dates).endOf('day').toISOString(),
    _gt: moment.min(...dates).toISOString(),
  };
}


//          Doesn't work because attachment is an empty string instead of null
/* function bool(input: string): { _is_null: boolean } {
 *   return { _is_null: input === "true" };
 * } */


function snagBool(input: string): { snag_counts?: {}; _not?: { snag_counts: {} } } {
  return input === "false" ? { _not: { snag_counts: {} } } : { snag_counts: {} };
}

export const CurrentStepcolumns: IColumnOptions[] = [
  { id: "step_name", name: "Submitted Step", Cell: StepNameDetails, print: true, isExcelField: true, excelExport: (elt: any) => elt['current_step'] },
  { id: "status", name: "Status", Cell: StatusWithDelayed, print: true, minWidth: 80, isExcelField: true, excelExport: (elt: any) => elt['status'] === '1_planned' ? 'Not Started' : elt['status'] === '3_active' ? 'On Going' : elt['status'] === '8_done' ? 'Completed' : 'Error' },
  { id: "actual_start_spl", accessor: "actual_start", name: "Submitted On", filter: delta, Cell: RenderedDate, print: true, minWidth: 80, isExcelField: true, excelExport: (elt: any) => !elt['plan_start'] ? '-' : moment(elt['plan_start']).format('DD/MM/YYYY') },
  // { id: "actual_start", name: "Start Date", filter: delta, Cell: RenderedDate, print: true, minWidth: 80, isExcelField: true, excelExport: (elt: any) => !elt['plan_start'] ? '-' : moment(elt['plan_start']).format('DD/MM/YYYY') },
  { id: "current_user_name", name: "Submitted By", print: true, isExcelField: true, excelExport: (elt: any) => elt['current_user_name'] },
  { id: "data", accessor: "id", value: "data", name: "Photos / Comments", print: true, Cell: PhotoSubmitNew, minWidth: 50 },
  // { id: "data", accessor: "id", value: "data", name: "Photos", print: true, Cell: CommentCell, minWidth: 250, isExcelField: true, excelExport: (elt: any) => !elt['comment_array'] ? '-' : elt['comment_array'].map(c => c.name + ': ' + c.comment + (c["attachment_type"].toLowerCase() === "image" ? `${config.baseURL}large/${c.attachment}` : "")).join('\n') },
];
export interface IStepPerUnitPerActivityProps {
  classes: any;
  unitActivity?: string;
  /* tower: ITower,
   * activity: string,
   * unit: string, */
};

export default function StepPerUnitPerActivity(props: IStepPerUnitPerActivityProps) {
  const store = useStore();
  const [data, setData] = useState<any[]>([]);
  const [headersData, setHeadersData] = useState<{}>({});
  useEffect(() => {
    if (!client.client) {
      client();
    }
  }, [client]);
  const { search, ...location } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const { push } = useHistory();
  const params = useCustomQueryParameters(CurrentStepcolumns, searchParams, props.unitActivity);
  const [print] = useSingleValueURLParam("view", "normal", searchParams);
  const [loading, setloading] = useState<boolean>(false)
  /* const { tower, spaceType, phase }: { tower?: string | undefined, spaceType?: string | undefined, phase?: string | undefined } = useParams<any>(); */
  /* const pathParams = useMemo(() => spaceType && phase ? tower ? ({ "space_type_id": { "_eq": spaceType }, "phase_id": { "_eq": phase }, "block_id": { "_eq": tower } }) : ({ "space_type_id": { "_eq": spaceType }, "phase_id": { "_eq": phase } }) : ({}), [tower, spaceType, phase]); */
  const pathParams = useMemo(() => ({}), []);
  const classes = useStyles({ print });
  const columnsConfig = useMemo(
    () =>
      transduce(
        CurrentStepcolumns,
        compose(
          filter(({ print: printV }) => (print === "print" ? !!printV : true)),
          filter(({ id }) =>
            headersData[id] ? (headersData[id].length > 0 ? true : false) : true
          ),
          map(({ id, name, Component, Cell, isVisible, accessor, headerObj, minWidth, minHeight, ...rest }) => ({
            name,
            Header:
              Component && print !== "print"
                ? Component
                : name,
            minWidth: minWidth || 150,
            minHeight: minHeight || 65,
            id,
            accessor: accessor || id,
            headerObj,
            Cell: !!Cell ? Cell : DefaultCells,
            data: headersData[id] || null,
            ...rest
          }))
        ),
        toArray()
      ),
    [headersData, print]
  );
  const [offset, setPage] = useSingleValueURLParam<number>(
    "page",
    0,
    searchParams,
    Number.parseInt,
    push,
    location
  );
  const [limit, setSize] = useSingleValueURLParam<number>(
    "pageSize",
    __DEFAULT,
    searchParams,
    Number.parseInt,
    push,
    location
  );
  useEffect(() => {
    if (client.client) {
      const query = (client.client as ApolloClient<NormalizedCacheObject>)!
        .watchQuery({ query: GET_STEP_BY_UNIT_ACTIVITY_IDD_HEADERS, variables: { where: { unit_activity_id: { _eq: props.unitActivity }, ...pathParams } } })
        .subscribe(({ data, loading }) => {
          if (!loading) {
            setHeadersData(
              CurrentStepcolumns.reduce(
                (acc, { id, Component }) =>
                  Component
                    ? { ...acc, [id]: data[id] && data[id].length > 0 ? data[id] : null }
                    : acc,
                {}
              )
            );
          }
        });
      return () => {
        query.unsubscribe();
      };
    }
  }, [pathParams]);
  // Issue the query
  useEffect(() => {
    if (client.client && params) {
      setloading(true)
      const variables: IQueryParams =
        print === "print"
          ? params
          : { ...params, limit, offset: offset * limit };
      const query = (client.client as ApolloClient<
        NormalizedCacheObject
      >)!.watchQuery<Array<any>, IQueryParams>({
        query: GET_STEP_BY_UNIT_ACTIVITY_IDD,
        variables: { ...variables, where: { ...variables.where, ...pathParams } },
        errorPolicy: "all",
      });
      query
        .result()
        .catch((error) => console.error(error))
        .finally(() => setloading(false));
      const observable = query.subscribe(({ data, loading, errors }) => {
        if (!loading) {
          if (data) {
            setData(data["data"]);
            setloading(false)
          }
        } else if (errors) {
          setloading(false)
        }
      });
      return () => {
        observable.unsubscribe();
      };
    }
  }, [params, offset, limit, print, pathParams]);

  //function to call query with params and where clause, and then return data to download excel component
  React.useEffect(() => {
    store.unitActivityDetails.getStepByunitActivityId(props.unitActivity);
  }, [props.unitActivity])

  const mobilefullScreenMode = (fullScreenMode) => {
    return !fullScreenMode ? classes.tablePanelSm : classes.tablePanelFs
  }

  useEffect(() => () => {
    store.exportTableFilters.clearTableFiltersData()
  }, [])

  return useObserver(() => !store.unitActivityDetails.loading ?
    <div
      style={{
        backgroundColor: "#faf9f9",
        flexDirection: "column",
        width: "100%",
        display: "flex",
        flexGrow: 1,
        flexBasis: "auto",
        flexShrink: 1,
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          flexBasis: "auto",
          overflow: "auto",
          flexGrow: 1,
          flexShrink: 1,
          // height: "100%"
        }}
      >
        <div className={store.responsiveUtils.currentViewport.isLg ? classes.tablePanel : mobilefullScreenMode(store.responsiveUtils.fullScreenMode)}>
          <Grid container>
            <Grid item xs={12} style={{ display: "flex", alignItems: "center", padding: "20px 0", marginLeft: "22px" }} >
              <Breadcrumbs aria-label="breadcrumb" className={classes.boldDarkBlue24px} style={{ paddingLeft: "12px" }}>
                <span>{!!(store.unitActivityDetails.steps) && ((store.unitActivityDetails.steps).length > 0) ? store.unitActivityDetails.steps[0].block : "tower"}</span>
                <span>{!!(store.unitActivityDetails.steps) && ((store.unitActivityDetails.steps).length > 0) ? store.unitActivityDetails.steps[0].unit_name : "unit"}</span>
                <span>{!!(store.unitActivityDetails.steps) && ((store.unitActivityDetails.steps).length > 0) ? store.unitActivityDetails.steps[0].activity_name : "activity"}</span>
              </Breadcrumbs>
            </Grid>
          </Grid>
          {loading ? <LoadingSpinner bgColor={'#faf9f9'} width={(print !== "print") ? "80%" : "100%"} /> : <FatTable columns={columnsConfig} data={data} print={print} />}
        </div>
      </div>
    </div>
    :
    <>
      <LoadingSpinner />
    </>
  )
}


