import React from 'react';
import { ReactComponent as Bell } from '../../assets/images/Bell-Icon.svg';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
        position: 'relative',
        display: 'inline-block'
    },
    count: {
        position: "absolute",
        top: "-3px",
        right: "-3px",
        backgroundColor: "red",
        color: "white",
        fontSize: "0.55em",
        fontWeight: "bold",
        padding: "0.25em",
        borderRadius: "50%"
    },
    media: {
        height: 140,
    },
});

const CustomBellIconWithCount = ({ count }) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Bell width={20} height={20} />
            {count > 0 && <span className={classes.count}>{count}</span>}
        </div>
    );
};

export default CustomBellIconWithCount;
