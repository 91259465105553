import axios, { AxiosResponse } from "axios";
import config from "../configs/clientConfig";
import { Auth } from "../models/Auth"
import { apiList } from '../configs/admin/apiConfig'
import { ApiProps } from "../models/admin/definitions/interface";
import { IOption } from "../models/admin/AdminModule";

async function getUser(email: string, password: string) {
  const data = {
    email: email,
    password: password
  };
  try {
    const res = await axios.post(config.RestApiUrl + "auth/hasuraJWT", data);
    return res.data.token;
  } catch (error) {
    console.error("Error response:");
    console.error(error); // data is any
    console.error(error.response.data); // data is any
    console.error(error.response.status); // status
    console.error(error.response.headers);
    throw new Error(error.response.data.errors.form === 'Sorry user not found!' ? 'Entered username does not match our records. Please check again.' :
      error.response.data.errors.form === 'Invalid Credentials!' ? 'The password you entered for the username is incorrect.' : error.response.data.errors.form);
  }
}

async function getProjects() {

  try {
    const res = await axios.get(config.RestApiUrl + "projectList/v2");
    return res.data;
  } catch (error) {
    console.error("Error response:");
    console.error(error.response.data); // data is any
    console.error(error.response.status); // status
    console.error(error.response.headers);
    // throw new Error(error.response.data.errors.form);
  }
}

async function getProperties(projectId: string) {
  try {
    const res = await axios.get(config.RestApiUrl + `projectList/${projectId}`);
    return res.data;
  } catch (error) {
    console.error("Error response:");
    console.error(error.response.data); // data is any
    console.error(error.response.status); // status
    console.error(error.response.headers);
    throw new Error(error.response.data.errors.form);
  }
}


async function downloadExcel(data: any, customer_id: string) {
  const requestData = {
    wSheetData: data,
    wSheetName: customer_id,
  };

  try {
    const res = await axios.post(config.RestApiUrl + `reports/exports/xlsx`, requestData);
    return res;
  } catch (error) {
    console.error(`Error response: ${error}`);
    throw new Error(error);
  }
}

async function generatePDF(page) {
  const requestData = { ...page }
  try {
    const res = await axios.post(config.RestApiUrl + `reports/exports/v2/pdf`, requestData);
    return res;
  }
  catch (error) {
    console.error(`Error response: ${error}`);
    throw new Error(error);
  }
}

async function pollPdf(id: string) {
  try {
    const res = await axios.get(config.RestApiUrl + `reports/exports/v2/pdf-check/${id}`);
    return res.data;
  } catch (error) {
    console.error(`Error response: ${error}`);
    throw new Error(error);
  }
}

//Function to retrieve powerBI access token from portal-server
async function getPowerBiToken(groupId: any, reportId: string): Promise<AxiosResponse<any>> {
  const request = { groupId, reportId };
  try {
    const res = await axios.post(config.RestApiUrl + `powerbi/v2`, request);
    return res;
  } catch (error) {
    console.error(`Error response: ${error}`);
    throw new Error(error);
  }
}

function changePassword(email: string, perviousPassword: string, newPassword: string): Promise<AxiosResponse<any>> {
  return axios.post(config.RestApiUrl + 'auth/changePassword', { email, perviousPassword, newPassword });
}

// function getAllAdminAPIs() {
//   return apiList
// }

async function getAllAdminAPIs(): Promise<AxiosResponse<ApiProps>> {
  // const request = { groupId, reportId };
  const url = process.env.REACT_APP_API_LIST || "https://txn-service-kotlin-dev.azurewebsites.net/adminTools/apis?customerId=fbtorg"
  try {
    const res = await axios.get(url);
    // console.log('ress',res)
    return res.data.data;
  } catch (error) {
    console.error(`Error response: ${error}`);
    throw new Error(error);
  }
}

async function getAllCustomers(): Promise<AxiosResponse<IOption>> {
  try {
    const url = process.env.REACT_APP_CUSTOMERS_LIST || "https://txn-service-kotlin-dev.azurewebsites.net/adminTools/customerList"
    const res = await axios.get(url);
    // console.log('ress',res)
    return res.data.data;
  } catch (error) {
    console.error(`Error response: ${error}`);
    throw new Error(error);
  }
}

// getAllAdminAPIs1()

export { getUser, getProjects, getProperties, downloadExcel, generatePDF, getPowerBiToken, changePassword, pollPdf, getAllAdminAPIs, getAllCustomers };
