import { Checkbox } from '@mantine/core';
import React, { memo } from 'react';
import { IGenericInputProps } from './GenericInput';

const labelStyle = { display: 'inline-block', cursor: 'default', lineHeight: '1.55', fontSize: '0.8525em', fontWeight: 600, color: '#585858', letterSpacing: '0.01071em', fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji' }

const CheckboxInput = ({ field, dispatch, state, label, disabled, required, type, description }: IGenericInputProps) => (
  <Checkbox
    label={<span style={labelStyle}>{label} {required && <span style={{ color: 'red' }}>*</span>}</span>}
    disabled={!!disabled}
    required={!!required || false}
    checked={!!state}
    description={description || ""}
    onChange={(e) => { if (!disabled) { dispatch({ type: 'REPLACE_GENERIC', payload: { input: e.currentTarget.checked, type, field } }); } }}
  />
);

export default memo(CheckboxInput);
