import React, { useEffect, useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import { ResponseObject } from '../../../models/admin/definitions/interface';
import Form from '../../../components/admin/form/Form';
import { useStore } from '../../../models/ProvideModel';
import { observer } from 'mobx-react-lite';
import { IRequest } from '../../../models/admin/AdminModule';
// import GenericTable from '../../../components/admin/tables/GenericTable'
// import JSONToField from '../../../components/admin/reponse/JsonToField'
import InitPage from '../../../components/admin/initPage/InitPage';
import RenderAccordion from '../../../components/admin/accordion/RenderAccordion';
import { makeStyles, Theme } from "@material-ui/core/styles";
import RenderIframe from '../iframe/RenderIframe';
import ResponseContainer from '../../../components/admin/reponse/ResponseContainer';
import DocsView from '../../../components/admin/doc/DocsView';
import RichTextOutput from '../../../components/RichTextOutput';
import RenderRetool from '../retool/RenderRetool';
// import { useParams } from 'react-router';

const useStyles = makeStyles((theme: Theme) => ({
    main: {
        display: 'flex', width: '100%', background: '#fdf1cb'
    },
    search: {
        fontSize: '0.85em'
    }

}));

const LandingPage: React.FC = () => {
    const store = useStore();
    const classes = useStyles();
    const [response, setResponse] = useState<ResponseObject | undefined>(undefined)
    const [storeRequests, setStoreRequests] = useState<any>(null);
    const [filteredRequests, setFilteredRequests] = useState<IRequest[] | null>(null);
    const [selectedCustomer, setSelectedCustomer] = useState(store.auth.customerId);
    const apiSource = store.adminModule.currentRequest?.meta?.get('source');
    const isStandardAPI = !['APP_SMITH','DOCS','RETOOL'].includes(apiSource);

    useEffect(() => {
        const fetchData = async () => {
            try {
                await store.adminModule.getApiList();
                setStoreRequests(store.adminModule.requests);
                // Upon successful fetching of data, use store to set the state value
            } catch (error) {
                console.error('Error fetching API list:', error);
            }
        };

        fetchData();
    }, [])

    const handleResponse = (responseObject: ResponseObject) => {
        setResponse(responseObject)
    }

    const handleCustomerChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const selectedValue = event.target.value as string;
        setSelectedCustomer(selectedValue);
        try {
            store.adminModule.setCustomer(selectedValue);
        } catch (error) {
            console.error('Failed to set customer or load data:', error);
        }
    };


    return (
        <div className={classes.main}>
            {/* List div */}
            <div style={{ overflowY: 'auto', maxHeight: '100vh', width: '19.5%', background: '#fbe5ba' }}>
                <div style={{ position: 'sticky', top: 0, background: '#fbe5ba', zIndex: 1 }}>
                    <div style={{ fontSize: '1.5em', fontWeight: 600, textAlign: 'left', padding: '0.8em  1em', color: '#2F3E6C' }}>All Requests</div>
                    <div style={{ display: 'flex', width: '100%' }}>
                        <TextField
                            style={{ padding: '0.5em', margin: '0.5em 1em', width: '100%', fontSize: '0.8em' }}
                            type="text"
                            // label={'Search'}
                            placeholder="Search by API Name"
                            onChange={(e) => {
                                const searchValue = e.target.value;
                                console.log('search value', searchValue)
                                const filteredData = storeRequests.filter((request) => request.title.toLowerCase().includes(searchValue && searchValue.toLowerCase()));
                                setFilteredRequests(filteredData);
                            }}
                            InputProps={{
                                classes: {
                                    input: classes.search
                                }
                            }}
                        />
                    </div>
                </div>
                {filteredRequests ? <RenderAccordion data={filteredRequests} expanded={!!filteredRequests.length} /> : storeRequests && <RenderAccordion data={storeRequests} expanded={false} />}
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', width: '79.5%', flexGrow: 1 }}>
                <div style={{ overflowY: 'auto', maxHeight: '40%', flexGrow: 1, width: '100%', borderRight: '0.001em solid #c8b794', zIndex: 1, background: '#fdf1cb' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', position: 'sticky', top: 0, background: '#fbe5ba', zIndex: 2, padding: '0.5em 1em', width: '100%' }}>
                        <span style={{ fontSize: '1.5em', fontWeight: 600, margin: 0, flex: 1, color: '#2F3E6C' }}>{store.adminModule.currentRequest?.title || `Welcome ${store.auth.userName} !`}</span>
                        <FormControl style={{ minWidth: 250 }}>
                            <InputLabel id="customer-dropdown-label">Select Customer</InputLabel>
                            <Select
                                //   labelId="customer-dropdown-label"
                                id="customer-dropdown"
                                value={selectedCustomer}
                                onChange={handleCustomerChange}
                            >
                                {store.adminModule.customers.map(({ id, name }) => (
                                    <MenuItem key={id} value={id}>
                                        {name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    {storeRequests && store.adminModule.currentRequest && isStandardAPI ?
                        <>
                            <div style={{ display: 'flex', flexDirection: 'column', padding: '1em' }}>
                                <span style={{ fontSize: '1em', color: '#666' }}><RichTextOutput content={store.adminModule.currentRequest?.description}/></span>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', marginTop: '2em' }}>
                                <Form formData={store.adminModule.currentRequest?.parameters} handleResponse={handleResponse} />
                            </div>
                        </> : store.adminModule.currentRequest && apiSource === "DOCS" ? <DocsView url={store.adminModule.currentRequest?.url} /> :
                        !store.adminModule.currentRequest ?
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', marginTop: '2em' }}> <InitPage /> </div> :
                            null
                    }
                </div>
                {store.adminModule.currentRequest && apiSource === "APP_SMITH" ?
                    <RenderIframe appURL={store.adminModule.currentRequest?.url} /> :
                    store.adminModule.currentRequest && apiSource === "RETOOL" ? 
                    <RenderRetool url={store.adminModule.currentRequest?.url} customerId={selectedCustomer}/> :
                    <>
                        {(store.adminModule.currentRequest && isStandardAPI) && <ResponseContainer response={response} />}
                    </>
                }
            </div>
        </div>
    );
};

export default observer(LandingPage);

