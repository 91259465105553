import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
    field: {
        display: 'flex', 
        marginBottom: '2em', 
        flexDirection: 'column',
        fontSize: '0.9em'
    },
    submitbutton: {
        background: '#7d725d',
        color: "#FFF",
        fontSize: "0.9em",
        fontWeight: 600,
        borderRadius: 0,
        '&:hover' : {
            background: '#645b4a'
        }
    },
    textField: {
        // padding: '9.5px 14px',
        fontSize: '0.8em'
    },
    disabledTextField: {
        color: '#585858',
        opacity: 0.7
    }

}));

export default useStyles;