import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100px',
    flexDirection: 'column',
    opacity: 0.9
  },
  loadingLine: {
    width: '0%',
    height: '1px',
    backgroundColor: '#585858',
    animation: '$loadingAnimation 2s infinite',
    marginTop: "-1px",
    zIndex: 1
  },
  verticalLine: {
    width: '12px',
    height: '25px',
    backgroundColor: '#585858',
    margin: '0 2px',
    transformOrigin: 'bottom',
    animation: '$verticalLinesAnimation 2s infinite',
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    animationDelay: '0s', 
    marginBottom: '-15px'
  },
  verticalLineMiddle: {
    width: '12px',
    height: '40px',
    margin: '0 2px',
    transformOrigin: 'bottom',
    animation: '$verticalLinesAnimation 2s infinite',
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    animationDelay: '0.2s', 
    backgroundColor: '#585858',
  },
  verticalLineLast: {
    width: '12px',
    height: '35px',
    margin: '0 2px',
    transformOrigin: 'bottom',
    animation: '$verticalLinesAnimation 2s infinite',
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    backgroundColor: '#585858',
    animationDelay: '0.4s', 
    marginBottom: '-5px',
  },
  gridContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "16px"
  },
  square: {
    width: "2px",
    height: "2px",
    backgroundColor: "white",
    marginBottom: "2px"
  },
  rectangle: {
    width: "2px",
    height: "12px",
    backgroundColor: "white",
    marginBottom: "2px"
  },
  verticalLinesContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  '@keyframes loadingAnimation': {
    '0%': {
      width: '0%',
    },
    '50%': {
      width: '100%',
    },
    '100%': {
      width: '0%',
    },
  },
  '@keyframes verticalLinesAnimation': {
    '0%': {
      transform: 'scaleY(0)',
    },
    '20%': {
      transform: 'scaleY(0)',
    },
    '40%': {
      transform: 'scaleY(1)',
    },
    '80%': {
      transform: 'scaleY(1)',
    },
    '100%': {
      transform: 'scaleY(0)',
    },
  },
  '@keyframes dotsAnimation': {
    '0%': {
      opacity: 0,
    },
    '33%': {
      opacity: 1,
    },
    '66%': {
      opacity: 1,
    },
    '100%': {
      opacity: 0,
    },
  },
  dots: {
    display: 'inline-flex',
  },
  dot: {
    width: '3px',
    height: '3px',
    backgroundColor: 'black',
    borderRadius: '50%',
    margin: '0 2px',
    animation: '$dotsAnimation 1.5s infinite',
    animationDelay: '0s',
  },
  text: {
    fontWeight: 500,
    fontSize: '0.8525em'
  }
});

const LoadingComponent = (props) => {
  const classes = useStyles();

  return (
    <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
      <div className={classes.loadingContainer}>
        <div className={classes.verticalLinesContainer}>
          <div className={classes.verticalLine}>
            <div className={classes.gridContainer}>
              <div className={classes.square}></div>
              <div className={classes.square}></div>
              <div className={classes.square}></div>
            </div>
            <div className={classes.gridContainer}>
              <div className={classes.square}></div>
              <div className={classes.square}></div>
              <div className={classes.square}></div>
            </div>
          </div>
          <div className={classes.verticalLineMiddle}>
            <div className={classes.gridContainer}>
              <div className={classes.rectangle}></div>
              <div className={classes.rectangle}></div>
            </div>
            <div className={classes.gridContainer}>
              <div className={classes.rectangle}></div>
              <div className={classes.rectangle}></div>
            </div>
          </div>
          <div className={classes.verticalLineLast}>
            <div className={classes.gridContainer}>
              <div className={classes.square}></div>
              <div className={classes.square}></div>
              <div className={classes.square}></div>
              <div className={classes.square}></div>
            </div>
            <div className={classes.gridContainer}>
              <div className={classes.square}></div>
              <div className={classes.square}></div>
              <div className={classes.square}></div>
              <div className={classes.square}></div>
            </div>
          </div>
        </div>
        <div className={classes.loadingLine}></div>
        <span><span className={classes.text}>{props.title || "Loading"} </span>
          <span className={classes.dots}>
            <span className={classes.dot} style={{ animationDelay: '0s' }}></span>
            <span className={classes.dot} style={{ animationDelay: '0.2s' }}></span>
            <span className={classes.dot} style={{ animationDelay: '0.4s' }}></span>
            </span>
            </span>
      </div>
    </div>
  );
};

const TowersLoading = (props) => <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: props?.width || "100%", height: "100%", margin: "auto", backgroundColor: props.bgColor || "#FAFAFA" }}>
  <LoadingComponent title={props?.title} />
</div>

export default memo(TowersLoading);


