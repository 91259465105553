import React, { memo, useState, useEffect } from 'react';
import { useStore } from '../../../models/ProvideModel';
import { getDPRReport } from '../../../api/transactionServer';
import { IFile } from '../../../containers/ticketPopup/tabItems/attachments/Attachment';
import { ProgressReportFrequencies } from '../../../models/enums';
import NoDataForTargets from '../targetsPage/noData/NoDataForTargets';
import { DatePickerInput } from '@mantine/dates';
import { IconCalendar } from '@tabler/icons-react';
import moment from 'moment';
import LoadingSpinner from '../../../components/loadingSkelaton/LoadingSpinner';

function extractFileData(data: { [K: string]: any; }): IFile | null {
  const { fileId, fileName, version } = data;
  if (!fileId) { return null; }
  return {
    fileId,
    fileName,
    version,
    fileType: "application/pdf",
  } as IFile;
}


const DPRReport = memo(({ reportDate }: { reportDate: string; }) => {
  const [loading, setLoading] = useState(false);
  const [reportFile, setReportFile] = useState<IFile | null>(null);
  const store = useStore();
  useEffect(() => {
    if (store.projectInfo.currentProject) {
      setLoading(() => true);
      getDPRReport({ projectId: store.projectInfo.currentProject.id, targetPeriod: reportDate, frequency: ProgressReportFrequencies.DAY })
        .then(({ data }) => {
          setReportFile(() => (Array.isArray(data) && data.length) ? extractFileData(data[0]!) : data);
          setLoading(false);
        })
        .catch(() => { setLoading(false); });
    }
  }, [reportDate]);
  return loading ?
    (
      <LoadingSpinner />
    ) :
    reportFile ?
      (
        <div style={{ display: "flex", width: "100%", height: "100%" }}>
          <iframe src={`${process.env.REACT_APP_TRANSACTION_SERVER_URL}files/download/v3?customerName=${store.auth.customerId}&fileId=${reportFile.fileId}&versionId=${reportFile.version || 0}`}
            style={{ width: "100%", height: "100%" }}
          >
          </iframe>
        </div>
      )
      :
      (
        <div style={{ display: "flex", placeContent: "center", alignSelf: "center", width: "100%" }}>
          <NoDataForTargets />
        </div>
      );
});

const DPRReportsPage = memo(() => {
  const [reportDate, setReportDate] = useState<string | null>();
  return (
    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', height: '100%' }}>
      <div style={{ display: "flex", justifyContent: 'space-between', alignItems: 'center', padding: '0.5em 1em' }}>
        <div>
          <h3 style={{ margin: 0 }}>Daily Progress Report {reportDate ? `[for: ${moment(reportDate).format('DD-MMM-YYYY')}]` : null}</h3>
        </div>
        <div style={{ display: "flex", alignItems: 'center' }}>
          <span style={{ marginRight: '1em', fontWeight: 600 }}>DPR date: </span>
          <DatePickerInput
            icon={<IconCalendar size="1.1rem" stroke={1.5} />}
            // label="Pick date"
            placeholder="Pick date"
            value={reportDate ? moment(reportDate).utc().toDate() : null}
            onChange={(val) => { setReportDate(() => val ? moment(val).format("YYYY-MM-DD") : val); }}
            // mx="auto"
            // maw={400}
            miw={200}
            valueFormat="YYYY-MM-DD"
          />
        </div>
      </div>
      <div style={{ display: "flex", width: "100%", flexGrow: 1 }}>
        {reportDate ? (
          <DPRReport reportDate={reportDate} />
        )
          : <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'inherit', width: '100%' }}>
            <div style={{ display: 'flex', padding: '2em' }}>
              <p style={{ fontSize: '1.5em', color: '#585858' }}>Please choose a date above to view report.</p>
            </div>
          </div>}
      </div>
    </div>
  );
});
export default DPRReportsPage;
