import React, { MouseEvent } from 'react';
import moment from 'moment';
import { Tooltip } from '@mantine/core';
import { IconDownload, IconFile } from '@tabler/icons-react';
import { MRT_Cell } from 'mantine-react-table';
import { downloadFile } from '../../../api/transactionServer';
import { appendFileVersion } from '../../../utils/utils'

const FileCell = ({ cell }: { cell: MRT_Cell<any>; }) => {
  const values = cell.getValue();
  const onDownload = (file) => (e: MouseEvent) => {
    e.stopPropagation();
    downloadFile({ fileId: file.fileId, fileName: appendFileVersion(file) });
  };
  if (Array.isArray(values)) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', cursor: 'auto' }} onClick={e => { e.stopPropagation(); }}>
        {values.map(file =>
          <div
            key={file.fileId}
            style={{ display: "flex", padding: '1em', background: '#f9f9f9 ', border: '0.01em solid #e9e9e9', width: "100%" }}>
            <div style={{ display: "flex", justifyContent: 'space-between', color: '#585858', fontSize: '0.9em', alignItems: 'center', width: "100%" }} key={file.fileId}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <IconFile style={{ color: '#858585', cursor: 'auto', marginRight: '0.15em' }} width={'1.3em'} height={'1.3em'} />
              </div>
              <Tooltip
                label={moment(file.createdDate).format("DD MMM YYYY hh:mm A")}
              >
                <div style={{ display: "flex", flex: 3, fontSize: '0.9em', maxWidth: '85%' }}>
                  <span style={{ wordBreak: 'break-word', overflowWrap: 'break-word', alignSelf: 'center', marginLeft: '0.5em', display: 'flex', alignItems: 'center' }}>
                    {file.fileName}
                  </span>
                </div>
              </Tooltip>
              <div style={{ display: "flex", justifyContent: 'flex-end', alignItems: 'center', flex: 1, fontSize: '0.8em' }}>
                <div style={{ display: 'flex', alignItems: 'center', marginRight: '0.5em', paddingLeft: "0.75em" }} onClick={onDownload(file)}>
                  <Tooltip label={'Download'}><IconDownload style={{ color: '#005eff', cursor: 'pointer', marginRight: '0.25em' }} width={'1.5em'} height={'1.5em'} /></Tooltip>
                </div>
              </div>
            </div>
          </div >
        )
        }
      </div>
    );
  }
  return "—";
};

export default FileCell;
