import React, { useState, useEffect, ChangeEvent, FormEvent } from 'react';
import axios from 'axios';
import {
  TextField,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Switch,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import Error from '../error/ErrorPopup';
import { useStore } from '../../../models/ProvideModel';
import { observer } from 'mobx-react-lite';
import { getAxiosHeader, getImageAcceptType, millisToMinutesAndSeconds, replaceURLPlaceholders } from '../../../utils/utils';
import LoadingIntermediate from '../loading/LoadingIntermediate'
import useStyles from './FormStyles';
import { FileInput } from '@mantine/core';
import { IconPhoto } from '@tabler/icons-react';


const Form: React.FC<{
  formData: any[], // Define or import your specific types for formData, axiosConfig, etc.
  handleResponse: (json: any) => void
}> = ({ formData, handleResponse }) => {
  const store = useStore();
  const [formValues, setFormValues] = useState<{ [key: string]: any }>({});
  const [dropdownOptions, setDropdownOptions] = useState<{ [key: string]: any[] }>({});
  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(true);
  const [apiError, setApiError] = useState<any | undefined>(undefined);
  const classes = useStyles()
  //   const [loading, setLoading] = useState<boolean>(false);
  const isDisabled = store.adminModule.loading

  // Create a map of dependencies for each field
  const dependenciesMap = formData.reduce((acc, field) => {
    if (field.dependentOn) {
      field.dependentOn.forEach(dependentField => {
        if (!acc[dependentField]) {
          acc[dependentField] = [];
        }
        acc[dependentField].push(field.id);
      });
    }
    return acc;
  }, {} as { [key: string]: string[] });

  // console.log('dependenciesMap', dependenciesMap)

  useEffect(() => {
    const initialValues: { [key: string]: any } = {};
    formData.forEach(field => {
      if (field.type === 'dropdown' || field.type === 'multiSelect') {
        if (field.staticOptions.length > 0) {
          setDropdownOptions(prevOptions => ({
            ...prevOptions,
            [field.id]: field.staticOptions,
          }));
        } else if (field.endpoint) {
          const placeholderRegex = /:(\w+)/g;
          if(!placeholderRegex.test(field.endpoint)) { //to avoid unncecessary api calls
            fetchDropdownOptions(field.id, field.endpoint);
          }
        }
        initialValues[field.id] = field.multiselect ? [] : '';
      } else if (field.type === 'boolean') {
        initialValues[field.id] = field.default || false;
      } else if (field.type === 'text') {
        initialValues[field.id] = field.default || '';
      } else if (field.type === 'textArray') {
        initialValues[field.id] = [];
      } else if (field.type === 'LOGIN_USER') {
        initialValues[field.id] = store.auth.userName;
      } else if (field.type === 'SET_CUSTOMER') {
        initialValues[field.id] = store.adminModule.customerId || store.auth.customerId;
      } else if (field.type === 'FILE') {
        initialValues[field.id] = null;
      }
    });
    setFormValues(initialValues);
    checkMandatoryFields();

    //Runs when customer is set on initial render
    formData.map(field => {
      if (field.type === 'SET_CUSTOMER') {
        setFormValues(prevValues => {
          const updatedValues = prevValues;
    
          // Update all dependent fields
          if (dependenciesMap[field.id]) {
            dependenciesMap[field.id].forEach(dependentFieldId => {
              const dependentField = formData.find(field => field.id === dependentFieldId);
              if (dependentField && dependentField.endpoint) {
                
                const endpoint = replaceURLPlaceholders(dependentField.endpoint, updatedValues);
                // console.log(`changed field ${fieldId} - dependent field ${dependentField} 's endpoint is ${endpoint}`, )
                fetchDropdownOptions(dependentFieldId, endpoint);
              } else {
                updatedValues[dependentFieldId] = ''; //set to empty
              }
            });
          }
    
          return updatedValues;
        });
      }
    })
  }, [formData, store.adminModule.customerId]);

  useEffect(() => {
    checkMandatoryFields();
  }, [formValues]);


  const checkMandatoryFields = () => {
    const allMandatoryFilled = formData.every(field => {
      if (field.mandatory) {
        return formValues[field.id] !== undefined && formValues[field.id] !== '' && formValues[field.id] !== null;
      }
      return true;
    });
    setIsSubmitDisabled(!allMandatoryFilled);
  };


  const fetchDropdownOptions = async (fieldId: string, endpoint: string) => {
    try {
      const response = await axios.get(endpoint);
      if (response.data?.data?.length === 0 || response.data?.data == undefined) {
        const fieldName = formData.find(field => field.id === fieldId)?.name || fieldId;
        setDropdownOptions(prevOptions => ({
          ...prevOptions,
          [fieldId]: [{ id: 'no-data', name: `No ${fieldName} data found` }],
        }));
      } else {
        setDropdownOptions(prevOptions => ({
          ...prevOptions,
          [fieldId]: response.data.data,
        }));
      }
    } catch (error) {
      console.error('Failed to fetch dropdown options:', error);
    }
  };

  const handleFileChange = (event: File, fieldId: string) => {
    setFormValues(prevValues => ({
      ...prevValues,
      [fieldId]: event
    }));
  }

  const handleChange = (event: ChangeEvent<{ value: unknown } | HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>, fieldId: string) => {
    const { checked, value, type } = event.target as HTMLInputElement;
    const newValue = type === 'checkbox' ? checked : type === 'textarea' ? value.split('\n').map(line => line.trim()) : value;
    // setFormValues(prevValues => ({
    //   ...prevValues,
    //   [fieldId]: newValue
    // }));

    setFormValues(prevValues => {
      const updatedValues = {
        ...prevValues,
        [fieldId]: newValue
      };

      // Update all dependent fields
      if (dependenciesMap[fieldId]) {
        dependenciesMap[fieldId].forEach(dependentFieldId => {
          const dependentField = formData.find(field => field.id === dependentFieldId);
          if (dependentField && dependentField.endpoint) {
            updatedValues[dependentFieldId] = '';
            const endpoint = replaceURLPlaceholders(dependentField.endpoint, updatedValues);
            // console.log(`changed field ${fieldId} - dependent field ${dependentField} 's endpoint is ${endpoint}`, )
            fetchDropdownOptions(dependentFieldId, endpoint);
          } else {
            updatedValues[dependentFieldId] = ''; //set to empty
          }
        });
      }

      return updatedValues;
    });
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // setLoading(true);
    store.adminModule.updateLoading();

    try {
      let url = store.adminModule.currentRequest?.url;
      let bodyFormData = new FormData()
      if (store.adminModule.currentRequest?.input === 'QUERY') {
        const queryParams = new URLSearchParams();
        Object.entries(formValues).forEach(([key, value]) => {
          queryParams.append(key, value);
        });
        url += `?${queryParams.toString()}`;
        // console.log('url is->', url)
      } else if (store.adminModule.currentRequest?.input === 'FORM_DATA') {
        Object.entries(formValues).forEach(([key, value]) => {
          bodyFormData.append(key, value);
        });
      } else if(store.adminModule.currentRequest?.input === 'PARAM') {
        Object.entries(formValues).forEach(([key, value]) => {
          // queryParams.append(key, value);
          url += `/${value.toString()}`;
        });
      }

      const headers = {...getAxiosHeader(store.adminModule.currentRequest?.input), ...store.adminModule.currentRequest?.meta.toJSON().headers}
      // console.log('headers',headers, 'meta', store.adminModule.currentRequest?.meta.toJSON().headers)

      const startTime = Date.now();
      const response = await axios({
        method: store.adminModule.currentRequest?.method || 'POST',
        url: url,
        // headers: getAxiosHeader(store.adminModule.currentRequest?.input),
        headers,
        data: store.adminModule.currentRequest?.input === 'FORM_DATA' ? bodyFormData : formValues
      });
      const duration = Date.now() - startTime
      //   setLoading(false);
      console.log('response-response',response)
      store.adminModule.updateLoading();
      handleResponse({...response.data, time: millisToMinutesAndSeconds(duration)});
    } catch (error) {
      //   setLoading(false);
      store.adminModule.updateLoading();
      console.error('Form submission failed:', error, error.response);
      setApiError(error.response?.data || "Error");
    }
  };

  // console.log('formvalues', formValues)
  return (
    <Paper elevation={3} style={{ display: 'flex', flexDirection: 'column', padding: '1em', width: '90%', background: '#fffff7', borderRadius: 0 }}>
      <form onSubmit={handleSubmit}>
        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', alignItems: 'center' }}>
          {formData.map((field, index) => (
            <div key={field.id} className={classes.field} style={{ width: '23%', marginBottom: '0.5em' }}>
              {field.type === 'text' && (
                <TextField
                  disabled={isDisabled || field.readOnly}
                  fullWidth
                  id="outlined-basic"
                  label={field.name}
                  variant="outlined"
                  name={field.id}
                  value={formValues[field.id] || ''}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e as any, field.id)}
                  required={field.mandatory}
                  InputProps={{
                    classes: {
                      input: classes.textField,
                      disabled: classes.disabledTextField
                    },
                  }}
                />
              )}
              {(field.type === 'dropdown' || field.type === 'multiSelect') && (
                <FormControl disabled={isDisabled} fullWidth>
                  <InputLabel id={`demo-simple-select-label-${index}`}>{field.name}</InputLabel>
                  <Select
                    id={`select-${field.id}`}
                    multiple={field.multiselect}
                    value={formValues[field.id] || (field.multiselect ? [] : '')}
                    onChange={(e: ChangeEvent<{ value: unknown }>) => handleChange(e, field.id)}
                    required={field.mandatory}
                    // style={{ fontSize: '0.7375em' }}
                    renderValue={(selected) => {
                      if (Array.isArray(selected)) {
                        return selected.map((value) => dropdownOptions[field.id].find((option) => option.id === value)?.name).join(', ');
                      }
                      return dropdownOptions[field.id].find((option) => option.id === selected)?.name || '';
                    }}
                  >
                    {
                    dropdownOptions[field.id]?.length === 1 && dropdownOptions[field.id][0].id === 'no-data' ? (
                      <MenuItem disabled>{dropdownOptions[field.id][0].name}</MenuItem>
                    ) : (dropdownOptions[field.id]?.map(option => (
                      <MenuItem key={option.id} value={option.id} style={{ fontSize: '0.7375em' }}>
                        {/* {option.name} */}
                        <div style={{display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'start'}}>
                        <span style={{ fontSize: '1em', fontWeight: 500 }}>{option.name}</span>
                        <span style={{ fontSize: '0.8em', color: '#888', marginBottom: '1.15em', marginTop: '0.35em', lineHeight: 0 }}>{option.id}</span>
                        </div>
                      </MenuItem>
                    )))
                    }
                  </Select>
                </FormControl>
              )}
              {field.type === 'boolean' && (
                <FormControlLabel
                  control={
                    <Switch
                      style={{ fontSize: '0.7375em' }}
                      checked={formValues[field.id] as boolean || false}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e as any, field.id)}
                    />
                  }
                  label={field.name}
                  disabled={isDisabled}
                />
              )}
              {field.type === 'textArray' && (
                <TextField
                  disabled={isDisabled}
                  fullWidth
                  id={field.id}
                  label={field.name}
                  multiline
                  rows={2}
                  variant="outlined"
                  value={formValues[field.id] ? formValues[field.id].join(',') : []}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e as any, field.id)}
                  required={field.mandatory}
                  InputProps={{
                    classes: {
                      input: classes.textField,
                      disabled: classes.disabledTextField
                    },
                  }}
                />
              )}
              {field.type === 'LOGIN_USER' && (
                <TextField
                  disabled={true}
                  fullWidth
                  id="outlined-basic"
                  label={field.name}
                  variant="outlined"
                  name={field.id}
                  value={formValues[field.id] || ''}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e as any, field.id)}
                  required={field.mandatory}
                  InputProps={{
                    classes: {
                      input: classes.textField,
                      disabled: classes.disabledTextField
                    },
                  }}
                />
              )}
              {field.type === 'SET_CUSTOMER' && (
                <>
                  {/* <span>{field.name}</span> */}
                  <TextField
                    disabled={true}
                    fullWidth
                    id="outlined-basic"
                    label={field.name}
                    variant="outlined"
                    name={field.id}
                    value={store.adminModule.customerId || formValues[field.id] || ''}
                    // onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e as any, field.id)}
                    required={field.mandatory}
                    InputProps={{
                      classes: {
                        input: classes.textField,
                        disabled: classes.disabledTextField
                      },
                    }}
                  />
                </>
              )}
              {field.type === 'number' && (
                <TextField
                  disabled={isDisabled}
                  fullWidth
                  id="outlined-basic"
                  label={field.name}
                  variant="outlined"
                  name={field.id}
                  value={formValues[field.id] || ''}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e as any, field.id)}
                  required={field.mandatory}
                  type="number"
                  InputProps={{
                    classes: {
                      input: classes.textField,
                      disabled: classes.disabledTextField
                    },
                  }}
                />
              )}
              {field.type === "FILE" && (
                <FileInput
                  label={field.name}
                  disabled={isDisabled}
                  placeholder="Upload file"
                  icon={<IconPhoto size={'xs'} />}
                  accept={getImageAcceptType(field.allowedTypes)}
                  onChange={(e: File) => handleFileChange(e as any, field.id)}
                  required={field.mandatory}
                />
              )}
              {/* {formErrors[field.id] && <p style={{ color: 'red', fontSize: '0.5em' }}>{formErrors[field.id]}</p>} */}
            </div>
          ))}
        </div>
        {/* <Button variant="contained" type="submit" disabled={isSubmitDisabled || store.adminModule.loading} className={classes.submitbutton}>{store.adminModule.loading ? 'Loading...' : 'Submit'}</Button> */}
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
          <Button variant="contained" type="submit" disabled={isSubmitDisabled || store.adminModule.loading} className={classes.submitbutton}>{store.adminModule.loading ? 'Loading...' : 'Submit'}</Button>
        </div>
        {/* {Object.keys(formErrors).map(e => (
          <p key={e} style={{ color: 'red', fontSize: '0.5em' }}>{e} : {formErrors[e]}</p>
        ))} */}
      </form>
      <Error response={apiError} open={!!apiError} handleClose={() => setApiError(undefined)} />
      {store.adminModule.loading ? <LoadingIntermediate /> : null}
    </Paper>

  );
};

export default observer(Form);

