import React, { memo, useReducer, MemoExoticComponent, FunctionComponent, useMemo, useState, useEffect, useRef, useCallback } from 'react';
import { chan, fixed, go, IChan, IStream, putAsync } from 'csp-with-ts';
import { compose, map } from 'transducist';
import { observer } from 'mobx-react-lite';
import { Button } from '@material-ui/core';
import moment from 'moment';
import useStyles from './NewUnitPopupStyles';
import FormDropdown, { IFormDropdownInputProps } from '../../components/filter/FormDropdown';
import DateTimeInput, { IDateTimeInputProps } from '../../components/FormInputs/DateTimeInput';
import GenericInput, { IGenericInputProps } from '../../components/FormInputs/GenericInput';
import { startProcessByDefinitionKey, getDropdownOption } from '../../api/transactionServer';
import { convertToISO, transformDate, transformDateTime, transformPercent, transformTime } from '../../utils/utils';
import GenericModal from '../GenericModal';
import LocationPicker from '../locationPicker/LocationPicker';
import { GET_BLOCK_UNITS, GET_FLOOR_UNITS, GET_UNIT_UNITS } from '../../utils/queries';
import { useGetState } from '../../components/filter/UseAccordionHook';
import MantineImageField from '../../components/FormInputs/MantineImageField';
import { CloseButton, Button as MantineButton, Chip, Badge, TextInput, Select, NumberInput } from '@mantine/core'
import { IFormField, ITicketType, IUser } from '../../models/Ticket';
import AssigneeList from '../../components/assignees/AssigneeList';
import { IMSTMap, IAnyType } from 'mobx-state-tree';
import { IconX } from "@tabler/icons-react";
import RichTextDescription from '../../components/richText/RichTextDescInput'
import { useStore } from '../../models/ProvideModel';

const dropdownFields = ["blocks", "unitTypes"];

const NewUnitPopup = ({ onClose }: { onClose: () => void; }) => {
  const store = useStore();
  const classes = useStyles();
  return (
    <div className={classes.mainDiv}>
      <div className={classes.newRequestPopupTitleDiv}>
        <span className={classes.newRequestPopupTitle}>New Unit</span>
        {/* <div className={classes.newRequestPopupClose} onClick={onClose}><span className={classes.closeHover}>Close</span></div> */}
        <div className={classes.newRequestPopupClose}><CloseButton color='red' title='Close' onClick={onClose} /></div>
      </div>
      <div className={classes.handleContainer} style={{ borderLeft: '0.01em solid #999999', borderRight: '0.01em solid #999999', background: "#F6F6F6", padding: '1em 4em' }}>
        <div className={classes.handleScrollLayer} style={{ marginBottom: '10%' }}>
          <div className={classes.fieldData}>
            <TextInput
              required
              label="Unit Id"
              onBlur={event => { store.planning.newUnit?.edit("id", event.target.value); }}
            />
          </div>
          <div className={classes.fieldData}>
            <TextInput
              required
              label="Unit Title"
              onBlur={event => { store.planning.newUnit?.edit("name", event.target.value); }}
            />
          </div>
          <div className={classes.fieldData}>
            <Select
              required
              label="Block"
              data={(store.planning.dropdowns.get("blocks")?.options || []).map(({ id, name }) => ({ label: name, value: id }))}
              value={store.planning.newUnit?.blockId || ""}
              onChange={val => { store.planning.newUnit?.edit("blockId", val); }}
            />
          </div>
          <div className={classes.fieldData}>
            <NumberInput
              required
              label="Floor Index"
              value={store.planning.newUnit?.floorIdx || ""}
              onChange={val => {
                const isNotNum = typeof val === "string" && !val.length;
                store.planning.newUnit?.edit("floorIdx", isNotNum ? null : val);
              }}
            />
          </div>
          <div className={classes.fieldData}>
            <TextInput
              required
              label="Floor Name"
              onBlur={event => { store.planning.newUnit?.edit("floorName", event.target.value); }}
            />
          </div>
          <div className={classes.fieldData}>
            <Select
              required
              label="Unit Type"
              data={(store.planning.dropdowns.get("unitTypes")?.options || []).map(({ id, name }) => ({ label: name, value: id }))}
              value={store.planning.newUnit?.unitTypeId || ""}
              onChange={val => { store.planning.newUnit?.edit("unitTypeId", val); }}
            />
          </div>
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "column", paddingTop: "1em", flexGrow: 1, flexShrink: 0, boxShadow: "0px -10px 10px -7px rgba(0,0,0,0.12)", background: '#FFF', border: '0.01em solid #999999', }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ flexShrink: 1, display: "flex", paddingBottom: "1em", justifyContent: "center" }}>{
            !store.planning.newUnit?.canSubmit ? (<span style={{ color: "red", fontSize: '0.9em' }}>You can only submit the form when all mandatory fields are filled.</span>) : null}</div>
          <div className={classes.submitCancelDiv}>
            <Button
              disabled={!store.planning.newUnit?.canSubmit}
              className={classes.submitbutton}
              onClick={() => { store.planning.createUnit(); }}
            >
              CREATE
            </Button>
          </div>
        </div>
      </div>
    </div >
  );
};

export default observer(NewUnitPopup);
