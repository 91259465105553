import { makeStyles, Theme } from "@material-ui/core/styles";
import { TicketStatusCategories } from "../../../models/enums";
import { materialStatusColours } from "../../../utils/constants";

export interface IStatusStyle {
  status: TicketStatusCategories;
  hideCoreFields: boolean;
}

const useStyles = makeStyles<Theme, IStatusStyle>((theme: Theme) => ({
  root: props => ({
    display: "flex",
    width: "100%",
    flexBasis: "auto",
    background: "#FFF",
    padding: '0.15em 1em',
    // marginBottom: '1em',
    flexDirection: 'column'
  }),
  main: props => ({
    display: 'flex',
    // justifyContent: 'space-between',
    justifyContent: 'flex-start',
    width: '100%',
    flexWrap: 'wrap'
    // flexWrap: 'nowrap',
  }),
  coreItem: props => ({
    flexBasis: 'calc(33.33% - 10px)',
    marginBottom: '0.5em',
    // display: 'flex',
    // width: '100%'
  }),
  key: {
    fontSize: "0.7em",
    color: '#585858',
    opacity: 0.8,
    fontWeight: 600
  },
  value: {
    fontSize: "0.92em",
    color: '#585858',
    marginTop: 4
  },
  status: props => ({
    width: '0.8em',
    height: '0.8em',
    display: "inline-block",
    backgroundColor: materialStatusColours[props.status]?.bg || "white",
    borderRadius: "50%",
    border: `1px solid ${materialStatusColours[props.status]?.border || "white"}`,
  }),
  coreFieldDiv: {
    display: 'flex',
    flexDirection: 'column'
  },
  showHideAction: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: '0.7em',
    cursor: 'pointer',
    background: "#FFF",
    border: "0.01em solid #E9E9E9",
    padding: 0,
  },
  arrow: props => ({
    width: '1.5em',
    transform: props.hideCoreFields ? 'rotate(0deg)' : 'rotate(180deg)'
  }),
  gridItem: {
    padding: '0.5em !important'
  }
}));

export default useStyles;
