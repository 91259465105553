import React, { memo, useState } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Avatar, Box, Group, Text, TextInput } from '@mantine/core';
import { getInitials } from "../../utils/utils";
import { IUser } from "../../models/Ticket";
import { IconSearch } from "@tabler/icons-react";


const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    // padding: '1em'
  },
  rootItem: {
    padding: '0.5em',
    height: 200,
    overflowY: 'scroll'
  },
  boxRoot: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  boxItem: {
    padding: '0.1em 0em',
  }
}));

const AssigneeList = ({ userId, handleAssigneeUser, options, state, multiSelect }: { userId: string | undefined; handleAssigneeUser: (userId: string) => void; options: any[]; state?: string[]; multiSelect?: boolean; }) => {
  const classes = useStyles();
  const [searchText, setSearchText] = useState<string>('');
  const [filteredValues, setFilteredValues] = useState<{ id: string, name: string }[]>(options);

  const updateFilteredList = (inputText: string) => {
    const searchText = inputText.toLowerCase();
    const filteredValues = options.filter((value) =>
      value.name.toLowerCase().includes(searchText)
    );
    setFilteredValues(filteredValues);
  };

  const sortAtoZ = (a: any, b: any) => a.name.localeCompare(b.name)

  return (
    <div className={classes.root}>
      <TextInput
        placeholder="Search users"
        value={searchText}
        onChange={(e) => {
          setSearchText(e.target.value);
          updateFilteredList(e.target.value);
        }}
        rightSection={<IconSearch width={'0.75em'} height={'0.75em'} color="#b9b9b9" />}
        autoFocus
      />
      <div className={classes.rootItem}>
        {/* <Radio.Group
            name="assigneeList"
            > */}
        {/* <Group> */}
        <div className={classes.boxRoot}>
          {filteredValues.sort(sortAtoZ).map(user => (<div className={classes.boxItem} key={user.id} >
            <Box
              sx={(theme) => ({
                backgroundColor: !!multiSelect ? state?.includes(user?.id || "") ? theme.colors.gray[1] : '#FFFFFF' : user?.id == userId ? '#F9F9F9' : '#FFFFFF',
                color: user?.id == userId ? '#B9B9B9' : '#585858',
                textAlign: 'left',
                padding: theme.spacing.xs,
                borderRadius: theme.radius.xs,
                cursor: user.id == userId ? 'not-allowed' : 'pointer',
                '&:hover': {
                  backgroundColor:
                    theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1],
                },
              })}
              onClick={() => user.id == userId ? null : handleAssigneeUser(user.id) }
            >
              <Group>
                <Avatar color="cyan" radius="xl" size={"sm"}>{getInitials(user.name)}</Avatar>
                <div>
                  <Text style={{fontSize: '0.95em'}}>{user?.name}</Text>
                  {/* <Text size="xs" color="dimmed">bob@handsome.inc</Text> */}
                </div>
              </Group>
            </Box>
          </div>))}
        </div>
        {/* </Group> */}
        {/* </Radio.Group> */}
      </div>
    </div>
  )
}

export default memo(AssigneeList);
