import { flow, getEnv, getParent, getRoot, Instance, types } from 'mobx-state-tree';
import { GET_DASHBOARDS, GET_FINISHING_TOWER_BLOCKS, GET_TOWERS } from '../utils/queries';
import { snToCellAddressActivities, snToCellAddressNumId, snToCellAddressStringId, snToCellAddressNull } from './Brick/CellAddress';
import { snToFinishingLPBrick, snToFinishingFSBrick, snToFinishingTcaLPBrick, snToFinishingTcaFSBrick, snToFinishingTcaLPBaratheonBrick, snToFinishingTcaFSBaratheonBrick } from './Brick/Finishing';
import { snToPourBrick } from './Brick/Pour';
import { BricksCollection } from './BrickCollection';
import { IParams, ePhase } from './Common';
import { LateStoreModel } from './DataStore';
import { BrickTypes, CellAddressType, DashboardTypes, getThrones, GOT, TabTypes } from './enums';
import { Tower } from './Tower';
import { captureEventCallback, sortingStrategy, sortingStrategyRev } from '../utils/utils';
import * as Sentry from "@sentry/browser";

// const __id_checker = (val: string | number): val is DashboardPages => {
//     switch (val) {
//         case DashboardPages.STRUCTURES_TOWER:
//         case DashboardPages.FINISHING_TOWER:
//         case DashboardPages.FINISHING_TCA_LANDING:
//         case DashboardPages.FINISHING_TCA_FULLSCREEN: return true;
//         default: return false;
//     }
// }
const __dispatcher = (val) => {
    switch (val.type) {
        // case DashboardPages.STRUCTURES_TOWER: return Dashboard1;
        // case DashboardPages.FINISHING_TOWER: return Dashboard2;
        // case DashboardPages.FINISHING_TCA_LANDING: return Dashboard3;
        case DashboardTypes.STRUCTURES_TOWER_TYPE: return Dashboard1;
        case DashboardTypes.FINISHING_TOWER_TYPE: return Dashboard2;
        case DashboardTypes.TABS: return Dashboard3;
        default: throw new Error('unimplemented');
    }
};

const __dispatcher1 = (val) => {
    switch (val.type) {
        case TabTypes.LANDING: return __Tab_LP;
        case TabTypes.FULLSCREEN: return __Tab_FS;
        default: throw new Error('unimplemented');
    }
};

/* Question: How to show summaries? */
/*
  Dashboards
  ,----
  |Defining Dashboards of different types. The intention is to capture the
  |various different summaries and tabs complexities in different models, and
  |then represent a "Dashboard" model as a union type.
  `----
*/
export const Dashboard1 = types.model("STRUCTURES_TOWER_TYPE",
    {
        // id: types.refinement(types.identifier, __id_checker),
        id: types.identifier,
        type: DashboardTypes.STRUCTURES_TOWER_TYPE,
        // progressDetails: types.map(TowerStructuresProgressDetails),
        towers: types.array(types.reference(Tower)),
        graphs: types.map(BricksCollection)
    }).volatile(_ => ({ loading: false }))
    .views(self => ({
        get params(): IParams {
            return (getRoot(self) as Instance<typeof LateStoreModel>).params;
        },
        get floorSort(): boolean {
            return (getRoot(self) as Instance<typeof LateStoreModel>).filters.floorSort;
        }
    })).views(self => ({
        get lpColumnPos() {
            if (!self.graphs.size) { return [0]; }
            return (getRoot(self) as Instance<typeof LateStoreModel>).towersList.reduce((acc, tower, i) => [...acc, acc[i] + (self.graphs.get(tower.id)?.columnSortedFiltered.length || - 2) + 2], [0]).slice(0, -1);
        },
        get lpRows() {
            if (!self.graphs.size) { return []; }
            const rows = Array.from(self.graphs.values()).map(graph => graph.rowSorted)
            let ret = rows[0];
            let i = 1;
            let indices = ret.map(({ id }) => id);
            const len = rows.length;
            for (; i < len; i++) {
                rows[i].forEach(row => { if (!indices.includes(row.id)) { ret.push(row); indices.push(row.id); } });
            }
            // check for asc or desc
            // self.rowType === CellAddressType.FLOOR_NUM ?
            const sort = self.floorSort ? sortingStrategyRev : sortingStrategy;
            return ret.sort(sort);
        }
    })).views(self => ({
        get hasData() {
            if (self.params.screen === 'landing') {
                return !!self.lpColumnPos.length && !!self.lpRows.length;
            }
            else if (self.params.screen === 'fullscreen' && !!self.params.tower && self.graphs.has(self.params.tower)) {
                const g = self.graphs.get(self.params.tower)!;
                return !!g.columns.length && !!g.rows.length && !!g.bricks.length;
            }
            return false;
        },
        get columns() {
            if (self.params.screen === 'landing') { return (getRoot(self) as Instance<typeof LateStoreModel>).towersList.reduce((acc, t) => [...acc, null, ...(self.graphs.get(t.id)?.columnSortedFiltered || []), null], []).slice(1, -1); }
            if (self.params.screen === 'fullscreen' && !!self.params.tower && self.graphs.has(self.params.tower)) {
                return self.graphs.get(self.params.tower)!.columnSortedFiltered;
            }
            return [];
        },
        get rows() {
            if (self.params.screen === 'landing') { return self.lpRows; }
            if (self.params.screen === 'fullscreen' && !!self.params.tower && self.graphs.has(self.params.tower)) {
                return self.graphs.get(self.params.tower)!.rowSorted;
            }
            return [];
        }
    })).views(self => ({
        get totalColumns() {
            const arr = (getRoot(self) as Instance<typeof LateStoreModel>).towersList.reduce((acc, t) => self.graphs.has(t.id) ? [...acc, self.graphs.get(t.id)] : acc, []);
            return arr.reduce((acc, t) => (acc + t.columns.length), 0) + arr.length * 2;
        },
        get totalRows() {
            return self.rows.length;
        }
    })).views(self => ({
        // get progressDetails() {
        //     if (self.params.screen === 'landing' && !!self.params.tower) { return self.progressDetails.get(self.params.tower) || null; }
        // },
        // getProgressDetails(id: string) {
        //     return self.progressDetails.get(id) || null;
        // },
        getBrick(xVal: number, yVal: number) {
            if (self.params.screen === 'fullscreen' && !!self.params.tower && self.graphs.has(self.params.tower)) {
                return self.graphs.get(self.params.tower)!.getBrick(xVal, yVal);
            }
            if (self.params.screen === 'landing' && self.hasData) {
                const unsafeIndex = self.lpColumnPos.findIndex((strt: number) => (xVal < strt));
                const [tower, index] = unsafeIndex > -1 ? [(getRoot(self) as Instance<typeof LateStoreModel>).towersList[unsafeIndex - 1], unsafeIndex - 1] : [(getRoot(self) as Instance<typeof LateStoreModel>).towersList[(getRoot(self) as Instance<typeof LateStoreModel>).towersList.length - 1], (getRoot(self) as Instance<typeof LateStoreModel>).towersList.length - 1];
                if (!tower) { return null }
                return self.graphs.get(tower.id)?.getBrick(xVal - self.lpColumnPos[index], yVal) || null;
            }
            return null;
        }
    })).actions(self => ({
        getDashboards: flow(function* getDashboards() {
            const root = getRoot(self) as Instance<typeof LateStoreModel>;
            try {
                if (!root.towers.loading && root.towers.length && !self.loading && !self.graphs.size && !self.towers.length) {
                    const { dashboard } = yield root.fetch(GET_DASHBOARDS.structuresTower, { variables: { blocks: root.towers.map(({ id }) => id) } });
                    if (!dashboard.length) {
                        const metadata = { customer: root.auth.customerId, user: root.auth.userId, path: root.params.path, section: root.params.section, phase: root.params.phase, spaceType: root.params.spaceType };
                        getEnv(self).mixpanel.track("NoData", { ...metadata });
                        Sentry.withScope(captureEventCallback(metadata));
                    }
                    if (dashboard.length) {
                        dashboard.forEach(({ id, name, progressDetails, rows, columns, units }) => {
                            self.towers.push(id);
                            // if (progressDetails) {
                            //     self.progressDetails.set(id, { id, ...progressDetails });
                            // }
                            self.graphs.set(id, {
                                id,
                                name,
                                type: GOT.STARK,
                                brickType: BrickTypes.S,
                                columnType: CellAddressType.POUR_NUM,
                                rowType: CellAddressType.FLOOR_NUM,
                                rows: rows.rows?.map(val => snToCellAddressNumId(CellAddressType.FLOOR_NUM, val)),
                                columns: columns.map(val => snToCellAddressNumId(CellAddressType.POUR_NUM, val)),
                                bricks: units.map(val => snToPourBrick(val))
                            });
                        });
                    }
                }
            }
            catch (err) { throw err; }
        })
    }));

export const Dashboard2 = types.model("FINISHING_TOWER_TYPE",
    {
        // id: types.refinement(types.identifier, __id_checker),
        id: types.identifier,
        type: DashboardTypes.FINISHING_TOWER_TYPE,
        towers: types.array(types.reference(Tower)),
        graphs: types.map(BricksCollection)
    }).volatile(_ => ({ loading: false }))
    .views(self => ({
        get params(): IParams {
            return (getRoot(self) as Instance<typeof LateStoreModel>).params;
        },
        get lpColumnPos(): number[] {
            return [0];
        }
    })).views(self => ({
        get hasData() {
            if (self.params.screen === 'landing' && self.graphs.has('landing')) {
                const g = self.graphs.get('landing')!;
                return !!g.columns.length && !!g.rows.length && !!g.bricks.length;
            }
            else if (self.params.screen === 'fullscreen' && !!self.params.tower && self.graphs.has(self.params.tower)) {
                const g = self.graphs.get(self.params.tower)!;
                return !!g.columns.length && !!g.rows.length && !!g.bricks.length;
            }
            return false;
        },
        get graph() {
            if ((self.params.screen === 'landing' || self.params.graphType === "barChart") && self.graphs.has('landing')) { return self.graphs.get('landing')!; }
            if (self.params.screen === 'fullscreen' && !!self.params.tower && self.graphs.has(self.params.tower)) {
                return self.graphs.get(self.params.tower)!;
            }
            return null;
        }
    })).views(self => ({
        get columns() { return self.graph?.columnSortedFiltered || []; },
        get rows() { return self.graph?.rowSorted || []; },
    })).views(self => ({
        get totalColumns() {
            return self.columns.length + 2;
        },
        get totalRows() {
            return self.rows.length;
        }
    })).views(self => ({
        getBrick(xVal: number, yVal: number) {
            return self.graph?.getBrick(xVal, yVal) || null;
        }
    })).actions(self => ({
        getTowers: flow(function* getTowers() {
            const root = getRoot(self) as Instance<typeof LateStoreModel>;
            if (!root.loading && root.towers.length && !self.loading && !self.towers.length) {
                try {
                    const { blocks } = yield root.fetch(GET_FINISHING_TOWER_BLOCKS, { variables: { blocks: root.towers.map(({ id }) => id) } });
                    if (!blocks.length) {
                        const metadata = { customer: root.auth.customerId, user: root.auth.userId, path: root.params.path, section: root.params.section, phase: root.params.phase, spaceType: root.params.spaceType };
                        getEnv(self).mixpanel.track("NoData", { ...metadata });
                        Sentry.withScope(captureEventCallback(metadata));
                    }
                    self.towers.clear();
                    self.towers.push(...blocks.map(({ id }) => id));
                } catch (err) { throw err; }
            }
        }),
        getDashboard: flow(function* getDashboard(id: string) {
            const root = getRoot(self) as Instance<typeof LateStoreModel>;
            try {
                if (!root.towers.loading && root.towers.length && self.towers.length && !self.loading && !self.graphs.has(id)) {
                    if (id === 'landing') {
                        const { units, rows, columns } = yield root.fetch(GET_DASHBOARDS.finishingTowerLanding, { variables: { blocks: self.towers.map(({ id }) => id) } });
                        if (!units.length) {
                            const metadata = { customer: root.auth.customerId, user: root.auth.userId, path: root.params.path, section: root.params.section, phase: root.params.phase, spaceType: root.params.spaceType };
                            getEnv(self).mixpanel.track("NoData", { ...metadata });
                            Sentry.withScope(captureEventCallback(metadata));
                        }
                        self.graphs.set('landing', {
                            id: 'landing',
                            name: 'Overview',
                            type: GOT.STARK,
                            brickType: BrickTypes.F,
                            columnType: CellAddressType.ACT_STRING,
                            rowType: CellAddressType.TOWER_STRING,
                            rows: rows.rows?.map(val => snToCellAddressStringId(CellAddressType.TOWER_STRING, val)),
                            columns: columns.map(val => snToCellAddressActivities(val)),
                            bricks: units.map(val => snToFinishingLPBrick(val))
                        });
                    }
                    else {
                        if (!self.graphs.has(id) && self.towers.map(({ id }) => id).includes(id)) {
                            const tower = self.towers.find(t => t.id === id) || null;
                            const { units, rows, columns } = yield root.fetch(GET_DASHBOARDS.finishingTowerFullscreen, { variables: { block: id } });
                            if (!units.length) {
                                const metadata = { customer: root.auth.customerId, user: root.auth.userId, path: root.params.path, section: root.params.section, phase: root.params.phase, spaceType: root.params.spaceType };
                                getEnv(self).mixpanel.track("NoData", { ...metadata });
                                Sentry.withScope(captureEventCallback(metadata));
                            }
                            self.graphs.set(id, {
                                id,
                                name: 'Detail - ' + tower,
                                type: GOT.STARK,
                                brickType: BrickTypes.F,
                                columnType: CellAddressType.ACT_STRING,
                                rowType: CellAddressType.FLOOR_NUM,
                                rows: rows.map(val => snToCellAddressNumId(CellAddressType.FLOOR_NUM, val)),
                                columns: columns.map(val => snToCellAddressActivities(val)),
                                bricks: units.map(val => snToFinishingFSBrick(val))
                            });
                        }
                    }
                }
            } catch (err) { throw err; }
        })
    })).actions(self => ({
        getDashboards: flow(function* getDashboards() {
            if (!self.loading && !self.towers.length) { yield self.getTowers(); }
            if (self.params.screen === 'landing' || self.params.graphType === "barChart") { yield self.getDashboard('landing'); }
            else if (self.params.screen === 'fullscreen' && self.params.tower && !self.towers.includes(self.params.tower)) { yield self.getDashboard(self.params.tower); }
        })
    }));

const __Tab = types.model("Tab", {
    id: types.identifier,
    name: types.string,
    graphType: types.frozen<GOT>(),
    phase: ePhase,
    spaceType: types.string,
    towers: types.array(types.reference(Tower)),
    graphs: types.map(BricksCollection)
})
    .volatile(_ => ({ loading: false }))
    .views(self => ({
        get params(): IParams {
            return (getRoot(self) as Instance<typeof LateStoreModel>).params;
        }
    }));

const __Tab_FS = __Tab.props({ type: TabTypes.FULLSCREEN })
    .views(self => ({
        get tabId() {
            return (getParent(self, 2) as Instance<typeof LateDashboard3>).id;
        }
    }))
    .views(_ => ({
        get lpColumnPos(): number[] {
            return [0];
        }
    })).views(self => ({
        get hasData() {
            if (!!self.params.tower && self.graphs.has(self.params.unitType)) {
                const g = self.graphs.get(self.params.unitType)!;
                return !!g.columns.length && !!g.rows.length && !!g.bricks.length;
            }
            return false;
        },
        get graph() {
            return self.graphs.get(self.params.unitType) || null;
        }
    })).views(self => ({
        get columns() { return self.graph?.columnSortedFiltered || []; },
        get rows() { return self.graph?.rowSorted || []; },
    })).views(self => ({
        get totalColumns() {
            return self.columns.length + 2;
        },
        get totalRows() {
            return self.rows.length;
        }
    })).views(self => ({
        getBrick(xVal: number, yVal: number) {
            return self.graph?.getBrick(xVal, yVal) || null;
        }
    })).actions(self => ({
        getTabData: flow(function* getTabData() {
            const root = getRoot(self) as Instance<typeof LateStoreModel>;
            try {
                if (!root.towers.loading && root.towers.length && !self.loading && !self.graphs.size) {
                    const regex = new RegExp(`_${self.phase}_${self.spaceType}$`);
                    const { units } = yield root.fetch(GET_DASHBOARDS.finishingTcaFullscreen, { variables: { block: self.id, tab: self.tabId.replace(regex, ""), phase: self.phase, spaceType: self.spaceType } });
                    if (!units.length) {
                        const metadata = { customer: root.auth.customerId, user: root.auth.userId, path: root.params.path, section: root.params.section, phase: root.params.phase, spaceType: root.params.spaceType };
                        getEnv(self).mixpanel.track("NoData", { ...metadata });
                        Sentry.withScope(captureEventCallback(metadata));
                    }
                    if (units.length) {
                        let i = 0, j = 0, len = units.length, rowIds: number[] = [], colIds: string[] = [], rows: any[] = [], columns: any[] = [], currentGroup = "", currentGroupName = "";
                        const graph = {
                            type: self.graphType,
                            brickType: BrickTypes.F,
                            columnType: CellAddressType.ACT_STRING,
                            rowType: self.graphType === GOT.STARK ? CellAddressType.FLOOR_NUM : CellAddressType.NULL
                        };
                        for (i; i < len; i++) {
                            // Set a unit type graph based on existing data,
                            // then reset.
                            if (currentGroup !== units[i].group) {
                                if (i) {
                                    if (self.graphType === GOT.STARK) {
                                        self.graphs.set(currentGroup, {
                                            ...graph,
                                            id: currentGroup,
                                            name: currentGroupName,
                                            rows: rows.map(val => snToCellAddressNumId(CellAddressType.FLOOR_NUM, val)),
                                            columns: columns.map(val => snToCellAddressActivities(val)),
                                            bricks: units.slice(j, i + 1).map(val => snToFinishingTcaFSBrick(val))
                                        });
                                    }
                                    else if (self.graphType === GOT.BARATHEON) {
                                        self.graphs.set(currentGroup, {
                                            ...graph,
                                            id: currentGroup,
                                            name: currentGroupName,
                                            rows: [snToCellAddressNull()],
                                            columns: columns.map(val => snToCellAddressActivities(val)),
                                            bricks: units.slice(j, i + 1).map(val => snToFinishingTcaFSBrick(val))
                                        });
                                    }
                                }
                                currentGroup = units[i].group;
                                currentGroupName = units[i].groupName;
                                j = i; rowIds = []; colIds = []; rows = []; columns = [];
                            }
                            // Add to arrays.
                            const { xVal, yVal, activity, precedence, floor }: { xVal: string; yVal: number; activity: string; precedence: number; floor: string; } = units[i];
                            if (!rowIds.includes(yVal)) {
                                rowIds.push(yVal);
                                rows.push({ id: yVal, name: floor });
                            }
                            if (!colIds.includes(xVal)) {
                                colIds.push(xVal);
                                columns.push({ 'id': xVal, 'name': activity, 'activity': {}, precedence });
                            }
                        }
                        // Final set
                        if (self.graphType === GOT.STARK) {
                            self.graphs.set(currentGroup, {
                                ...graph,
                                id: currentGroup,
                                name: currentGroupName,
                                rows: rows.map(val => snToCellAddressNumId(CellAddressType.FLOOR_NUM, val)),
                                columns: columns.map(val => snToCellAddressActivities(val)),
                                bricks: units.slice(j).map(val => snToFinishingTcaFSBrick(val))
                            });
                        }
                        else if (self.graphType === GOT.BARATHEON) {
                            self.graphs.set(currentGroup, {
                                ...graph,
                                id: currentGroup,
                                name: currentGroupName,
                                rows: [snToCellAddressNull()],
                                columns: columns.map(val => snToCellAddressActivities(val)),
                                bricks: units.slice(j).map(val => snToFinishingTcaFSBaratheonBrick(val))
                            });
                        }
                    }
                }
            }
            catch (err) { throw err; }
        })
    }));


const __Tab_LP = __Tab.props({ type: TabTypes.LANDING }).views(self => ({
    get floorSort(): boolean {
        return (getRoot(self) as Instance<typeof LateStoreModel>).filters.floorSort;
    }
}))
    .views(self => ({
        get lpColumnPos() {
            if (!self.graphs.size) { return [0]; }
            return (getRoot(self) as Instance<typeof LateStoreModel>).towersList.reduce((acc, t, i) => [...acc, acc[i] + (self.graphs.get(t.id)?.columnSortedFiltered.length || - 2) + 2], [0]).slice(0, -1);
        },
        get lpRows() {
            if (!self.graphs.size) { return []; }
            const rows = Array.from(self.graphs.values()).map(graph => graph.rowSorted)
            let ret = rows[0];
            let i = 1;
            let indices = ret.map(({ id }) => id);
            const len = rows.length;
            for (; i < len; i++) {
                rows[i].forEach(row => { if (!indices.includes(row.id)) { ret.push(row); indices.push(row.id); } });
            }
            // check for asc or desc
            const sort = getThrones(self.graphType) === GOT.STARK ? self.floorSort ? sortingStrategyRev : sortingStrategy : sortingStrategyRev;
            return ret.sort(sort);
        }
    })).views(self => ({
        get hasData() {
            return !!self.lpColumnPos.length && !!self.lpRows.length;
        },
        get columns() {
            return (getRoot(self) as Instance<typeof LateStoreModel>).towersList.reduce((acc, t) => [...acc, null, ...(self.graphs.get(t.id)?.columnSortedFiltered || []), null], []).slice(1, -1);
        },
        get rows() {
            return self.lpRows;
        }
    })).views(self => ({
        get totalColumns() {
            const arr = (getRoot(self) as Instance<typeof LateStoreModel>).towersList.reduce((acc, t) => self.graphs.has(t.id) ? [...acc, self.graphs.get(t.id)] : acc, []);
            return arr.reduce((acc, t) => (acc + t.columns.length), 0) + arr.length * 2;
        },
        get totalRows() {
            return self.rows.length;
        }
    })).views(self => ({
        // get progressDetails() {
        //     if (self.params.screen === 'landing' && !!self.params.tower) { return self.progressDetails.get(self.params.tower) || null; }
        // },
        // getProgressDetails(id: string) {
        //     return self.progressDetails.get(id) || null;
        // },
        getBrick(xVal: number, yVal: number) {
            if (self.params.screen === 'landing' && self.hasData) {
                const unsafeIndex = self.lpColumnPos.findIndex((strt: number) => (xVal < strt));
                const [tower, index] = unsafeIndex > -1 ? [(getRoot(self) as Instance<typeof LateStoreModel>).towersList[unsafeIndex - 1], unsafeIndex - 1] : [(getRoot(self) as Instance<typeof LateStoreModel>).towersList[(getRoot(self) as Instance<typeof LateStoreModel>).towersList.length - 1], (getRoot(self) as Instance<typeof LateStoreModel>).towersList.length - 1];
                if (!tower) { return null }
                return self.graphs.get(tower.id)?.getBrick(xVal - self.lpColumnPos[index], yVal) || null;
            }
            return null;
        }
    })).actions(self => ({
        getTabData: flow(function* getTabData() {
            const root = getRoot(self) as Instance<typeof LateStoreModel>;
            try {
                if (!root.towers.loading && root.towers.length && !self.loading && !self.graphs.size && !self.towers.length) {
                    const { dashboard } = yield root.fetch(GET_DASHBOARDS.finishingTcaLanding, { variables: { blocks: root.towers.map(({ id }) => id), tab: self.id, phase: self.phase, spaceType: self.spaceType } });
                    if (!dashboard.length) {
                        const metadata = { customer: root.auth.customerId, user: root.auth.userId, path: root.params.path, section: root.params.section, phase: root.params.phase, spaceType: root.params.spaceType };
                        getEnv(self).mixpanel.track("NoData", { ...metadata });
                        Sentry.withScope(captureEventCallback(metadata));
                    }
                    if (dashboard.length) {
                        let finalTowers: { id: string; name: string }[] = [], graphs: any[] = [], rws: any[] = [], rowIds: string[] = [];
                        self.graphs.clear();
                        dashboard.forEach(({ id, name, rows, columns, units }) => {
                            if (units.length) {
                                self.towers.push(id);
                                finalTowers.push({ id, name });
                                // if (progressDetails) {
                                //     self.progressDetails.set(id, { id, ...progressDetails });
                                // }
                                if (self.graphType === GOT.BARATHEON) {
                                    graphs.push([id, {
                                        id,
                                        name,
                                        type: self.graphType,
                                        brickType: BrickTypes.F,
                                        columnType: CellAddressType.NULL,
                                        rowType: CellAddressType.UNIT_TYPE_STRING,
                                        columns: [snToCellAddressNull()],
                                        bricks: units.map(val => snToFinishingTcaLPBaratheonBrick(val))
                                    }]);
                                    columns.forEach((val) => {
                                        const elt = snToCellAddressStringId(CellAddressType.UNIT_TYPE_STRING, val);
                                        if (!rowIds.includes(elt.id)) { rowIds.push(elt.id); rws.push({ ...elt }); }
                                    });
                                }
                                else if (self.graphType === GOT.STARK) {
                                    graphs.push([id, {
                                        id,
                                        name,
                                        type: self.graphType,
                                        brickType: BrickTypes.F,
                                        columnType: CellAddressType.UNIT_TYPE_STRING,
                                        rowType: CellAddressType.FLOOR_NUM,
                                        rows: rows.map(val => snToCellAddressNumId(CellAddressType.FLOOR_NUM, val)),
                                        columns: columns.map(val => snToCellAddressStringId(CellAddressType.UNIT_TYPE_STRING, { ...val, tower: id })),
                                        bricks: units.map(val => snToFinishingTcaLPBrick(val))
                                    }]);
                                }
                            }
                        });
                        if (self.graphType === GOT.BARATHEON) { self.graphs.merge(graphs.map(([id, { ...rest }]) => [id, { ...rest, rows: rws }])); }
                        else if (self.graphType === GOT.STARK) { self.graphs.merge(graphs); }
                        root.dashboards.get(self.id)?.fillFullscreenSelf([...finalTowers], self.graphType);
                    }
                }
            }
            catch (err) { throw err; }
        })
    }));

const Tab = types.union({ dispatcher: __dispatcher1 }, __Tab_LP, __Tab_FS);

export const Dashboard3 = types.model("WithTabsLanding", {
    // id: types.refinement(types.identifier, __id_checker),
    id: types.identifier,
    phase: ePhase,
    spaceType: types.string,
    type: DashboardTypes.TABS,
    tabs: types.map(Tab)
}).volatile(_ => ({ loading: false }))
    .views(self => ({
        get params() {
            return (getRoot(self) as Instance<typeof LateStoreModel>).params;
        }
    }))
    .views(self => ({
        get tab() {
            if (self.params.screen === 'landing') { return self.tabs.get(self.params.tab) || null; }
            if (self.params.screen === 'fullscreen') { return self.tabs.get(self.params.tower) || null; }
            return null;
        },
        get graphs() {
            if (self.params.screen === 'landing') { return self.tabs.get(self.params.tab)?.graphs || null; }
            if (self.params.screen === 'fullscreen') { return self.tabs.get(self.params.tower)?.graphs || null; }
            return null;
        }
    }))
    .views(self => ({
        get hasData() {
            return !!self.tab?.hasData;
        },
        get towers() {
            if (self.params.screen === 'landing') { return self.tab?.towers || []; }
            if (self.params.screen === 'fullscreen') { return Array.from(self.tabs.values()).map(({ id, name }) => ({ id, name })); }
            // if (self.params.screen === 'fullscreen') { return self.tabs.values() || []}
            return [];
        },
        get lpColumnPos() {
            return self.tab?.lpColumnPos;
        },
        get tabsList() {
            if (self.params.screen === 'landing') { return Array.from(self.tabs.values()).map(({ id, name, graphType, towers }) => ({ id, name, graphType, stats: Array.from(towers) })); }
            if (self.params.screen === 'fullscreen') { return Array.from(self.graphs?.values() || []).map(({ id, name, graphType, columns }) => ({ id, name, graphType, stats: Array.from(columns) })); }
            return [];
        }
    })).views(self => ({
        get columns() { return self.tab?.columns || []; },
        get rows() { return self.tab?.rows || []; },
    })).views(self => ({
        get totalColumns() {
            return self.tab?.totalColumns || 0;
        },
        get totalRows() {
            return self.tab?.totalRows || 0;
        },
        get graph() {
            if (self.params.screen === 'landing') { return null; }
            if (self.params.screen === 'fullscreen' && !!self.params.unitType) {
                return self.graphs?.get(self.params.unitType) || null;
            }
            return null;
        }
    })).views(self => ({
        getBrick(xVal: number, yVal: number) {
            return self.tab?.getBrick(xVal, yVal) || null;
        }
    })).actions(self => ({
        getTab(tab: string) {
            self.tabs.get(tab)?.getTabData();
        },
        fillFullscreenSelf(towers: { id: string; name: string }[], graphType: GOT) {
            towers.forEach(({ id, name }) => {
                self.tabs.set(id, { id, name, graphType, type: TabTypes.FULLSCREEN, phase: self.phase, spaceType: self.spaceType });
            });
        }
    })).actions(self => ({
        setTabs(tabs: { id: string; name: string; type: string; phase: string; spaceType: string; }[], tabType: TabTypes) {
            tabs.forEach(({ id, name, type, phase, spaceType }) => {
                self.tabs.set(id, {
                    id,
                    name,
                    phase,
                    spaceType,
                    type: tabType,
                    graphType: getThrones(type)
                });
            });
        }
    })).actions(self => ({
        getDashboards: flow(function* getDashboards() {
            const root = getRoot(self) as Instance<typeof LateStoreModel>;
            if (!root.towers.loading && root.towers.length && !self.loading) {
                if (!self.tabs.size) {
                    // if (self.params.screen === 'landing') {
                    //     const { tabs } = yield root.fetch(GET_TABS, {
                    //         variables: { blocks: root.towers.map(({ id }) => id) }
                    //     });
                    //     if (tabs.length) {
                    //         // self.tabs.clear();
                    //         self.setTabs(tabs, DashboardPages.FINISHING_TCA_LANDING);
                    //         tabs.forEach(({ id }) => { root.addDashboard(DashboardTypes.TABS, id); });
                    //         localStorage.setItem('tabs', JSON.stringify(tabs));
                    //     }
                    // }
                    if (self.params.screen === 'fullscreen') {
                        const regex = new RegExp(`_${self.phase}_${self.spaceType}$`);
                        const { blocks } = yield root.fetch(GET_TOWERS.finishingTcaFullscreen, {
                            variables: {
                                tab: self.id.replace(regex, ""),
                                blocks: root.towers.map(({ id }) => id),
                                phase: self.phase,
                                spaceType: self.spaceType
                            }
                        });

                        if (!blocks.length) {
                            const metadata = { customer: root.auth.customerId, user: root.auth.userId, path: root.params.path, section: root.params.section, phase: root.params.phase, spaceType: root.params.spaceType };
                            getEnv(self).mixpanel.track("NoData", { ...metadata });
                            Sentry.withScope(captureEventCallback(metadata));
                        }


                        if (blocks.length) {
                            // type: root.dashboards.get(DashboardPages.FINISHING_TCA_LANDING)?.tabs.get(self.id)?.graphType
                            self.setTabs(blocks.map(({ id, name, details }) => ({
                                id,
                                name,
                                phase: self.phase,
                                spaceType: self.spaceType,
                                type: details[0]?.type || GOT.STARK
                            })), TabTypes.FULLSCREEN);
                        }
                    }
                }
                if (self.tab && !self.tab?.hasData) {
                    self.getTab(self.tab!.id);
                }
            }
        })
    }))
    ;


export const Dashboard = types.union({ dispatcher: __dispatcher }, Dashboard1, Dashboard2, Dashboard3);

/*
  Entry Point of the Root Store to our Dashboards
  ,----
  | This will house all the actions, and expose th4e underlying Brick
  | Collection views, as well as any Summary props/views. This is responsible for
  | resolving parameters to specific dashboards, and houses logic to load and set
  | data regarding the dashboards.
  `----
*/

// export const Dashboards = types.model("Dashboards",
//     {
//         dashboards: types.map(Dashboard)
//     }).views(self => ({
//         get params(): IParams {
//             return (getRoot(self) as Instance<typeof LateStoreModel>).params;
//         }
//     })).views(self => ({
//         get currentDashboard(): IStateTreeNode<typeof Dashboard> | null {
//             const hasDash = self.dashboards.has(getDashboard(self.params));
//             if (!hasDash) { return null; }
//             else { return self.dashboards.get(getDashboard(self.params))!; }
//         }
//     }))
//.actions();



export interface IDashboard1 extends Instance<typeof Dashboard1> { };
export interface IDashboard2 extends Instance<typeof Dashboard2> { };
export interface IDashboard3 extends Instance<typeof Dashboard3> { };
const LateDashboard3 = types.late(() => Dashboard3);
// export interface IDashboard3 extends Instance<typeof Dashboard3> { };
// export interface IDashboards extends Instance<typeof Dashboards> { };
