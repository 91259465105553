import React from 'react';
import { ErrorResponseObject } from '../../../models/admin/definitions/interface';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';

interface ErrorProps {
  // response: ErrorResponseObject | undefined;
  response: any;
  open: boolean;
  handleClose: () => void;
}

const Error: React.FC<ErrorProps> = ({ response, open, handleClose }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="response-dialog-title"
      aria-describedby="response-dialog-description"
    >
      <DialogTitle id="response-dialog-title">{"Error"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="response-dialog-description">
          {response?.message || response?.errorMsg || response?.error_message || JSON.stringify(response)}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default Error;
