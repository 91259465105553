import React from 'react'
import { useStore } from '../../../models/ProvideModel';
import { observer } from 'mobx-react-lite';
import useStyles from './DocsViewStyles';

const DocsView = ({ url }: { url: string }) => {
  const store = useStore();
  const classes = useStyles()
  return (
    <div style={{ display: 'flex', flexDirection:'column', justifyContent: 'center', alignItems: 'center', width: '100%', marginTop: '2em' }}>
        <span>Documentations are opened in a new browser tab.</span>
        <a className={classes.button} href={url} target='_blank'>Click to open <strong>{store.adminModule.currentRequest?.title}</strong></a>
    </div>
  )
}

export default observer(DocsView)