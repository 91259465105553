import React from "react";
import {
  MRT_Column,
  MRT_Row,
} from 'mantine-react-table';
import Tag from "./Tag";


/* sx={{
 *   backgroundColor: stringToColour(tag) || "#7D7D7D",
 *   borderColor: stringToColour(tag) || "#7D7D7D",
 *   color: "#BBB",
 *   margin: ".3em"
 * }} */

const Tags = ({ row: { original }, column: { getFilterValue, setFilterValue } }: { row: MRT_Row<any>; column: MRT_Column<any>; }) => {
  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center"
      }}>
      {
        original['tags'].map((tag: string) => (
          <Tag
            key={tag}
            path={original["path"]}
            tag={tag}
            actionable={original["canEdit"]}
            shouldHighlight={true}
            isSelected={((getFilterValue() || []) as string[]).includes(tag)}
            handleClick={(flag: boolean) => {
              setFilterValue(val =>
                flag ?
                  [...(val || []), tag]
                  :
                  (val || []).filter(t => t !== tag));
            }}
          />
        ))
      }
    </div >
  );
}

export default Tags;
