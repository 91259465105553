import React from 'react';
import { Button, Group, Select, Stack, Text, Loader, Grid, ScrollArea, Tooltip, ActionIcon } from '@mantine/core';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../models/ProvideModel';
import { IconDownload, IconX, IconUpload, IconPhoto, IconAlertHexagonFilled, IconReload, IconAlertTriangleFilled } from '@tabler/icons-react';
import { IBulkImport, IBulkImportRequest, ITicketType } from '../../models/Ticket';
/* import { makeStyles } from '@material-ui/core/styles'; */
import { Dropzone } from '@mantine/dropzone';
import { notifications } from '@mantine/notifications';
import { BulkRequestStatuses } from '../../models/enums';


/* const useStyles = makeStyles((theme: Theme) => ({
 *   iconStyles: { width: '1.3em', height: '1.3em', paddingRight: '0.35em', color: '#005eff', },
 *   iconDivStyles: { display: "flex", justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer', '&:hover': { color: '#005eff' } },
 *   dropozone: (props: { disabled: boolean }) => ({ cursor: props.disabled ? "default" : "pointer", border: props.disabled ? "0.1em solid #ececec" : "0.1em dashed #d9d9d9", '&:hover': { background: props.disabled ? '#fff' : '#f8f9fa' } })
 * })); */

const MAX_SIZE = 25 * 1024 ** 2;

const DropOrUpload = observer(({ model, label }: { model: IBulkImport; label: string; }) => {
  return (
    <Stack w="100%" spacing="xs">
      <Group position="apart" align="center" >
        <Text
          fw={600}
          // my="0.5em"
          mb={'0.5em'}
          fz="0.8525em"
          c="#585858"
          opacity="0.8"
        >{label}
          <span style={{ color: "red", marginLeft: "0.25em" }}>*</span>
        </Text>
      </Group>
      <Dropzone
        onDrop={(files) => {
          console.log('accepted files', files, 'size', files[0].size, 'MAX_SIZE', MAX_SIZE);
          if (files[0].size < MAX_SIZE) model.makeBulkImportRequest(files[0])
        }}
        onReject={(files) => {
          console.log('rejected files', files);
          notifications.show({ title: 'Failed!', message: `Maximum file upload size is 25 MB.`, color: 'red', icon: <IconX /> })
        }}
        name={label}
        activateOnClick
        w="100%"
        styles={{
          inner: { pointerEvents: "all", cursor: model.disabled ? "not-allowed" : "pointer" },
          root: model.disabled ? {
            backgroundColor: "#eaeaea",
            borderColor: "#585858",
            cursor: 'not-allowed',
            border: '0.1em dashed #ececec',
            '&:hover': {
              backgroundColor: "#eaeaea",
              color: "#888888",
              cursor: 'not-allowed',
            },
          } : {
            border: '0.1em dashed #005eff',
          }
        }}
        maxSize={MAX_SIZE}
        disabled={model.disabled}
      >
        {model.loading ?
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Loader size={'xs'} />
          </div> : (
            <Group position="center" spacing="xl" style={{ pointerEvents: 'none', alignSelf: 'baseline', }}>
              <Dropzone.Accept>
                <IconUpload
                  size="1.2em"
                  stroke={1.5}
                />
              </Dropzone.Accept>
              <Dropzone.Reject>
                <IconX
                  size="1.2em"
                  stroke={1.5}
                />
              </Dropzone.Reject>
              <Dropzone.Idle>
                <IconPhoto size="1.2em" stroke={1.5} />
              </Dropzone.Idle>
              <div>
                <Text size="xs" inline>
                  {model.disabled ? "Select a Request Type first." : "Drag images here or click to select files"}
                </Text>
              </div>
            </Group>)
        }
      </Dropzone>
    </Stack >
  );
});

const BulkImportRequestHistoryRow = observer(({ request }: { request: IBulkImportRequest }) => (
  <Grid justify="space-around" mx={0} >
    <Grid.Col sx={{ borderLeft: "0.0525em solid #585858", padding: 10 }} span={2}><Tooltip label={request.requestId}><Text truncate>{request.requestId}</Text></Tooltip></Grid.Col>
    <Grid.Col sx={{ borderLeft: "0.0525em solid #585858", padding: 10 }} span={4} > {request.ticketType}</Grid.Col >
    <Grid.Col sx={{ borderLeft: "0.0525em solid #585858", padding: 10 }} span={4} > {request.statusText}</Grid.Col >
    <Grid.Col sx={{ borderLeft: '0.0525em solid #585858', borderRight: "0.0525em solid #585858", justifyItems: "center" }} span={2} >
      <Button display="flex" color={request.disabled ? "red" : request.status === BulkRequestStatuses.PENDING ? "yellow" : "green"} disabled={request.disabled} styles={{ root: { "&:disabled": { background: "red" }, borderRadius: 0 } }} onClick={() => { request.download(); }} loading={request.loading}>
        <Tooltip label={request.disabled ? "Failed" : "Check and Download"}>
          {
            request.disabled ?
              <IconAlertHexagonFilled size="1em" />
              :
              request.status === BulkRequestStatuses.PENDING ?
                <IconReload size="1em" />
                :
                <IconDownload size="1em" />
          }
        </Tooltip>
      </Button>
    </Grid.Col >
  </Grid >
));

const TicketExcelImport = ({ importModel }: { importModel: IBulkImport }) => {
  return (
    <Stack align="center" mx="1rem" w="100%" spacing="lg" p=".5rem">
      <Text fz="xl" fw={800} mb={'1em'}>Bulk Upload</Text>
      <Grid w="100%" mx="2rem" align="flex-start">
        <Grid.Col span={6}>
          <Group style={{ display: 'flex', alignItems: 'center'}}>
            <Select
              label="Request Type"
              data={importModel.startableTicketTypes.map((tT: ITicketType) => ({ value: tT.typeId, label: tT.title }))}
              value={importModel.selectedTicketType?.typeId}
              onChange={(val) => { if (val) { importModel.selectTicketType(val); } }}
              placeholder={'Choose a type'}
              
              sx={{
                flexGrow: 9,
                // width: 120,
                ["& .mantine-Select-label"]: { fontSize: '0.8525em', color: '#585858', fontWeight: 600 },
                ["& .mantine-Select-input"]: { marginTop: '0.5em', marginBottom: '0.5em', borderBottom: '0.0525em solid #005eff', borderTop: 0, borderRight: 0, borderLeft: 0, borderRadius: 0, paddingRight: '1.8575rem', '&[data-disabled]': { backgroundColor: '#FFF', border: 0, opacity: 1, color: '#585858', cursor: 'not-allowed' }, '&[data-with-icon]': { paddingLeft: '1.8575rem' } },
                // ["& .mantine-Select-icon"]: { justifyContent: coreField ? 'flex-start' : 'center' }
              }}
            />
            <Tooltip label="Download Template File">
              <Button sx={{ alignSelf: "end", flexGrow: 1, borderRadius: 0, marginRight: '2.5em' }} disabled={importModel.disabled} onClick={() => { importModel.downloadTemplate(); }}>
                <span style={{marginRight: '0.5em'}}>Download</span>
                <IconDownload size="1.5em" />
              </Button>
            </Tooltip>
          </Group>
        </Grid.Col>
        <Grid.Col span={6} >
          <Group>
            <DropOrUpload
              label="Upload Excel Here"
              model={importModel}
            />
          </Group>
        </Grid.Col>
      </Grid>
      <Text fz="lg" fw={800} my={'1em'}>Request Status (Last 10)</Text>
      <Grid sx={{  border: '0.05em solid #585858' }} justify="space-around" mx="2rem" w="100%">
        <Grid.Col span={2}><Text fw={600}>Req. Id</Text></Grid.Col>
        <Grid.Col span={4}><Text fw={600}>Request Type</Text></Grid.Col>
        <Grid.Col span={4}><Text fw={600}>Status</Text></Grid.Col>
        <Grid.Col span={2}> <Text fw={600} align="center">Check & Download</Text> </Grid.Col>
      </Grid>
      {importModel.history.length ?
        <ScrollArea h={400} type="auto" w="100%" >
          {importModel.history.map((request: IBulkImportRequest) => (
            <BulkImportRequestHistoryRow key={request.requestId} request={request} />
          ))}
        </ScrollArea>
        :
        <Group position="center" align="center" w="100%" p="1rem" bg="#EAEAEA" mih={100}>
          <ActionIcon color="yellow" sx={{ cursor: 'auto' }} >
            <IconAlertTriangleFilled size="2rem" />
          </ActionIcon>
          <Text fw={600} fz=".8em" opacity={.8}>No Bulk Requests made yet.</Text>
        </Group>
      }
    </Stack>
  );

};

export default observer(TicketExcelImport);

