import React, { useReducer, MemoExoticComponent, FunctionComponent, useMemo, useEffect, useState, useCallback } from 'react';
import { IGenericInputProps } from '../../../../components/FormInputs/GenericInput';
import { IDateTimeInputProps } from '../../../../components/FormInputs/DateTimeInput';
import { IFormDropdownInputProps } from '../../../../components/filter/FormDropdown';
import { TicketStatusCategories, capitalizeNames } from '../../../../models/enums';
import { formEditReducer, } from '../../../../utils/utils';
import { IImagePickerProps } from '../../../../components/FormInputs/ImagePicker';
import useStyles from './TasksStyles';
import { useFormDialogModal, ModalEffects, ModalActions, modalsActionsConfig, ModalState } from '../../../../utils/hooks';
import { submitForm, saveFormByUserAndTask, submitComment, } from '../../../../api/transactionServer';
import GenericModal from '../../../GenericModal';
import { DialogContentText, Button, CircularProgress, } from '@material-ui/core';
import { TextInput, CloseButton, Group, Tooltip, Avatar, Textarea, Text, HoverCard, Stack, Portal } from '@mantine/core';
import { IconEye, IconPencil, } from '@tabler/icons-react';
import { IChan, putAsync } from 'csp-with-ts';
import AssigneeList from '../../../../components/assignees/AssigneeList';
import { IFormField, ITask, ITicket, IUser, } from '../../../../models/Ticket';
import { observer } from 'mobx-react-lite';
import NotCurrentAssignee from '../../others/notCurrentAssignee/NotCurrentAssignee';
import { useClipboard } from '@mantine/hooks';
import Copy from '../../../../components/CopyButton';
import { reverseTransform, transformCopy, transformMap } from '../../../../utils/constants';
import { notifications } from '@mantine/notifications';
import { IMultiSelectDropdownInputProps } from '../../../../components/FormInputs/MultiSelectDropdownInput';
import { useStore } from '../../../../models/ProvideModel';

// Remove hardcode sometime
const __module = "requests";

function sanitizeState(fields: IFormField[], state: { [K: string]: any; }) {
  return fields.reduce((acc, { id, type }) => !state[id] || (state[id] && Array.isArray(state[id]) && !state[id].length) ? acc : ({ ...acc, [id]: transformMap(type)['transform'](state[id]) }), {});
}

// TODO: move task change mechanics to mobx-state-tree
export interface ITicketTasks {
  customerId: string;
  userId: string;
  ticket: ITicket;
  projectId: string;
  refreshTicket: (id: string) => Promise<any>;
  lg?: boolean;
  onClose?: () => void;
  taskIdValue?: string;
  coreFields: { [K: string]: any; };
  ch: IChan;
  activeTab: string | null;
};

const initializer = (fields: IFormField[]) => ({ isChanged: false, ...fields.reduce((acc, { id, value, placeholder, type, options }) => ({ ...acc, [id]: (type === "multi-line-text" || type === "text") && (!value || !value.length) && placeholder ? placeholder : reverseTransform[type] ? reverseTransform[type](value || "") : value || "" }), {}) });

interface INonCurrentUser {
  assignees: IUser[],
  formFields: { taskId: string; taskName: string; formKey: string; assignee?: string; fields: IFormField[]; }[] | undefined,
  classes: any,
  userId: string
}

const TicketTasks = ({ activeTab, userId, customerId, ticket, lg, onClose, projectId, coreFields, ch, refreshTicket, taskIdValue }: ITicketTasks) => {
  const [open, setOpen] = useState(false);
  const { copy, copied } = useClipboard({ timeout: 500 });
  useEffect(() => {
    ticket.getTaskDetails();
  }, []);

  useEffect(() => {
    if (activeTab == "tasks") {
      let rightUserExists = false;
      ticket.tasks.map(({ taskId, assignee }) => {
        if (assignee.includes(userId)) {
          rightUserExists = true
          ticket.setCurrentTask(taskId)
        }
      })
      if (!rightUserExists && ticket.tasks.length) {
        ticket.setCurrentTask(ticket.tasks[0].taskId)
      }
    }
  }, [activeTab])

  const closeAssigneeListModal = () => setOpen(false);

  const handleAssigneeUser = (userId: string) => {
    putAsync(ch, { field: 'assignee', value: userId })
    setOpen(false);
  };

  const outcomes = ticket.outcomes && ticket.currentTask?.taskId ? ticket.outcomes[ticket.currentTask?.taskId]?.filter(outcome => !!ticket.processVariables.outcomeMeta ? !ticket.processVariables.outcomeMeta[outcome.name]?.hidden : true) || [] : [];
  const [state, dispatch] = useReducer(formEditReducer, ticket.formFields, initializer);
  const disabled = (!!ticket.tasksLoading || (ticket.statusCategory === TicketStatusCategories.CLOSED)) ? true : (ticket.currentTask?.isMultiUser && !ticket.currentTask?.isUnclaimable) ? true : !!ticket.formFields?.some(({ id, required, readOnly, type }) => (state[id] === null || state[id] === undefined) ? true : (!!required && !readOnly ? Array.isArray(state[id]) ? !state[id]?.length : transformMap(type)['transform'] ? typeof transformMap(type)['transform'](state[id]) === "string" ? !transformMap(type)['transform'](state[id])?.length : !transformMap(type)['transform'](state[id]) : !state[id]?.length : ticket.currentTask?.assignee[0] != userId ? true : false));

  const [isInputVisible, setInputVisible] = useState(false);
  const [outcomeActionName, setOutcomeActionName] = useState("");
  const [mandatoryComment, setMandatoryComment] = useState("");
  useEffect(() => { dispatch({ type: 'RESET', payload: initializer(ticket.formFields || []) }); }, [ticket.formFields]);
  const [pendingSave, setPendingSave] = useState('');
  const store = useStore()

  /**Function to check if any file actions were carried via task form */
  const handleFileChange = useCallback((field: string) => {
    setPendingSave(field); // Set the flag to trigger save after state update
  }, []);

  useEffect(() => {
    if (pendingSave) {
      onSave(pendingSave); // Call the onSave API function
      setPendingSave(''); // Reset the flag
    }
  }, [state, pendingSave]);


  useEffect(() => {
    setInputVisible(false);
  }, [taskIdValue])

  // Modal shenanigans
  const onSubmit = (outcome?: string) => {
    /* const payload = Object.keys(state).reduce((acc, id) => id === "isChanged" ? acc : !(state[id] && state[id].length) ? acc : { ...acc, [id]: state[id] }, {}); */
    const payload = sanitizeState(ticket.formFields, state);

    if (mandatoryComment.length) {
      const template = `<span><strong>Task:</strong> ${ticket.currentTask?.taskName}</span><br/><span>Notes added on ${outcomeActionName.toUpperCase()} action:</span><hr />`
      return submitComment({ processInstanceId: ticket.id, userId, commentType: "test", commentMessage: template + mandatoryComment, customerId, projectId, moduleId: __module })
        .then(() => {
          setMandatoryComment("");
          setInputVisible(false);
          ticket.refreshComments();
        })
        /* .then(() => { onSubmitHook(); }) */
        .then(() => (submitForm({ userId, taskId: ticket.currentTask?.taskId || "", customer: customerId, ticketId: ticket.id, moduleId: __module, outcome, payload, /* property: rest.property */ }).then(() => {
          refreshTicket(ticket.id);
          notifications.show({
            title: `Submit success!`,
            message: outcome ? `You successfully submitted with outcome ${capitalizeNames(outcome)}!` : `Your changes were submitted successfully!`,
            color: 'teal'
          });
          if (onClose) onClose()
        })))
        .catch(error => {
          console.error(error);
          notifications.show({
            title: 'An Error Occurred while attempting to Submit changes.',
            message: error.response?.data?.errorMessage,
            color: 'red'
          });
        })
    } else {
      /* onSubmitHook(); */
      return submitForm({ userId, taskId: ticket.currentTask?.taskId || "", customer: customerId, ticketId: ticket.id, moduleId: __module, outcome, payload, /* property: rest.property */ }).then(() => {
        refreshTicket(ticket.id);
        notifications.show({
          title: `Submit success!`,
          message: outcome ? `You successfully submitted with outcome ${capitalizeNames(outcome)}!` : `Your changes were submitted successfully!`,
          color: 'teal'
        });
        if (onClose) onClose()
      })
        .catch(error => {
          console.error(error);
          notifications.show({
            title: 'An Error Occurred while attempting to Submit changes.',
            message: error.response?.data?.errorMessage || error.message,
            color: 'red'
          });
        })
    }

  };
  const onSave = (field?: string) => {
    const payload = sanitizeState(ticket.formFields, state);

    /**Prepare payload only for file type fields */
    const statePayload = Object.entries(state).reduce((acc, [key, value]: [string, any]) => {
      if (key !== 'isChanged') { //Do not consider "isChanged" key
        const formField = ticket.formFields.find(field => field.id === key);
        if (formField && formField.type === 'upload' && formField.id === field) { //consider only "upload" type fields - Note: if there are more than one "upload" type field, then all of them will be saved in the save task.
          acc[key] = value;
        }
      }
      return acc;
    }, {} as Record<string, any>);

    return saveFormByUserAndTask({ userId, taskId: ticket.currentTask?.taskId || "", customer: customerId, projectId, payload: pendingSave ? statePayload : payload, moduleId: __module }) //check if the pendingSave flag was set, if true then send prepared payload else send sanitized payload
      .then(() => {
        ticket.currentTask?.patchFields({ ...payload });
        /* refreshTickets(); */
        //  dispatch({ type: 'SET_CHANGE_STATE', payload: false });
        notifications.show({
          title: 'Save success!',
          message: 'Your changes were saved successfully!',
        })
      }).catch(error => {
        console.error(error);
        notifications.show({
          title: 'An Error Occurred while attempting to Save changes!',
          message: error.response?.data?.errorMessage || error.message,
          color: 'red'
        })
      });
  };
  const effects = useMemo(() =>
    outcomes && outcomes.length ?
      {
        [ModalEffects.SAVE]: onSave,
        [ModalEffects.OUTCOME]: onSubmit
      } :
      {
        [ModalEffects.SAVE]: onSave,
        [ModalEffects.SUBMIT]: onSubmit
      }
    , [outcomes, taskIdValue, state, mandatoryComment, onSubmit]);
  const [modalState, modalDispatch, mapping] = useFormDialogModal(effects, onClose);
  // End of modal shenanigans
  const classes = useStyles({ isLg: !!lg });
  const [lastCopied, setLastCopied] = useState<string>("");

  return (
    <div className={classes.mainDiv} >
      <GenericModal
        isOpen={modalState.isOpen}
        loading={modalState.loading}
        title={modalState.title.charAt(0).toUpperCase() + modalState.title.slice(1)}
        onClose={mapping[ModalActions.CLOSE_MODAL]}
        actions={modalsActionsConfig[modalState.state].map(({ type, onClick }) => ({
          type,
          onClick: onClick.effect ? mapping[onClick.effect] : onClick.action ? mapping[onClick.action] : () => { }
        }))}
      >
        <DialogContentText style={{ paddingLeft: '1em', paddingRight: '1em', paddingBottom: '1em' }}>{modalState.text}</DialogContentText>
      </GenericModal>
      <div className={classes.handleContainer} style={{ overflow: 'overlay' }}>
        <div className={classes.taskListDiv}>
          <div className={classes.main}>
            <span style={{ color: "red" }}>
              {ticket.isStale && "Changes have been made to this ticket since you opened this popup. Kindly refresh the browser to show the changed task list"}
            </span>
            {ticket?.tasks.map((task: ITask) =>
              <div key={task.taskId} onClick={() => ticket.setCurrentTask(task.taskId)} className={classes.taskListItem} style={{ border: task.taskId == taskIdValue ? '0.01em solid #D0EBFF' : 'none' }}>
                {task.taskId == taskIdValue ? <div style={{ width: '0.4em', margin: '-0.3em 0em -0.25em -0.3em', background: '#585858' }}></div> : null}
                <div style={{ padding: '0.2em', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', maxWidth: '50%', flex: 1 }} >
                  <span style={{ fontWeight: task.taskId == taskIdValue ? 600 : 400, textTransform: "capitalize", marginLeft: '1em' }}>{task.taskName}</span>
                </div>
                <div style={{ width: '40%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', maxWidth: '50%', flex: 1 }}>
                  <div style={{ padding: '0.2em', display: 'flex', justifyContent: 'flex-start' }}>
                    {task.isMultiUser ?
                      (
                        <>
                          <Avatar color="cyan" radius="xl" size={"xs"} sx={{ opacity: 0.8, marginRight: '0.5em' }} />

                          <span style={{ fontWeight: task.taskId == taskIdValue ? 600 : 400, marginRight: '0.5em' }}>{
                            task.assignee.length === 1 ?
                              ticket.processVariables.usersMap[task.assignee && task.assignee[0] || ""]
                              :
                              (<HoverCard shadow="md" closeDelay={500} zIndex={1301} withinPortal >
                                <HoverCard.Target>
                                  <Text>Unassigned</Text>
                                </HoverCard.Target>
                                <HoverCard.Dropdown>
                                  <Group style={{ fontWeight: 400 }}>
                                    <Text fw={600}>Candidates: </Text>
                                    {task.assignee.map(id => (
                                      <p key={id}>{ticket.processVariables.usersMap[id]}</p>
                                    ))}
                                  </Group>
                                </HoverCard.Dropdown>
                              </HoverCard>
                              )

                          }
                          </span>
                          <span style={{ display: 'flex', alignSelf: 'center', }}>
                            {(task.isClaimable || task.isUnclaimable) ?
                              <Group position="center"><Tooltip position='right-end' label={task.isClaimable ? "Assign Task to Me" : "Remove Me as Assignee"}><Text c="blue.8" onClick={() => { task.isClaimable ? task.claim() : task.unclaim(); }}>{task.isClaimable ? "Claim" : "Relase"}</Text></Tooltip></Group> : null}
                          </span>
                        </>
                      )
                      :
                      (
                        <>
                          <Avatar color="cyan" radius="xl" size={"xs"} sx={{ opacity: 0.8, marginRight: '0.5em' }} />
                          <span style={{ fontWeight: task.taskId == taskIdValue ? 600 : 400, marginRight: '0.5em' }}>{ticket.processVariables.usersMap[task.assignee && task.assignee[0] || ""]}</span>
                          <span style={{ display: 'flex', alignSelf: 'center', }}>
                            {ticket.ticketDefinition.features.editableAssignee && ticket.ticketDefinition.features.editableAssignee[ticket.ticketDefinition.featureConfig.editableAssignee.configKeys[1]] && ticket.ticketDefinition.features.editableAssignee[ticket.ticketDefinition.featureConfig.editableAssignee.configKeys[1]][task.taskDefinitionKey] && (ticket.ticketDefinition.isAdhoc || (task.assignee && task.assignee[0] === userId)) ?
                              <Group position="center"><Tooltip position='right-end' label={"Re-Assign"}><IconPencil onClick={() => { if (taskIdValue !== task.taskId) { ticket.setCurrentTask(task.taskId) } setOpen(true); }} style={{ width: '1em', height: '1em' }} /></Tooltip></Group> : null}</span>

                        </>)
                    }
                  </div>
                  {task.taskId == taskIdValue ? <span style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginRight: '1em' }}>
                    {/* <img src={Arrow} className={classes.listArrow} /> */}
                    <IconEye color="#585858" style={{ width: '1.2em', height: '1.2em' }} />
                  </span>
                    : null}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={classes.handleScrollLayer}>
          {ticket.formFields?.length && Object.keys(state).length ?
            ticket.formFields?.map(({ id, name, readOnly, required, type, options, optionsExpression, placeholder, params }) => {
              const tM = transformMap(type);
              if (type === "dropdown" || type === "radio-buttons" || type === "multiselect") {
                const Component: MemoExoticComponent<FunctionComponent<IFormDropdownInputProps | IMultiSelectDropdownInputProps>> = tM.Component;
                return (
                  <div key={id} style={{ display: "flex", width: "100%", flexDirection: "row", alignItems: "flex-end", }}>
                    <div className={classes.fieldData}>
                      <Component
                        field={id}
                        dispatch={dispatch}
                        state={state[id]}
                        label={name}
                        options={options.slice() || []}
                        disabled={!!readOnly || (ticket.currentTask?.assignee[0] !== userId)}
                        required={!!required && !readOnly}
                        size='xs'
                        icon={coreFields[id] && coreFields[id].icon || null}
                      />
                    </div>
                    <Copy copy={() => { copy(transformCopy[type][options.find(({ id: idd }) => idd === state[id])?.name || state[id]]); setLastCopied(() => id); }} copied={copied && (lastCopied === id)} />
                  </div>
                );
              } else if (type === "upload") {
                const Component: MemoExoticComponent<FunctionComponent<IImagePickerProps>> = tM.Component;
                return (
                  <div key={name} style={{ display: "flex", flexDirection: "row", width: "100%", alignItems: "flex-end", }}>
                    <div className={classes.fieldData}>
                      <Component
                        field={id}
                        dispatch={dispatch}
                        state={state[id]}
                        type={type}
                        label={name}
                        disabled={!!readOnly || (ticket.currentTask?.assignee[0] !== userId)}
                        required={!!required && !readOnly}
                        params={params}
                        onFileChange={handleFileChange}
                        usersMap={store.ticket.currentTicket?.processVariables.usersMap || {}}
                      />
                    </div>
                    {!!tM.disableCopy ? null : <Copy copy={() => { copy(transformCopy[type][state[id]]); setLastCopied(() => id); }} copied={copied && (lastCopied === id)} />}
                  </div>
                );
              }
              else {
                const Component: MemoExoticComponent<FunctionComponent<IGenericInputProps | IImagePickerProps | IDateTimeInputProps>> = tM.Component;
                return (
                  <div key={name} style={{ display: "flex", flexDirection: "row", width: "100%", alignItems: "flex-end", }}>
                    <div className={classes.fieldData}>
                      <Component
                        field={id}
                        dispatch={dispatch}
                        state={state[id]}
                        type={type === "datetime" ? "datetime-local" : type}
                        label={name}
                        disabled={!!readOnly || (ticket.currentTask?.assignee[0] !== userId)}
                        required={!!required && !readOnly}
                        isDueDate={false}
                        size='xs'
                        icon={coreFields[id] && coreFields[id].icon || null}
                      />
                    </div>
                    {!!tM.disableCopy ? null : <Copy copy={() => { copy(transformCopy[type][state[id]]); setLastCopied(() => id); }} copied={copied && (lastCopied === id)} />}
                  </div>
                );
              }
            })
            : (ticket.currentTask && !ticket.currentTask?.assignee.includes(userId)) ? <NotCurrentAssignee taskName={ticket.currentTask?.taskName || ""} userName={ticket.processVariables.usersMap[ticket.currentTask?.assignee[0] || ""]} /> : null
          }
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "column", flexGrow: 1, flexShrink: 0 }}>
        {isInputVisible ?
          <div style={{ display: 'flex', flexDirection: 'column', border: '1px solid red', background: "#F1F3F5", }}>
            <div className={`${classes.hiddenInput} ${isInputVisible ? classes.visibleInput : ""}`}>
              <Textarea
                label={"Remarks for " + outcomeActionName.toUpperCase()}
                description={"This field is mandatory! Please enter your remarks below for the action you're taking."}
                placeholder="Enter remarks here"
                size='xs'
                sx={{ width: '100%', ["& .mantine-TextInput-label"]: { marginTop: '0.5em', marginBottom: '0.5em', fontSize: '0.7em', color: '#585858', fontWeight: 600, } }}
                required={true}
                onChange={(event) => setMandatoryComment(event.currentTarget.value)} />
              {/* <div style={{ display: 'flex', justifyContent: 'flex-end', cursor: 'pointer', position: 'relative', top: 0, right: 0 }}>
                 <CloseButton
                 size={'xs'}
                 onClick={() => { setInputVisible(false); setMandatoryComment(""); }} />
                 </div> */}
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', padding: '1em' }}>
              <Button
                className={classes.submitbutton}
                style={{ marginRight: '1em' }}
                onClick={() => { setInputVisible(false); setMandatoryComment(""); }}
              >CLOSE</Button>
              <Button
                className={classes.submitbutton}
                disabled={mandatoryComment.length <= 0}
                onClick={() => modalDispatch({ type: ModalActions.OPEN_MODAL, payload: { state: ModalState.OUTCOME_OPEN, outcome: outcomeActionName } })}
              >{ticket.processVariables.outcomeMeta && ticket.processVariables.outcomeMeta[outcomeActionName] ? (ticket.processVariables.outcomeMeta[outcomeActionName]["btn-label"] || "") : outcomeActionName || 'OK'}</Button>
            </div>
          </div>
          : null}
        {ticket.formFields?.length ? (
          <div style={{ display: "flex", flexDirection: "column", boxShadow: "0px -10px 10px -7px rgba(0,0,0,0.12)" }}>
            <div style={{ flexShrink: 1, display: "flex", padding: "0.5em", justifyContent: "center" }}>
              {
                disabled ? !state.isChanged ? (<span className={classes.captionText}>No changes have been made in the form yet.</span>) : (<span className={classes.captionText} style={{ color: "#EA3434" }}>You can only submit the form when all mandatory fields are filled.</span>) : null}
            </div>
            <div className={classes.submitCancelDiv}>
              <Button
                className={classes.submitbutton}
                disabled={!state.isChanged || modalState.loading || isInputVisible}
                onClick={mapping[ModalEffects.SAVE]}
              >
                SAVE AS DRAFT
                {modalState.loading && modalState.state === ModalState.CLOSED ?
                  <CircularProgress
                    size="1.7em"
                    thickness={6}
                    style={{
                      color: "#2038e0",
                      marginLeft: "1em"
                    }}
                  />
                  : null}
              </Button>
              {(outcomes && outcomes.length) ?
                outcomes.map(({ name }) => (
                  <Button
                    key={name}
                    disabled={disabled || isInputVisible}
                    className={classes.submitbutton}
                    onClick={() => {
                      if (ticket.processVariables.outcomeMeta && ticket.processVariables.outcomeMeta[name] && ticket.processVariables.outcomeMeta[name].mandatory_comment && !mandatoryComment.length) {
                        setInputVisible(true);
                        setOutcomeActionName(name);
                      }
                      else {
                        modalDispatch({ type: ModalActions.OPEN_MODAL, payload: { state: ModalState.OUTCOME_OPEN, outcome: name } })
                      };
                    }}>
                    {(ticket.processVariables.outcomeMeta && ticket.processVariables.outcomeMeta[name] ? (ticket.processVariables.outcomeMeta[name]["btn-label"] || "") : name).toUpperCase()}
                  </Button>
                ))
                :
                (<Button
                  color="secondary"
                  className={classes.submitbutton}
                  disabled={disabled}
                  onClick={() => { modalDispatch({ type: ModalActions.OPEN_MODAL, payload: { state: ModalState.SUBMIT_OPEN } }); }}>
                  SUBMIT
                </Button>)
              }
            </div>
          </div>) : null}
      </div>
      <GenericModal
        isOpen={open}
        onClose={closeAssigneeListModal}
        actions={[]}
      >
        <div>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '1em' }}>
            <span style={{ color: '#585858', fontWeight: 600 }}>Choose New Assignee</span>
            <span><CloseButton color='red' title='Close' onClick={closeAssigneeListModal} /></span>
          </div>
          <AssigneeList userId={ticket.currentTask?.assignee && ticket.currentTask?.assignee[0]} handleAssigneeUser={handleAssigneeUser} options={ticket.ticketDefinition.features.editableAssignee && ticket.ticketDefinition.features.editableAssignee[ticket.ticketDefinition.featureConfig.editableAssignee.configKeys[0]] && ticket.processVariables[ticket.ticketDefinition.features.editableAssignee[ticket.ticketDefinition.featureConfig.editableAssignee.configKeys[0]][ticket.currentTask?.taskDefinitionKey || ""] || ""]?.map(id => ({ id, name: ticket.processVariables.usersMap[id] ? ticket.processVariables.usersMap[id] : id })) || []} />
        </div>
      </GenericModal>
    </div >
  );
};
export default observer(TicketTasks);
