import React, { memo } from 'react';
import { TypographyStylesProvider } from '@mantine/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    wordWrap: 'break-word',
    '& h1, h2, h3, h4, h5, h6, p' : {
      margin: 0
    },
    '& hr': {
      margin: '0.5em 0em',
      borderTop: '0.0625rem solid #868e96',
      opacity: 0.3
    }
  },
  content : {
    fontSize: '0.8em',
    marginTop: '0.5em',
    marginBottom:'0.5em'
  }
})

const labelStyle = { display: 'inline-block', cursor: 'default', lineHeight: '1.55', fontSize: '0.7525em', fontWeight: 600, color: '#585858', letterSpacing: '0.01071em', fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji' }

function HTMLContent({ content, label }) {
  const classes = useStyles();
  return (
    <TypographyStylesProvider className={classes.root}>
     <div style={labelStyle}>{label}</div>
      <div
        className={classes.content}
        dangerouslySetInnerHTML={{
          __html: content && content.length > 0 ? content : '-'
        }}
      />
    </TypographyStylesProvider>
  );
}

export default memo(HTMLContent)