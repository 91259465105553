import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { MRT_Column, MRT_Row } from "mantine-react-table";
import { IUser } from "../../../models/Ticket";

const useStyles = makeStyles((theme: Theme) => ({
  assigneeName: {
    fontSize: "1em",
  },
  assigneeTitle: {
    fontSize: "0.675em",
    color: "#A9A9A9"
  }
}));

const RequestUser = ({ row: { original }, column }: { row: MRT_Row<any>; column: MRT_Column<any>; }) => {
  const classes = useStyles();
  const val = !!original[column.id] ? Array.isArray(original[column.id]) ? original[column.id] : [original[column.id]] : [];
  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      {column.id === "assignee" && original["isStale"] && (
        <span style={{ color: "red", fontWeight: 700 }}>*</span>
      )}
      <div style={{ display: "flex", flexDirection: "column" }}>
        {!val.length ?
          "—" :
          val.length > 1 ?
            val.map((vall: IUser, ind) => (
              <div key={vall.id + ind} style={{ display: "flex", flexDirection: "column", marginBottom: "2px" }}>
                <span className={classes.assigneeName}>{vall.name}</span>
                {vall.title ? <span className={classes.assigneeTitle}>{vall.title}</span> : null}
              </div>
            ))
            :
            val.map((vall: IUser) => (
              <div
                key={vall.id} style={{ display: "flex", flexDirection: "column" }}>
                <span className={classes.assigneeName}>{vall.name}</span>
                {vall.title ? <span className={classes.assigneeTitle}>{vall.title}</span> : null}
              </div>
            ))
        }
      </div>
    </div >
  )
};

export default RequestUser;
