import moment from 'moment';
import React, { memo } from 'react';
import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { HistoryEvents, HistoryEventCategories } from '../models/enums';
import { downloadFile } from '../api/transactionServer';
import { IHistory } from '../models/Ticket';
import { useStore } from '../models/ProvideModel';
import { Tooltip } from '@mantine/core'


const useStyles = makeStyles((theme: Theme) => ({
  root: {
    fontSize: "0.775em",
    color: "#585858",
    padding: "0.8em 1.5em",
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    width: '100%',
    opacity: 0.6
  },
  leftDiv: { width: '80%' },
  rightDiv: { width: '20%', fontSize: '0.775em' },
  paper: props => ({
    borderRadius: "0em",
    opactiy: "1 !important"
  }),
  event: {
    fontWeight: 600,
    color: "#585858"
  },
  date: {
    opacity: 0.9
  }
}));

const thirtyDaysInMilliseconds = 2592000000

const HistoryEventItem = ({ eventCategory, eventName, payload, usersMap, assignee, createdAt, isSystem, user }) => {
  const classes = useStyles();
  const store = useStore();
  const readableEventName = HistoryEvents[eventName] || eventName;
  const readableEventCategories = HistoryEventCategories[eventCategory] || eventCategory;
  const dateTime = moment(moment.now()).diff(moment(createdAt)) > thirtyDaysInMilliseconds ? moment(createdAt).format('MMM DD') : moment(createdAt).fromNow();
  if (readableEventCategories === HistoryEventCategories.USER_TASK) {

    if (readableEventName == HistoryEvents.CREATED) return null

    return (
      <div className={classes.root}>
        <div className={classes.leftDiv}>
          {
            readableEventName === HistoryEvents.ASSIGNED ? payload.title === "Adhoc Task" ?  <span>Ticket <span>{readableEventName}</span> to <span className={classes.event}>{usersMap[payload?.toUser]}</span></span> :
              <span>Sub-task <span style={{ textTransform: "capitalize", fontWeight: "bold" }}>{payload.title}</span> <span>{readableEventName}</span> to <span className={classes.event}>{usersMap[payload?.toUser]}</span></span>
              :
              readableEventName === HistoryEvents.COMPLETED && payload.outcome ?
                <span>{user} {readableEventName} <span style={{ textTransform: "capitalize", fontWeight: "bold" }}>"{payload.title}"</span> sub-task with outcome <strong>{payload.outcome}</strong></span>

                :
                null
          }
        </div>
        <Tooltip className={classes.rightDiv} label={moment(createdAt).format('MMM DD, YYYY hh:mm A')} position='left'><span className={classes.date}>{dateTime}</span></Tooltip>
      </div >
    );
  }
  if (isSystem) {
    return (
      <div className={classes.root}>
        <div className={classes.leftDiv}>
          <span className={classes.event}>{readableEventCategories} </span> <span>{` ${readableEventName}`}</span>
          {
            payload ?
              <span>{(payload?.old || usersMap[payload?.old]) ? ` from "${readableEventCategories === "Status" ? store.ticket?.statuses.find(p => p.id === payload?.old)?.name : readableEventCategories === "priority" ? store.ticket?.priorities.find(p => p.id === payload?.old)?.name : (usersMap[payload?.old] || payload?.old)}"` : null}{(usersMap[payload?.new] || payload?.new) ? <span> to <span className={classes.event}>"{readableEventCategories === "Status" ? store.ticket?.statuses.find(p => p.id === payload?.new)?.name : readableEventCategories === "priority" ? store.ticket?.priorities.find(p => p.id === payload?.new)?.name : (usersMap[payload?.new] || payload?.new )}"</span></span> : null}</span>
              :
              ""
          }
        </div>
        <Tooltip className={classes.rightDiv} label={moment(createdAt).format('MMM DD, YYYY hh:mm A')} position='left'><span className={classes.date}>{dateTime}</span></Tooltip>
      </div>
    )
  }
  if (readableEventCategories === "file") {
    const click = () => {
      downloadFile({ fileId: payload.fileId, fileName: payload.fileName, version: payload.version });
    }
    return (
      <div className={classes.root}>
        <div className={classes.leftDiv}>
          <span>{user} <span className={classes.event}>{readableEventName}</span> {readableEventCategories} </span>
          {readableEventName === "deleted" ?
            <span>{payload.title} — ${payload.fileName}</span>
            :
            // (<a href="#" onClick={click}>{payload.title}</a>)
            (<span>{payload.title}</span>) //Remove hyperlink for task field uploads
            }
        </div>
        <Tooltip className={classes.rightDiv} label={moment(createdAt).format('MMM DD, YYYY hh:mm A')} position='left'><span className={classes.date}>{dateTime}</span></Tooltip>
      </div>
    );
  }
  if(readableEventCategories === "assignee" && (readableEventName === "claimed" || readableEventName === "unclaimed")) {
    return (
      <div className={classes.root}>
        <div className={classes.leftDiv}>
          <span>{user} <span className={classes.event}>{readableEventName}</span> </span>
          <span>{payload && payload.title ? `Task ${payload.title}` : ""}</span>
        </div>
        <Tooltip className={classes.rightDiv} label={moment(createdAt).format('MMM DD, YYYY hh:mm A')} position='left'><span className={classes.date}>{dateTime}</span></Tooltip>
      </div>
  );
  }
  return (
    <div className={classes.root}>
      <div className={classes.leftDiv}>
        <span>{user} <span className={classes.event}>{readableEventName}</span> {readableEventCategories}</span>
        {payload && (readableEventCategories !== HistoryEventCategories.DESCRIPTION) ?
          //  <span> from {payload?.from || usersMap[payload?.previous_user]} to {payload?.to || usersMap[payload?.assigned_user]}</span>
          <span>{
            (payload?.old || usersMap[payload?.old]) ? 
            ` from ${ readableEventCategories === "Status" ? store.ticket?.statuses.find(p => p.id === payload?.old)?.name : readableEventCategories === "priority" ? store.ticket?.priorities.find(p => p.id === payload?.old)?.name : (usersMap[payload?.old] || payload?.old) }` : null}
            {(readableEventCategories === "Status" ? store.ticket?.statuses.find(p => p.id === payload?.new)?.name : readableEventCategories === "priority" ? store.ticket?.priorities.find(p => p.id === payload?.new)?.name : (usersMap[payload?.new] || payload?.new)) ? ` to ${readableEventCategories === "Status" ? store.ticket?.statuses.find(p => p.id === payload?.new)?.name : readableEventCategories === "priority" ? store.ticket?.priorities.find(p => p.id === payload?.new)?.name : (usersMap[payload?.new] || payload?.new)}` : null}
          </span>
          :
          ""}
      </div>
      <Tooltip className={classes.rightDiv} label={moment(createdAt).format('MMM DD, YYYY hh:mm A')} position='left'><span className={classes.date}>{dateTime}</span></Tooltip>
    </div>
  );
};

const HistoryEventDisplay = ({ taskId, ticketId, source, payload, createdAt, eventName, eventCategory, usersMap, assignee }: IHistory & { assignee: string; usersMap: { [K: string]: string; } }) => {
  return <HistoryEventItem
    eventCategory={eventCategory}
    eventName={eventName}
    payload={payload}
    usersMap={usersMap}
    assignee={assignee}
    createdAt={createdAt}
    isSystem={source === "SYSTEM" || !source}
    user={source === assignee ? "You" : usersMap[source || ""] || source}
  />
};
export default memo(HistoryEventDisplay);
