import React from 'react'

const NotFound = () => {
  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
    //   alignItems: 'center',
      height: '100%',
      textAlign: 'center',
      padding: '20px',
      width: '100%'
    }}>
      <p>Report you're trying to view does not exist in FalconBrick's catalogue. Please contact administrator for details.</p>
    </div>
  )
}

export default NotFound