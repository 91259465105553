import React, { memo } from 'react';
import { Select } from '@mantine/core';
import { FormControl } from '@material-ui/core';
import { useStore } from '../../models/ProvideModel';
import { useHistory } from 'react-router';

const ProjectSelect = ({ options, label }: { options: {id: string, name: string | undefined, valid?: boolean}[], label: string }) => {
    const store = useStore();
    const history = useHistory();
    const optionsData = options.map(option => ({ value: option.id, label: option.name }))
    return (
        <div style={{ marginLeft: '1em'}}>
            <FormControl>
                <Select
                    // label={label}
                    data={optionsData.map(option => ({
                        ...option,
                        disabled: !options.find(o => o.id === option.value)?.valid
                    }))}
                    placeholder={label}
                    onChange={(val) => {
                        const newProj = store.projectInfo.projects?.find(proj => proj.id === val);
                        const currentURL = history.location
                        if (newProj) {
                            store.projectInfo.unsetProject();
                            store.projectInfo.setProject(newProj);
                            store.ticket.clear();
                            setTimeout(() => {
                                history.replace(currentURL);
                                history.go(0);
                            }, 1000)
                        }
                    }}
                />
            </FormControl>
        </div>
    )
}

export default memo(ProjectSelect);