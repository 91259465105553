import { types, flow, getRoot, getEnv, Instance, decorate, SnapshotOrInstance, applySnapshot, SnapshotIn } from 'mobx-state-tree';
import { QueryOptions, OperationVariables } from 'apollo-client';
import { chan } from 'csp-with-ts';
import { Params, IParams, Filters } from './Common';
import { ResponsiveUtils } from './ResponsiveUtils';
import { Tower } from './Tower';
import { Auth, } from './Auth';
import { GET_BLOCKS, GET_TABS } from '../utils/queries';
import { handleLoading } from './Middleware';
import { Summary } from './Summary';
import { MenuModel, } from './Menu'
import InfoPanel from './InfoPanel';
import Projects from './Project';
import TableState from './TableState';
import Excel from './tableFilters';
import UnitActivityDetails from './UnitActivityDetails'
import UserReportDetails from './UserReportDetails'
import { getDashboard, Dashboards, DashboardTypes, TabTypes } from './enums';
import { DownloadQ } from './Download';
import { Dashboard } from './Dashboard';
import { sortingStrategy, groupByKeys } from '../utils/utils';
import { ChecklistSelect } from './MultiSelect';
import { Notifications } from './Notifications';
import { TicketModule } from './Ticket';
import { AdminModule } from './admin/AdminModule'
import { PlanningModule } from './Planning';

// Old return type
// { units: { [K in SnapshotOut<typeof ePhase>]: { phase: K; columns: { [P in string | number]: { id: P; name: string; type: typeof CellAddressUnitX } } | {}, rows: { [M in string | number]: { id: M; name: string; type: typeof CellAddressUnitY } }, rowExtremities: { min: number; max: number; } } } }


// TODO: Factor out channels into their own volatile model

export type IChanCommands = { prop: string; action: string; path?: string; args?: any[] };
export const StoreModel = types.model({
    id: types.identifier,
    towers: types.array(Tower),
    dashboards: types.map(Dashboard),
    auth: Auth,
    params: Params,
    filters: Filters,
    projectInfo: Projects,
    summary: Summary,
    infoPanel: InfoPanel,
    unitActivityDetails: UnitActivityDetails,
    menus: MenuModel,
    userReportDetails: UserReportDetails,
    download: DownloadQ,
    responsiveUtils: ResponsiveUtils,
    downloadpdf: TableState,
    exportTableFilters: Excel,
    checklistMultiSelect: ChecklistSelect,
    notifications: Notifications,
    ticket: TicketModule,
    adminModule: AdminModule,
    planning: PlanningModule,
}).volatile(_ => ({ loading: false, channel: chan<IChanCommands>() })).views(self => ({
    get currentTower() {
        if (!!!self.towers || self.towers.length === 0)
            return null;
        if (self.params.tower) {
            const res = self.towers.find(({ id }) => id === self.params.tower) || null;
            return res;
        }
        return null;
    }
})).views(self => ({
    get currentDashboard() {
        try {
            const id = getDashboard(self.params);
            if (id === null) { return null; }
            return self.dashboards.get(id) || null;
        } catch (err) { console.error(err); return null; }
    }
})).views(self => ({
    get towersList() {
        const list = self.towers
            .filter(tower => (
                !self.currentTower && (!Array.isArray(self.filters.tower) || self.filters.tower.length === 0) ?
                    self.params.section !== "dashboard" ?
                        true :
                        self.currentDashboard?.towers.length ?
                            self.currentDashboard?.towers.find(t => t.id === tower.id)
                            :
                            false
                    :
                    self.currentTower
                        ?
                        self.currentTower.id === tower.id
                        :
                        self.filters.hasTower(tower.id)
            ));
        return list.sort(sortingStrategy);
    }
})).views(self => ({
    get pos(): number[] {
        return self.currentDashboard?.lpColumnPos || [0];
    },
    get totalRows() {
        if (self.loading || !self.towers.length) { return []; }
        const dash = self.currentDashboard;
        if (!dash) { return []; }
        return dash!.rows;
    },
    get totalColumns(): number {
        if (self.loading || !self.towers.length) { return 1; }
        return self.currentDashboard?.totalColumns || 1;
    },
    get totalColumnSortedFiltered() {
        return self.currentDashboard?.columns || [];
    }
})).actions(self => ({
    setLoading(arg: boolean) {
        self.loading = arg;
    }
})).actions(self => {
    const fetch = flow(function* fetchQuery(query: any, params?: object): never | any {
        const queryObject: QueryOptions<OperationVariables> = params ? { query, fetchPolicy: 'no-cache', ...params } : { query, fetchPolicy: 'no-cache' };
        if (!getEnv(self).client.client) {
            getEnv(self).client();
        }
        const queryResult = yield getEnv(self).client.client.query(queryObject);
        return queryResult.data;
    });
    return {
        fetch: decorate(handleLoading, fetch)
    };
}).actions(self => ({
    setParams(params: Instance<IParams>) {
        self.params = { ...params, graphType: params.graphType || self.params.graphType, spaceTypes: self.params.spaceTypes, phasesWithNames: self.params.phasesWithNames };
    }
})).actions(self => ({
    createTcaDashboards(tabs: any[]) {
        const data: SnapshotIn<typeof Dashboard> = [
            [Dashboards.STRUCTURES_TOWER, {
                id: Dashboards.STRUCTURES_TOWER,
                type: DashboardTypes.STRUCTURES_TOWER_TYPE
            }],
            [Dashboards.FINISHING_TOWER, {
                id: Dashboards.FINISHING_TOWER,
                type: DashboardTypes.FINISHING_TOWER_TYPE
            }],
        ];
        if (tabs.length) {
            // self.tabs.clear();
            const dynDash = groupByKeys(['phase', 'spaceType'], tabs);
            dynDash.forEach(([params, tabsData]) => {
                const dashId = getDashboard({ ...self.params, ...params });
                if (!dashId) { throw new Error("Invalid spaceType and phase values configured for TCA"); }
                self.dashboards.set(dashId, { id: dashId, type: DashboardTypes.TABS, phase: params.phase, spaceType: params.spaceType });
                self.dashboards.get(dashId)?.setTabs(tabsData, TabTypes.LANDING);
                tabsData.forEach(({ id }) => {
                    data.push([`${id}_${params.phase}_${params.spaceType}`, { id: `${id}_${params.phase}_${params.spaceType}`, type: DashboardTypes.TABS, phase: params.phase, spaceType: params.spaceType }]);
                });
            });
        }
        self.dashboards.merge(data);
        localStorage.setItem('tabs', JSON.stringify(tabs));
    }
})).actions(self => ({
    getTowers: flow(function* getTowers() {
        if (!self.loading && !self.towers.length) {
            if (self.projectInfo.currentProject) {
                const propertyList = self.projectInfo.currentProject.properties.map(({ id }) => id);
                try {
                    // * this is temp solution for property selection
                    const data: any = yield (getRoot(self) as ILateStoreModel).fetch(GET_BLOCKS, {
                        variables: { propertyList },
                        fetchPolicy: 'network-only'
                    });
                    self.towers.clear();
                    self.towers.push(...data!.towersList);
                }
                catch (err) {
                    console.error(err);
                }
            }
        }
    }),
    refreshTowers() {
        if (!self.loading) { self.towers.clear(); }
    },
    refreshProjects() {
        localStorage.clear();
        applySnapshot(self.projectInfo, {});
    },
    makeDashboards: flow(function* createDashboards() {
        if (self.dashboards.size || !self.projectInfo.currentProject) { return; }
        const tabsStore = localStorage.getItem('tabs');
        if (!tabsStore) {
            const { tabs } = yield self.fetch(GET_TABS, {
                variables: {
                    blocks: self.towers.map(({ id }) => id),
                    project: self.projectInfo.currentProject.id
                }
            });
            self.createTcaDashboards(tabs.map(({ tab, ...rest }) => ({ ...tab, ...rest })));
        }
        else if (tabsStore) {
            const tabs: { id: string; name: string; type: string; phase: string; spaceType: string; }[] = JSON.parse(tabsStore);
            self.createTcaDashboards(tabs);
        }
    }),
    refreshTicketModule() {
        self.ticket.clear();
    }
})).actions(self => ({
    refreshDashboards() { self.dashboards.clear(); self.makeDashboards(); },
    getDashboard: flow(function* getDashboardRoot() {
        if (!self.loading) {
            try {
                if (self.currentDashboard) { yield self.currentDashboard?.getDashboards(); }
            }
            catch (err) { console.error(err); }
        }
    })
})).actions(self => ({ afterCreate() { if (self.projectInfo.currentProject) { self.makeDashboards(); } } }));

export const LateStoreModel = types.late<typeof StoreModel>((): SnapshotOrInstance<typeof StoreModel> => StoreModel);

// type ILateStoreModel = typeof LateStoreModel;
// export type IStoreModel = typeof StoreModel.Type;

export interface ILateStoreModel extends Instance<typeof LateStoreModel> { };
export interface IStoreModel extends Instance<typeof StoreModel> { };
