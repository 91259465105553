import React, { memo, useEffect } from 'react';
import { useEditor, BubbleMenu } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import Highlight from '@tiptap/extension-highlight';
import TextAlign from '@tiptap/extension-text-align';
import Placeholder from '@tiptap/extension-placeholder';
import { RichTextEditor, Link } from '@mantine/tiptap';

function RichTextInput({ value, setValue, children, setBlur }) {
  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Highlight,
      TextAlign.configure({ types: ['heading', 'paragraph'] }),
      Placeholder.configure({ placeholder: 'Enter your comment here..' }),
      Link
    ],
    editable: true,
    content: value,
    onUpdate: ({ editor }) => {
      const input = editor.getHTML();
      // console.log(`RT input -> ${JSON.stringify(input)}`)
      setValue(input);
    },
    autofocus: true,
    // onBlur: setBlur
  });
  useEffect(() => {
    if (editor && value === "<p></p>") {
      editor.commands.setContent(value);
    }
  }, [value, editor]);

  return (
    <RichTextEditor editor={editor} sx={{ width: '100%', wordBreak: 'break-word' }}>
      <RichTextEditor.Toolbar sticky stickyOffset={60} style={{ borderRadius: 0}}>
      {/* {editor && (<BubbleMenu editor={editor} > */}
          {/* <RichTextEditor.ControlsGroup>
              <RichTextEditor.Bold />
              <RichTextEditor.Italic />
              <RichTextEditor.Underline />
              <RichTextEditor.Strikethrough />
              <RichTextEditor.Highlight />
              </RichTextEditor.ControlsGroup>

              <RichTextEditor.ControlsGroup>
              <RichTextEditor.H1 />
              <RichTextEditor.H2 />
              <RichTextEditor.H3 />
              <RichTextEditor.H4 />
              </RichTextEditor.ControlsGroup>

              <RichTextEditor.ControlsGroup>
              <RichTextEditor.BulletList />
              <RichTextEditor.OrderedList />
              </RichTextEditor.ControlsGroup>

              <RichTextEditor.ControlsGroup>
              <RichTextEditor.AlignLeft />
              <RichTextEditor.AlignCenter />
              <RichTextEditor.AlignJustify />
              <RichTextEditor.AlignRight />
              </RichTextEditor.ControlsGroup> */}

          <RichTextEditor.ControlsGroup sx={{display: "flex", justifyContent: 'space-between',  width: '100%'}}>

            <div style={{ display: "flex", justifyContent: 'space-between',  width: '100%', flexWrap: 'wrap'}}>
              <div style={{ display: "flex" }}>
                <RichTextEditor.Bold />
                <RichTextEditor.Italic />
                <RichTextEditor.Underline />
                <RichTextEditor.Strikethrough />
                <RichTextEditor.Highlight />
                <RichTextEditor.ClearFormatting />
              </div>

              <div style={{ display: "flex" }}>
                <RichTextEditor.H1 />
                <RichTextEditor.H2 />
                <RichTextEditor.H3 />
                <RichTextEditor.H4 />
                <RichTextEditor.H5 />
                <RichTextEditor.H6 />
              </div>

              <div style={{ display: "flex" }}>
                <RichTextEditor.BulletList />
                <RichTextEditor.OrderedList />
                <RichTextEditor.Hr />
              </div>

              <div style={{ display: "flex" }}>
                <RichTextEditor.AlignLeft />
                <RichTextEditor.AlignCenter />
                <RichTextEditor.AlignJustify />
                <RichTextEditor.AlignRight />
              </div>
            </div>
          </RichTextEditor.ControlsGroup>

        {/* </BubbleMenu>)} */}
      </RichTextEditor.Toolbar>
      <RichTextEditor.Content sx={{ fontSize: '0.8em', maxHeight: 200, minHeight: 100, overflowY: 'scroll', borderRadius: 0 }} />
      {children}
    </RichTextEditor>
  );
}

export default memo(RichTextInput)
