import React from 'react';
import APIAccordion from './APIAccordion'
import { IRequest } from '../../../models/admin/AdminModule';
import { observer } from 'mobx-react-lite';

const RenderAccordion: React.FC<{ data: IRequest[], expanded: boolean }> = ({ data, expanded }) => {
    const groupedData: { [K: string]: IRequest[] } = data.reduce((acc, item) => {
        acc[item.groupId] = acc[item.groupId] || [];
        acc[item.groupId].push(item);
        return acc;
    }, {});

    return (
        <>
            {Object.entries(groupedData).map(([groupId, items]) => (
                <APIAccordion key={groupId} groupId={groupId} apiData={items} expanded={expanded} />
            ))}
        </>
    );
}

export default observer(RenderAccordion)
