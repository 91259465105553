import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
    },
    main: {
        display: 'flex',
        marginTop: '2rem',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    taskDiv: {
        fontSize: '0.9575em'
    },
    task: {
        textTransform: "capitalize",
        fontWeight: 600
    },
    messageDiv: {
        fontSize: '0.7575em',
        opacity: .5
    }
}))

export default useStyles;