import React, { useState, memo, useMemo, useRef, useEffect, RefObject } from 'react';
import useStyles from './HistoryCommentsStyles';
import { Button } from '@material-ui/core';
import { submitComment, listCommentsForProcess, uploadFileV2, submitAttachmentComment } from '../../../api/transactionServer';
import HistoryEventDisplay from '../../../components/HistoryEventDisplay';
import CommentCard from '../../../components/CommentCard';
import RichTextInput from '../../../components/RichTextInput';
import RichTextRenderer from '../../../components/RichTextOutput';
import { IconMessageCircle2, IconMessageCircleOff, IconPaperclip, IconTrash, IconX } from '@tabler/icons-react';
import { Loader } from '@mantine/core';
import { reaction } from 'mobx';
import { IFile } from '../../requestsFormPopup/Attachments'
import { Dropzone } from '@mantine/dropzone';
import { ICommentDetail, IHistory, ITicket } from '../../../models/Ticket';
import { observer } from 'mobx-react-lite';
import HistoryCommentLoader from '../others/historyLoading/HistoryCommentSkeleton';
import AttachmentComment from '../tabItems/attachments/AttachmentComment';
import ToggleWatcherV2 from '../../../components/toggleWatcher/ToggleWatcherV2';
import { notifications } from '@mantine/notifications';

interface IMaterialCommentsPopup {
  customerId: string;
  userId: string;
  ticket: ITicket;
  lg?: boolean;
  onClose?: () => void;
  projectId: string;
  openRef: RefObject<() => void>
};
const MAX_SIZE = 25 * 1024 ** 2;

// TODO: Add comment date?
const MaterialCommentsPopup = ({ userId, ticket, lg, customerId, projectId, openRef }: IMaterialCommentsPopup) => {
  const [richTextComment, setRichTextComment] = useState<string>("<p></p>");
  const [hideTasksList, setHideTasksList] = useState(false)
  const classes = useStyles({ isLg: !!lg, showTasksList: !!hideTasksList });
  const [showRTE, setShowRTE] = useState(false);
  // const openRef = React.useRef<() => void>(null);
  const [fileLoading, setFileLoading] = React.useState(false);
  const [rejectMessage, setRejectMessage] = React.useState("");
  useEffect(() => {
    if (!ticket.history.length) { ticket.refreshHistory(); }
    if (!ticket.commentDetailList.length) { ticket.refreshComments(); }
  }, []);

  useEffect(() => {
    if (rejectMessage) {
      notifications.show({
        title: 'Error!',
        message: rejectMessage,
        color: 'red',
        icon: <IconX />
      })
    }
  }, [rejectMessage]);
  const onSubmit = (e) => {
    e.preventDefault();
    submitComment({ processInstanceId: ticket.id, userId, commentType: "test", commentMessage: richTextComment, customerId, projectId, moduleId: "requests" })
      .then(() => {
        setRichTextComment("<p></p>");
        ticket.refreshComments();
        ticket.setLastUpdated();
      })
    setShowRTE(false)
  };

  /**To set comments section scroll to bottom on load and whenever comments are updated */
  const divRef = useRef<HTMLDivElement>(null);
  useEffect(() => reaction(() => ticket.historyAndComments.length, () => {
    divRef.current?.scrollTo({ top: divRef.current?.scrollHeight });
    /* myDiv["scrollTop"] = myDiv["scrollHeight"]; */
  }), []);

  const setBlur = () => {
    setShowRTE(false)
  }

  const renderAttachmentDetails = (array: any) =>
    array.map((file) => `<strong>${file.fileName}</strong><br/>`).join("");

  const onChange = (dzfiles) => {
    setFileLoading(true);
    return Promise.all(dzfiles.map(file => uploadFileV2({ file }).then(res => ({ ...res, file })))).then((list) => {
      const allAttachments: IFile[] = list.map(({ data }) => data);
      submitAttachmentComment({ processInstanceId: ticket.id, userId, commentType: 'media', commentMessage: `${renderAttachmentDetails(allAttachments)}`, customerId, projectId, moduleId: "requests", attachments: allAttachments })
        .then(() => ticket.refreshComments())
        .then(() => setFileLoading(false));
    }).catch(error => { console.error(error); setFileLoading(false) })
  };

  const onDropHandler = (files: any[]) => {
    setRejectMessage("");
    let largeFile = false;
    files.map(file => { if (file.size > MAX_SIZE) { largeFile = true } });
    if (!largeFile) { onChange(files); } else { setRejectMessage(`Maximum file upload size is 25 MB.`) }
  };


  return (
    <div className={classes.commentsDiv}>
      <div className={classes.handleContainer} >
        <div className={classes.handleScrollLayer} ref={divRef}>
          <div className={classes.actions} style={{ display: 'flex', width: '100%', position: "sticky", top: 0, zIndex: 1 }}>
          <span className={classes.actionsTitle}>Actions</span>
          <div className={classes.actionsList}>
          <span className={classes.notiIcon}>
          <ToggleWatcherV2
            toggleState={!!ticket?.watchers.includes(userId)}
            ticket={ticket!}
          />
          </span>
          </div>
          </div>
          {ticket.historyAndComments.map(({ array, index }) => {
            const props = array ? (ticket.commentDetailList || [])[index] : ticket.history[index];
            return array ? (props as ICommentDetail).commentType === "event" ? null : (
              <CommentCard
                user={ticket.processVariables.usersMap[(props as ICommentDetail).userId]}
                key={(props as ICommentDetail).id}
                createdAt={props.createdAt as Date}
                you={userId === (props as ICommentDetail).userId}
                type={(props as ICommentDetail).commentType}
              >
                {(props as ICommentDetail).commentType === "media" ?
                  <AttachmentComment attachments={(props as ICommentDetail).attachments} customerId={customerId} commentMessage={(props as ICommentDetail).commentMessage} /> :
                  <RichTextRenderer content={(props as ICommentDetail).commentMessage} />}
              </CommentCard>
            ) :
              (
                <HistoryEventDisplay
                  key={`${props.createdAt}_${props.taskId}_${(props as IHistory).eventCategory}_${(props as IHistory).eventName}_${JSON.stringify((props as IHistory).payload)}`}
                  {...(props as IHistory)}
                  usersMap={ticket.processVariables.usersMap}
                  assignee={userId}
                />
              );
          })
          }
          {ticket.historyCommentsPaneLoading ? <HistoryCommentLoader /> : null}
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "column", flexGrow: 1, flexShrink: 0, alignItems: "flex-end", position: "sticky", bottom: 0, }}>
        {/* <div style={{ display: 'flex', width: '100%', flexDirection: 'column', position: "sticky", bottom: 0, zIndex: 1 }}>
          {rejectMessage ? <span className={classes.rejectMessage}>{rejectMessage}</span> : null}
        </div> */}
        <form className={classes.submitCommentDiv} onSubmit={onSubmit}>
          <Dropzone
            openRef={openRef}
            onDrop={onDropHandler}
            onReject={(files) => { console.log('rejected files', files); }}
            activateOnClick={false}
            multiple={true}
            className={classes.dropzoneRoot}
            style={{ padding: '0', }}
            styles={{ inner: { pointerEvents: "all", cursor: "default", display: 'flex', justifyContent: 'center', width: '100%' }, }}
          >
            {showRTE ?
              <RichTextInput
                value={richTextComment}
                setValue={setRichTextComment}
                setBlur={setBlur}
              >
                <div style={{ display: 'flex', justifyContent: 'space-between', padding: '0.5em 0.5em', background: "#FFF", borderTop: "0.01em solid #E9E9E9", alignItems: 'center' }}>

                  <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', opacity: 0.5, flexGrow: 1 }} onClick={() => setShowRTE(false)}>
                    <span style={{ color: '#585858', fontSize: '0.8em', alignSelf: 'center', marginRight: '0.2em' }}>Hide</span>
                    <IconMessageCircleOff style={{ alignSelf: 'center' }} width={'0.8em'} height={'0.8em'} />
                  </div>

                  <span style={{ alignSelf: 'flex-end' }} onClick={() => { if (openRef.current) openRef?.current() }}><IconPaperclip style={{ opacity: 0.5, marginRight: '0.5em', cursor: 'pointer' }} width={'1.2em'} height={'1.2em'} /></span>

                  <Button
                    type="submit"
                    className={classes.submitCommentButton}
                    disabled={!richTextComment.length || richTextComment == "<p></p>" || ticket.historyCommentsPaneLoading}
                    onClick={onSubmit}
                  >
                    {ticket.historyCommentsPaneLoading ? <span><Loader variant='dots' size={'xs'} color='#585858' /></span> : <span style={{}}>Comment</span>}
                  </Button>
                </div>
              </RichTextInput> : null}
            <div
              style={{ width: '100%', background: '#FFF', display: !showRTE ? 'flex' : 'none', justifyContent: 'space-between', padding: '0.5em 0.5em', borderTop: "0.01em solid #E9E9E9", cursor: 'text', alignItems: 'center' }}
              onClick={() => setShowRTE(true)}>
              <span style={{ opacity: 0.5, marginRight: '0.2em', fontSize: '0.8525em', padding: '0.42em 0em' }}>Comment or drop files</span> <IconMessageCircle2 style={{ opacity: 0.5 }} width={'1.2em'} height={'1.2em'} />
            </div>
          </Dropzone>
        </form>
      </div>
    </div>
  );
}

export default observer(MaterialCommentsPopup);
