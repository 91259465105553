import React from 'react'

const RenderIframe = ({ appURL } : { appURL: string}) => {
  return (
        <iframe 
        id="embeddedAppsmith" 
        src={appURL} 
        width={'100%'}
        height={'100%'}
        scrolling="yes" />
  )
}

export default RenderIframe