import React, { useState, Dispatch, ReducerAction, memo, useEffect } from 'react'
import moment from 'moment';
import { Popover, FileInput, Tooltip, TextInput } from '@mantine/core';
import { IconEdit, IconDownload, IconHistory, IconDotsVertical, IconTrashX, IconFile, IconPencil } from '@tabler/icons-react';
import FileHistoryCard from './FileHistoryCard';
import { uploadFileV2, downloadFile, getFileHistory, renameFile } from '../../../api/transactionServer';
import { isImageType, appendFileVersion } from '../../../utils/utils'
import useStyles from './MultipleImageFieldStyles';
import { useStore } from '../../../models/ProvideModel';

export interface IFileItemCardProps {
    field: string;
    file: { fileId: string, fileName: string, fileStatus: string, fileType: string, version: number, createdDate?: string, original_file_name: string }
    disabled?: boolean;
    dispatch: Dispatch<ReducerAction<any>>;
    onFileChange?: (field: string) => void;
    usersMap?: { [K: string]: string } | undefined
};

const GetFileType = () => {
    const classes = useStyles();
    return (
      <div className={classes.fileTypeTextDiv}>
        <IconFile className={classes.fileIcon} />
      </div>
    )
  }

const FileItemCard = ({ field, file, disabled, dispatch, onFileChange, usersMap }: IFileItemCardProps) => {
    const [history, setHistory] = useState([]);
    const store =  useStore();
    const [isEditing, setIsEditing] = useState(false); // Track edit mode
    const fileNameWithoutExt = file.fileName.replace(/\.[^.]*$/, '')
    const [editedFileName, setEditedFileName] = useState(fileNameWithoutExt); // Track the file name change
    const regex = /\.([a-zA-Z0-9]+)$/; //Extract only file extension
    const match: any = file.fileName.match(regex);

    //Handle rename input change
    const handleRenameValue = (e) => {
        const inputValue = e.currentTarget.value;
        const regex = /^[a-zA-Z0-9 _-\s]*$/; // Allow only alphanumeric characters, underscores, spaces and hyphens
        if (regex.test(inputValue)) {
            setEditedFileName(inputValue);
        }
    }
    
    const onDownload = (version?: number, historyFile?: any) => {
        if(version !== undefined && historyFile) {
            downloadFile({ fileId: historyFile.file_id, fileName: appendFileVersion(historyFile, version), version });
            return 
        }
        if (file) {
            downloadFile({ fileId: file.fileId, fileName: appendFileVersion({...file, file_name: file.fileName}) });
        }
    };
    const getHistory = () => {
        if (file) {
            getFileHistory({ fileId: file.fileId }).then(res => {
                setHistory(res.data);
            });
        }
    };
    const deleteTheFile = () => {
        if(file) {
        dispatch({ type: 'DELETE_UPLOAD', payload : { field, input: { fileId : file.fileId} } });
        if(onFileChange) { onFileChange(field); }
    }
}

const handleBlur = () => {
    setIsEditing(false); // Exit edit mode on blur
    if (editedFileName.length && editedFileName !== fileNameWithoutExt) {
        dispatch({ type: 'EDIT_UPLOAD', payload: { field, input: { ...file, fileName: `${editedFileName}${match ? `.${match[1]}` : ''}` } } });
        renameFile({ fileId : file.fileId, fileName : `${editedFileName}${match ? `.${match[1]}` : ''}` }) // call file rename API
        if (onFileChange) onFileChange(field); // Call file change handler
    } else if (!editedFileName.length) {
        setEditedFileName(`${fileNameWithoutExt}`)
    }
};

    return ( <div style={{ display: "flex", justifyContent: 'space-between', color: '#585858', fontSize: '0.77em', alignItems: 'center' }} key={file.fileId}>
            <div style={{ display: "flex", flex: 3, fontSize: '0.9em', maxWidth: '85%' }}>
            {
            // (isImageType(file.fileType)) ? 
            //   <>
            //   <Image
            //     src={`${process.env.REACT_APP_TRANSACTION_SERVER_URL}files/download/v3?customerName=${store.auth.customerId}&fileId=${file.fileId}&versionId=${file.version || 0}`}
            //     height={20}
            //     width={20}
            //     alt="Norway"
            //     withPlaceholder
            //   />
            //   </>
            //   : 
              <GetFileType />
              }
            {/* <span style={{ wordBreak: 'break-word', overflowWrap: 'break-word', alignSelf: 'center', marginLeft: '0.5em' }}>{file.fileName} {file.version > 0 ? <span style={{ fontSize: '0.7em' }}>(V{file.version})</span> : null}</span> */}
            {/* Editable FileName section */}
            {isEditing ? (
                    <div style={{display: 'flex', alignItems: 'center'}}>
                    <TextInput
                        value={editedFileName}
                        onChange={handleRenameValue}
                        onBlur={handleBlur} // Save changes on blur
                        autoFocus
                        styles={{ input: { padding: '4px', fontSize: '0.9em', marginLeft: '0.5em', width: '100%' } }}
                        sx={{
                            ["& .mantine-TextInput-input"]: { border: 'none', },
                        }}
                    />
                    {match && <span style={{ fontSize: '0.9em', marginLeft: '0.5em', minWidth: 30 }}>.{match[1]}</span>}
                    </div>
                ) : (
                    <span style={{ wordBreak: 'break-word', overflowWrap: 'break-word', alignSelf: 'center', marginLeft: '0.5em', display: 'flex', alignItems: 'center' }}>
                        {editedFileName} {match && <span style={{ fontSize: '0.9em', marginRight: '0.5em', minWidth: 30 }}>.{match[1]}</span>} {file.version > 0 && <span style={{ fontSize: '0.7em' }}>(V{file.version})</span>}
                        {!disabled && (
                            <Tooltip label="Rename file">
                                <IconPencil
                                    style={{ color: '#585858', cursor: 'pointer', marginLeft: '0.5em', }}
                                    width={'1.5em'} 
                                    height={'1.5em'}
                                    onClick={() => setIsEditing(true)} // Enable editing
                                />
                            </Tooltip>
                        )}
                    </span>
                )}
            </div>
            <div style={{ display: "flex", justifyContent: 'flex-end', alignItems: 'center', marginRight: '1em', flex: 2 }}><span style={{ opacity: 0.85, fontSize: '0.8em' }}>{moment(file.createdDate).format("DD MMM YYYY hh:mm A")}</span></div>
            <div style={{ display: "flex", justifyContent: 'flex-end', alignItems: 'center', flex: 1, fontSize: '0.8em' }}>
                <div style={{ display: 'flex', alignItems: 'center', marginRight: '1em' }} onClick={getHistory}>
                    <Popover position="bottom" withArrow shadow="md">
                        <Popover.Target>
                            <div style={{ display: 'flex', alignItems: 'center' }}><IconHistory style={{ color: '#005eff', cursor: 'pointer', marginRight: '0.25em' }} width={'1.3em'} height={'1.3em'} /><span>History</span></div>
                        </Popover.Target>
                        <Popover.Dropdown style={{padding : '0.35em'}}>
                                {history && history.map((file: any, index) => (<div key={`${file.file_id}_${index}`} style={{ padding: '0.5em', border: '0.01em solid #e9e9e9'}}><FileHistoryCard file={file} onDownload={onDownload} usersMap={usersMap} /></div>))}
                        </Popover.Dropdown>
                    </Popover>
                </div>
                {disabled ? null :
                    <div style={{ display: 'flex', alignItems: 'center', marginRight: '1em' }}>
                        <FileInput
                            id={file.fileId}
                            style={{ display: 'none' }}
                            onChange={(fl) => {
                                if (fl) uploadFileV2({ file: fl, fileId: file.fileId })
                                    .then(res => { dispatch({ type: 'EDIT_UPLOAD', payload: { field, input: { ...res.data, createdDate: moment() } } });  if(onFileChange) { onFileChange(field); }})
                            }}
                        />
                        <label htmlFor={file.fileId} style={{ display: 'flex', cursor: 'pointer' }}> <Tooltip label={'Revise'}><IconEdit style={{ color: '#005eff', cursor: 'pointer', marginRight: '0.25em' }} width={'1.3em'} height={'1.3em'} /></Tooltip></label>
                    </div>
                }
                <div style={{ display: 'flex', alignItems: 'center', marginRight: '0.5em' }} onClick={() => onDownload()}>
                <Tooltip label={'Download'}><IconDownload style={{ color: '#005eff', cursor: 'pointer', marginRight: '0.25em' }} width={'1.3em'} height={'1.3em'} /></Tooltip>
                </div>
                {disabled ? null : <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Popover position="bottom" withArrow shadow="md">
                        <Popover.Target>
                            <Tooltip label={'More'}><IconDotsVertical style={{ color: '#005eff', cursor: 'pointer', marginRight: '0.25em' }} width={'1.3em'} height={'1.3em'}/></Tooltip>
                        </Popover.Target>
                        <Popover.Dropdown>
                            <div style={{ display: 'flex', alignItems: 'center' }} onClick={deleteTheFile}>
                                <IconTrashX style={{ color: 'red', cursor: 'pointer', marginRight: '0.25em' }} width={'1.3em'} height={'1.3em'}/><span style={{ color: 'red'}}>Delete</span>
                            </div>
                        </Popover.Dropdown>
                    </Popover>
                </div>
                }
            </div>
        </div>
    )
}

export default memo(FileItemCard)