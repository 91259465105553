/* import React, { Dispatch, ReducerAction, memo, useEffect, useCallback, useMemo } from 'react'; */
import React, { Dispatch, ReducerAction, memo } from 'react';
/* import useAccordion from './UseAccordionHook';
 * import { DefaultList } from './DefaultAccordianRender';
 * import { putAsync } from 'csp-with-ts';
 * import { SelectedState } from './SelectedState';
 * import SelectedItem from './SelectedItem'; */
import { FormControl } from '@material-ui/core';
import { Select as MantineSelect } from '@mantine/core'

export interface IFormDropdownInputProps {
  field: string;
  dispatch: Dispatch<ReducerAction<any>>;
  state: string;
  label: string;
  options: string[] | { id: string; name?: string; }[];
  disabled?: boolean;
  description?: string;
  required?: boolean;
  size?: string;
  icon?: JSX.Element;
  coreField?: boolean;
  params?: {[K: string] : any} | null | undefined
  overrideSort?: boolean;
};

/* const FormDropdown = ({ field, dispatch, state, label, options, disabled }: IFormDropdownInputProps) => {
 *   const newOptions = useMemo(() => options.map(opt => ({ id: opt, name: opt })), [options]);
 *   const initialState = useCallback(() => state.length ? [state] : [], [state]);
 *   const [dropdownState, ByFormDropdown, relay] = useAccordion<{ id: string; name: string; }, 'id'>(DefaultList, { multiple: false, accordian: false, apply: false, reset: false }, initialState);
 *   useEffect(() => { if (dropdownState.length) { dispatch({ type: 'REPLACE_GENERIC', payload: { input: dropdownState[0], field } }); } }, [dropdownState]);
 *   useEffect(() => { putAsync(relay, { type: 'toggle', payload: false }); }, [state]);
 *   return !!disabled ? (<input disabled type="text" value={state} />) : (
 *     <ByFormDropdown
 *       options={newOptions}
 *       Render={SelectedItem}
 *       title={<SelectedState title={label} state={state} />}
 *     />
 *   )
 * }; */
const FormDropdown = ({ field, dispatch, state, label, options, disabled, description, required, size, icon, coreField, overrideSort }: IFormDropdownInputProps) => {
  const optionsData = overrideSort ? options.map(opt => ({ key: opt.id ? opt.id : opt.name ? opt.name : opt, value: opt.id ? opt.id : opt.name ? opt.name : opt, label: opt.name ? opt.name : opt })) : options.map(opt => ({ key: opt.id ? opt.id : opt.name ? opt.name : opt, value: opt.id ? opt.id : opt.name ? opt.name : opt, label: opt.name ? opt.name : opt })).sort((a, b) => a.label.localeCompare(b.label));
  return (
    <FormControl>
      <MantineSelect
        label={label}
        data={optionsData}
        disabled={disabled}
        value={state}
        placeholder={`Choose ${label}`}
        // description={description || "Some description of the field"}
        onChange={value => { dispatch({ type: 'REPLACE_GENERIC', payload: { input: value, field } }) }}
        required={required || false}
        size={size || 'sm'}
        sx={{
          ["& .mantine-Select-label"]: { fontSize: coreField ? '0.7em' : '0.8525em', color: '#585858', fontWeight: coreField ? 500 : 600 },
          ["& .mantine-Select-input"]: { marginTop: coreField ? '-4px' : '0.5em', marginBottom: coreField ? 0 : '0.5em', borderBottom: '0.0525em solid #005eff', borderTop: 0, borderRight: 0, borderLeft: 0, borderRadius: 0, paddingRight: coreField ? '1.8em' : '1.8575rem', '&[data-disabled]': { backgroundColor: '#FFF', border: 0, opacity: 1, color: '#585858', cursor: 'not-allowed' }, '&[data-with-icon]': { paddingLeft: coreField ? '1.8em' : '1.8575rem' } },
          ["& .mantine-Select-icon"]: { justifyContent: coreField ? 'flex-start' : 'center' }
        }}
        icon={icon}
        searchable
      />
    </FormControl>
  )
};

export default memo(FormDropdown);
