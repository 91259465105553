import { ActionIcon, CopyButton, Tooltip, rem } from '@mantine/core'
import { IconCheck, IconCopy } from '@tabler/icons-react'
import React from 'react'
import JsonViewer from './JsonViewer'
import { useStore } from '../../../models/ProvideModel';
import { ResponseObject } from '../../../models/admin/definitions/interface';
import { observer } from 'mobx-react-lite';

const ResponseContainer = ({ response} : { response: ResponseObject | undefined }) => {
 const store = useStore();
  return (
    <div style={{ overflowY: 'auto', maxHeight: '60%', width: '100%' }}>
                    <div style={{ background: '#fbe5ba', width: '100%', padding: '0.5em 1em', position: 'sticky', top: 0, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <span style={{ fontSize: '1.5em', fontWeight: 600, margin: 0, textAlign: 'left', color: '#2F3E6C' }}>Response</span>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            {response ? <span style={{marginRight: '0.5em'}}>
                                <CopyButton value={JSON.stringify(response)} timeout={2000}>
                                    {({ copied, copy }) => (
                                        <Tooltip label={copied ? 'Response copied' : 'Copy response'} withArrow position="right">
                                            <ActionIcon color={copied ? 'teal' : 'gray'} variant="subtle" onClick={copy}>
                                                {copied ? (
                                                    <IconCheck style={{ width: rem(16) }} />
                                                ) : (
                                                    <IconCopy style={{ width: rem(16) }} />
                                                )}
                                            </ActionIcon>
                                        </Tooltip>
                                    )}
                                </CopyButton>
                            </span> : null}
                            <span>{response?.time}</span>
                        </div>
                    </div>
                    <div style={{ display: 'flex', maxWidth: '100%', overflow: 'auto', padding: '1em' }}>
                        <JsonViewer jsonResponse={response} refresh={store.adminModule.loading} />
                        {/* <GenericTable data={response?.data || response?.data.data} /> */}
                        {/* { (response && response.data) && <JSONToField data={response.data}/>} */}
                    </div>
                </div>
  )
}

export default observer(ResponseContainer)