import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
    },
    image: {
        width: 80,
        // height: 80,
        borderRadius: 2
    },
    otherFile: {
        width: 80,
        height: 80,
        opacity: 0.4
    },
    fileName: {
        fontSize: '0.85em',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        textDecoration: 'none',
        width: 80,
        overflow: 'hidden',
        '&:hover' : {
            whiteSpace: 'normal' ,
        }
    }
}))

export default useStyles;