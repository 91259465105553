import React, { memo } from 'react';
import { Title, Divider } from '@mantine/core';
import { IGenericInputProps } from './GenericInput';

const Headline = memo(({ label, ...rest }: IGenericInputProps) => (
  <div>
    {rest.type === "headline-with-line" ?
     <Divider
       label={<Title order={2} size="h3"> {label} </Title>}
       labelPosition="center"
       size="sm" />
    : <Title order={2} size="h3">
      {label}
     </Title>
    }
  </div>));

export default Headline;
