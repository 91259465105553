import React from 'react';
import JSONPretty from 'react-json-pretty';
// import { JSONTree } from 'react-json-tree'
import { ResponseObject } from '../../../models/admin/definitions/interface'
import 'react-json-pretty/themes/monikai.css';
import { useStore } from '../../../models/ProvideModel';
import LoadingCircle from '../loading/LoadingCircular'
import { observer } from 'mobx-react-lite';

const theme = {
    scheme: 'monokai',
    author: 'wimer hazenberg (http://www.monokai.nl)',
    base00: '#272822', /* base background color */
    base01: '#383830',
    // other colors...
};

const JsonViewer: React.FC<{ jsonResponse: ResponseObject | undefined, refresh: boolean }> = ({ jsonResponse }) => {
    const store = useStore();
    // React.useEffect(() => { console.log('remounted component')}, [refresh])
    const [data, setData] = React.useState<ResponseObject | undefined>(undefined)

    React.useEffect(() => {
        // if (store.adminModule.loading) {
            setData(undefined);
        // }
    }, [store.adminModule.loading, store.adminModule.currentRequest])

    return (
        <div style={{width: '100%'}}>
            {
            !store.adminModule.loading ? 
            // false ?
            <JSONPretty
                id="json-pretty"
                data={jsonResponse}
                // data={data}
                space={4}
                themeClassName="JSONPretty.monikai"
                theme={theme}
            /> : <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <span>Execution in progress.. Please wait</span>
                <LoadingCircle />
                </div>
                 }
            {/* <JSONTree data={data} theme={theme}/> */}
        </div>
    );
};

export default observer(JsonViewer);
