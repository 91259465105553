import { types, flow, Instance, resolveIdentifier, getRoot } from 'mobx-state-tree'
import { getAllAdminAPIs, getAllCustomers } from '../../api/Auth';
import { LateStoreModel } from '../DataStore';


export interface IOption {
    id: string;
    name: string;
  }


const Field = types.model('field',{
    id: types.identifier,
    name: types.string,
    type: types.string,
    mandatory: types.boolean,
    multiselect: types.maybeNull(types.boolean),
    staticOptions: types.maybeNull(types.array(types.frozen<IOption>())) || [],
    endpoint: types.maybeNull(types.string),
    default: types.maybeNull(types.union(types.boolean, types.string, types.number)),
    readOnly: types.maybeNull(types.boolean),
    allowedTypes: types.maybeNull(types.array(types.string)),
    dependentOn: types.maybeNull(types.array(types.string))
})

const Request = types.model('request', {
    id: types.identifier,
    title: types.string,
    description: types.maybeNull(types.string),
    groupId: types.string,
    groupTitle: types.string,
    url: types.string,
    method: types.string,
    input: types.maybeNull(types.string),
    parameters: types.array(Field),
    meta: types.map(types.frozen())
})


export const AdminModule = types.model('adminModule', {
    requests :  types.array(Request),
    currentRequest: types.safeReference(Request),
    customerId: types.optional(types.string, ''),
   // customerName: types.maybeNull(types.string),
    error: types.maybeNull(types.string),
    customers: types.array(types.frozen<IOption>()) || []
})
.volatile(_ => ({ loading: false }))
.actions(self => ({
    setError() { self.error = null }
}))
.views(self => ({
    getAPIAxiosConfig() {
        return { url: self.currentRequest?.url, input: self.currentRequest?.input, method: self.currentRequest?.method }
    },
    get customerName() {
        return self.customers.find(c => c.id === self.customerId)?.name || "";
    }
}))
.actions(self => ({
    getApiList : flow(function* () {
        if(self.loading) {return;}
        self.loading = true;
        const customer = (getRoot(self) as Instance<typeof LateStoreModel>).auth.customerId
        try {
            (getRoot(self) as Instance<typeof LateStoreModel>).adminModule.setCustomer(customer)
            const customers = yield getAllCustomers()
            self.customers = customers
            const data = yield getAllAdminAPIs()
            self.requests.clear();
            data.map((api : any) => {
                const { id, title, description, group_id, group_title,  url, method, input, parameters, status, meta } = api
                if(status === 'Active')
                self.requests.push({id, title, description, groupId: group_id, groupTitle: group_title,  url, method, input, parameters, meta })
            })
            // console.log('dataaa...l',data)
            // self.requests.push([...data])
        }catch (err) {

            self.error = err.message;
            setTimeout(() => {
                self.setError()
            }, 5000);
            throw err;
        }finally {
            self.loading = false;
        }
    }),
    getAPIData(id: string) {
        return self.requests.find(api => api.id === id)
    },
    updateLoading() {
         self.loading = !self.loading
    },
    setCustomer(customerId: string) {
        self.customerId = customerId;
    }
}))
.actions(self => ({
    setCurrentRequest(id: string | undefined) {
        if (!id && self.currentRequest?.id) {
            // self.currentRequest = null
        }
        self.currentRequest = resolveIdentifier(Request, self.requests, id || "");
    }
})).actions(self => ({
    afterAttach() {
        self.customerId = (getRoot(self) as Instance<typeof LateStoreModel>).auth.customerId
    }
}))

export type IAdminModule = typeof AdminModule.Type;
export interface IRequest extends Instance<typeof Request> {};