import React, { useMemo } from 'react';
import TitleCell from '../../components/requests/cells/Title';
import DateCell from '../../components/requests/cells/Date';
import StatusCell from '../../components/requests/cells/Status';
import PriorityCell from '../../components/requests/cells/Priority';
import UsersCell from '../../components/requests/cells/Users';
import { IUser } from '../../models/Ticket';
import { MRT_ColumnDef, MRT_DensityState, MRT_SortingFn, MRT_TableOptions, } from 'mantine-react-table';
import Tags from '../../components/requests/cells/Tags';
import { MultiSelect } from '@mantine/core';
import { useStore } from '../../models/ProvideModel';
import { compose, flatten, map, toSet, transduce } from 'transducist';
import { observer } from 'mobx-react-lite';
import { Instance } from 'mobx-state-tree';
import { LateStoreModel } from '../../models/DataStore';
import CustomHeader from '../../components/requests/cells/CustomHeader';

// TODO: Refactor this somewhere
const TagsFilter = observer(({ setFilterValue, filterValue }: { setFilterValue: (updater: any) => void; filterValue?: string[] | undefined }) => {
  const store = useStore();
  return (
    <MultiSelect
      data={store.ticket.tags.slice()}
      placeholder="Filter by tags"
      nothingFound="Nothing found"
      searchable
      value={filterValue || []}
      onChange={val => {
        setFilterValue(() => val);
      }}
    />);
});

const assigneeFilter = (row, id, filterValue) => {
  if (filterValue.length < 2) {
    return row.getValue(id).includes(filterValue);
  }
  return filterValue.some(filt => row.getValue(id).includes(filt));
};

export interface BaseTicketDetails {
  id: string;
  name: string;
  status: string;
  priority: string;
  priorityTitle: string;
  createdAt: string;
  description: string;
  dueDate: string;
  assignee: IUser[];
  createdBy: IUser;
  lastUpdated: string;
  statusTitle: string;
  statusCategory: string;
  requestTypeTitle: string;
  taskId: string;
  unread?: boolean;
  delayed?: string;
};

export interface RequestTicketDetails extends BaseTicketDetails {
  [K: string]: any;
};

const assigneeAccessorFn = (row) => row.assignee.map(({ id, name }) => name || id).join(',');
const watchersAccessorFn = (row) => row.watchersWithNames.map(({ id, name }) => name || id).join(',');
const dueDateAccessorFn = (row) => {
  if (!row.dueDate) return ''
  const sDay = new Date(row.dueDate);
  sDay.setHours(0, 0, 0, 0);
  return sDay;
}
const startDateAccessorFn = (row) => {
  if (!row.startDate) return ''
  const sDay = new Date(row.startDate);
  sDay.setHours(0, 0, 0, 0);
  return sDay;
}
const dateAccessorFn = (row) => {
  const sDay = new Date(row.createdAt);
  return sDay;
};
const lastUpdatedDateAccessorFn = (row) => {
  const sDay = new Date(row.lastUpdated);
  return sDay;
};
const tagsAccessorFn = (row) => row.tags.join(',');


const prioritySortFn: MRT_SortingFn<RequestTicketDetails> = (rowA, rowB, _) => {
  const rowANum = Number.parseInt(rowA.original.priority.match(/[0-9]+/g)?.join() || "");
  const rowBNum = Number.parseInt(rowB.original.priority.match(/[0-9]+/g)?.join() || "");
  if (rowANum === rowBNum) {
    if (Number.isNaN(rowANum)) { return rowA.original.priority.localeCompare(rowB.original.priority); }
    return 0;
  }
  if (Number.isNaN(rowANum)) { return 1; }
  if (Number.isNaN(rowBNum)) { return -1; }
  return rowANum - rowBNum;
};

const columnsDef: MRT_ColumnDef<RequestTicketDetails>[] = [
  {
    accessorKey: "unread",
    id: "unread",
    header: "unread"
  },
  {
    /* accessorFn: (row) => `${row.name}`, */
    id: 'title',
    accessorKey: 'name',
    /* id: 'name', */
    header: 'Title',
    size: 400,
    /* maxSize: 300, */
    enableClickToCopy: false,
    enableColumnFilterModes: false,
    Cell: TitleCell,
    Header: CustomHeader,
  },
  {
    id: 'status',
    accessorKey: 'statusTitle',
    enableClickToCopy: false,
    header: 'Status',
    size: 150,
    filterVariant: 'multi-select',
    enableColumnFilterModes: false,
    Header: CustomHeader,
    Cell: StatusCell,
  },
  {
    id: "assignee",
    accessorFn: assigneeAccessorFn,
    header: 'Assigned To',
    size: 150,
    enableColumnFilterModes: false,
    filterVariant: 'multi-select',
    filterFn: assigneeFilter,
    //mantineFilterSelectProps: {
    //data: assigneeOptions as readonly SelectItem[],
    //},
    Header: CustomHeader,
    Cell: UsersCell,
    mantineFilterMultiSelectProps: ({ column }) => ({
      data: Array.from<string>(transduce(column.getFacetedUniqueValues(),
        compose(
          map(([v]) => v.split(',')),
          flatten()
        ),
        toSet()
      )).sort()
    }),
  },
  {
    id: "priority",
    accessorKey: 'priorityTitle', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
    enableClickToCopy: false,
    header: 'Priority',
    size: 100,
    filterVariant: 'multi-select',
    enableColumnFilterModes: false,
    Header: CustomHeader,
    Cell: PriorityCell,
    sortingFn: prioritySortFn,
  },
  {
    id: 'type',
    accessorKey: 'requestTypeTitle', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
    enableClickToCopy: false,
    header: 'Request Type',
    size: 100,
    filterVariant: 'multi-select',
    enableColumnFilterModes: false,
    Header: CustomHeader,
  },
  {
    id: "tags",
    accessorFn: tagsAccessorFn,
    header: "Tags",
    size: 200,
    Header: CustomHeader,
    Cell: Tags,
    Filter: ({ column: { getFilterValue, setFilterValue } }) => (
      <TagsFilter
        filterValue={getFilterValue() as string[] | undefined}
        setFilterValue={setFilterValue}
      />),
    enableColumnFilterModes: false,
    filterFn: assigneeFilter,
  },
  {
    accessorFn: startDateAccessorFn,
    id: 'startDate',
    header: 'Start Date',
    filterVariant: 'date-range',
    sortingFn: 'datetime',
    enableColumnFilterModes: false, //keep this as only date-range filter with between inclusive filterFn
    Header: CustomHeader,
    Cell: DateCell,
    size: 200,
  },
  {
    accessorFn: dueDateAccessorFn,
    id: 'dueDate',
    header: 'Due On',
    filterVariant: 'date-range',
    sortingFn: 'datetime',
    enableColumnFilterModes: false, //keep this as only date-range filter with between inclusive filterFn
    Header: CustomHeader,
    Cell: DateCell,
    size: 200,
  },
  {
    accessorFn: lastUpdatedDateAccessorFn,
    id: 'lastUpdated',
    header: 'Last Updated On',
    filterVariant: 'date-range',
    sortingFn: 'datetime',
    enableColumnFilterModes: false, //keep this as only date-range filter with between inclusive filterFn
    // Cell: ({ cell }) => cell.getValue<Date>()?.toLocaleDateString(), //render Date as a string
    Header: CustomHeader, //custom header markup
    Cell: DateCell,
  },
  {
    id: "createdBy",
    accessorKey: 'createdBy.name', //hey a simple column for once
    header: 'Created By',
    filterVariant: 'multi-select',
    size: 150,
    enableColumnFilterModes: false,
    columnFilterModeOptions: ['fuzzy', 'contains', 'equals'],
    Header: CustomHeader,
    Cell: UsersCell,
  },
  {
    accessorFn: dateAccessorFn,
    id: 'createdAt',
    header: 'Created On',
    filterVariant: 'date-range',
    sortingFn: 'datetime',
    enableColumnFilterModes: false,
    Header: CustomHeader,
    Cell: DateCell,
    size: 200,
  },
  {
    id: 'delayed',
    header: 'delayed',
    accessorKey: 'delayed'
  },
  /* {
   *   id: "watchersWithNames",
   *   accessorFn: watchersAccessorFn,
   *   header: 'Watchers',
   *   size: 150,
     :   *   enableColumnFilterModes: false,
   *   filterVariant: 'multi-select',
   *   filterFn: assigneeFilter,
   *   //mantineFilterSelectProps: {
   *   //data: assigneeOptions as readonly SelectItem[],
   *   //},
   *   Cell: UsersCell,
   *   mantineFilterMultiSelectProps: ({ column }) => ({
   *     data: Array.from<string>(transduce(column.getFacetedUniqueValues(),
   *       compose(
   *         map(([v]) => v.split(',')),
   *         flatten()
   *       ),
   *       toSet()
   *     )).sort()
   *   })
   * }, */
];

export const defaultColumns: string[] = columnsDef.map(({ id }) => id!);

export const useColumnsDef: (store: Instance<typeof LateStoreModel>) => MRT_ColumnDef<RequestTicketDetails>[] = (store) => useMemo(() => {
  return store.ticket.columnsDef.length ?
    [...columnsDef,
    ...store.ticket.columnsDef,
    ]
    :
         columnsDef;
}, [store.ticket.columnsDef]);

export const useTableOptions: (store: Instance<typeof LateStoreModel>) => Partial<MRT_TableOptions<RequestTicketDetails>> = store => useMemo(() => Object.assign({
  initialState: {
    showColumnFilters: true,
    density: 'xs' as MRT_DensityState,
    showGlobalFilter: true,
    columnVisibility: store.ticket.columnsVisibility,
    ...(store.ticket.columnOrder.length ? { columnOrder: store.ticket.columnOrder.slice() } : {}),
  },
  onColumnOrderChange: (val: string[]) => { store.ticket.setColumnOrder(val); },
}, store.ticket.columnOrder.length ? { state: { columnOrder: store.ticket.columnOrder.slice() } } : {}), [store.ticket.loading, store.ticket.columnOrder.slice(), store.ticket.columnsVisibility]);

export const gridKeys = [
    "id",
    "name",
    "status",
    "priority",
    "priorityTitle",
    "createdAt",
    "description",
    "dueDate",
    "assignee",
    "createdBy",
    "lastUpdated",
    "statusTitle",
    "statusCategory",
    "requestTypeTitle",
    "taskId",
    "unread",
    "isStale",
    "tags",
    "path",
    "canEdit",
    "startDate",
    'delayed'
    /* "watchersWithNames", */
];
