import { makeStyles } from "@material-ui/core/styles";
import { Theme } from '@material-ui/core';

const useStyles = makeStyles<Theme>(() => {
  return {
    mainDiv: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      flexBasis: "auto",
      flexDirection: "column",
      background: "#FFFFFF",
    },
    commentsDiv: {
      width: "100%",
      background: "#F1F3F5",
      display: "flex",
      justifyContent: "center",
      flexBasis: "auto",
      flexDirection: "column"
    },
    handleContainer: (props: { isLg: boolean }) => ({
      alignItems: "center",
      height: "100%",
      overflow: "overlay",
      flexShrink: 1,
      // padding: "10px 20px"
    }),
    handleSecondContainer: (props: { isLg: boolean }) => ({
      width: "100%",
      overflow: "auto",
      marginBottom: "10px",
      minHeight: props.isLg ? "216px" : "unset",
      maxHeight: props.isLg ? "300px" : "unset"
    }),
    handleScrollLayer: {
      display: "flex",
      flexDirection: "column",
      flexShrink: 1,
      flexGrow: 1,
      overflow: "scroll",
      width: "100%",
      alignItems: "center",
      position: "relative"
    },
    titleContainer: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 0
    },
    titlediv: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      /* height: "59px", */
      justifyContent: "center"
    },
    workStatusdiv: {
      fontSize: "35px",
      fontWeight: "bold",
      color: "#242222"
    },
    fieldData: ({ isLg }: { isLg: boolean }) => ({
      display: "flex",
      flexDirection: "column",
      justifySelf: "center",
      marginBottom: "8px",
      paddingTop: "1em",
      maxWidth: isLg ? 800 : "80%",
      width: "100%"
    }),
    fieldDescription: {
      fontSize: 11
    },
    UnitName: (props: { isLg: boolean }) => ({
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "column"
    }),
    ActivityName: (props: { isLg: boolean }) => ({
      display: "flex",
      // justifyContent: "center",
      // marginLeft: "5px",
      alignItems: "center",
      fontSize: props.isLg ? "0.875em" : "22px",
      // color: "#0f0e0e",
      color: '#585858',
      marginBottom: 5,
      textTransform: "none",
      fontWeight: 700
    }),
    ActivityNameDisabled: (props: { isLg: boolean }) => ({
      display: "flex",
      // justifyContent: "center",
      // marginLeft: "5px",
      alignItems: "center",
      fontSize: props.isLg ? "0.875em" : "22px",
      color: "#B3B3B3",
      marginBottom: 5,
      textTransform: "none",
      fontWeight: 700
    }),
    closeDiv: {
      display: "flex",
      flexDirection: "column",
      height: "35px",
      justifyContent: "space-between",
      width: "100%",
      alignItems: "center"
    },
    close: {
      display: "flex",
      // fontFamily: "SourceSansPro",
      fontSize: "20px",
      color: "#005eff",
      textAlign: "center",
      flexDirection: "column",
      justifyContent: "center",
      cursor: "pointer",
      width: "60px",
      border: "2px solid rgb(0 94 255 / 53%)",
      height: "60px"
    },
    image: {
      height: 64,
      width: 64,
      marginBottom: "5px",
      marginTop: "2px",
      cursor: "pointer",
      "&:hover": {
        height: 72,
        width: 72,
        marginLeft: "0px",
      }
    },
    brokenImage: {
      height: 64,
      width: 64,
      marginBottom: "5px",
      marginTop: "2px"
    },
    imgClass: {
      maxHeight: 100,
      maxWidth: 100,
      marginBottom: "10px",
      cursor: "pointer",
      "&:hover": {
        maxHeight: 110,
        maxWidth: 110,
      }
    },
    submitCancelDiv: {
      display: "flex",
      justifyContent: "space-evenly",
      alignItems: "flex-end",
      textAlign: "center",
      width: "100%",
      paddingBottom: '1em'
    },
    submitCommentDiv: {
      display: "flex",
      justifyContent: "space-evenly",
      alignItems: "flex-end",
      width: "100%",
      // height: "2.5em",
      // background: "#A3AABA",
      padding: "5px, 10px !important",
    },
    commentArea: {
      fontSize: "1em",
      background: "#E9EAF3",
      width: "100%",
      // height: "100%",
      paddingRight: ".2em",
      // borderRadius: ".8em",
      borderTopRightRadius: "0px",
      borderBottomRightRadius: "0px",
    },
    commentText: {
      background: "#FFFFFF",
      width: "100%",
      height: "3em",
      alignItems: "baseline",
      opacity: 0.6,
      // borderRadius: ".8em",
      paddingLeft: "1em",
      borderTopRightRadius: "0px",
      borderBottomRightRadius: "0px",
      fontSize: "0.90em",
      "&:hover": {
        opacity: 1,
      },
      "&:focus": {
        opacity: 1,
      }
    },
    submitbutton: {
      display: "flex",
      flexGrow: 1,
      fontSize: "0.7em",
      color: "white",
      background: " #005eff",
      textAlign: "center",
      flexDirection: "column",
      justifyContent: "center",
      cursor: "pointer",
      fontWeight: 600,
      /* width: "60px", */
      maxWidth: "20%",
      // border: "2px solid",
      height: "33px",
      borderRadius: "0px",
      "&:hover": {
        backgroundColor: "#1a6fef",
        color: "#faf9f9"
      },
      "&:disabled": {
        backgroundColor: "#d1d1d1",
        color: "#808080"
      },
    },
    submitCommentButton: {
      display: "flex",
      height: "2.4em",
      flexGrow: 0,
      fontSize: "0.80em",
      color: "white",
      background: " #005eff",
      textAlign: "center",
      flexDirection: "column",
      justifyContent: "center",
      cursor: "pointer",
      borderRadius: "0",
      fontWeight: 600,
      // borderTopLeftRadius: "0px",
      // borderBottomLeftRadius: "0px",
      "&:hover": {
        backgroundColor: "#1a6fef",
        color: "#faf9f9"
      },
      "&:disabled": {
        backgroundColor: "#d1d1d1",
        color: "#808080"
      },
    },
    captionText: {
      fontSize: "0.8em",
      opacity: 0.6
    },
    noFormText: {
      color: '#585858',
      fontSize: "0.9em",
      paddingTop: 5
    },
    taskTitleDiv: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      // borderBottom: '0.01em solid #585858',
      width: '100%',
      padding: '0.5em 2em',
      position: "sticky",
      top: 0,
      background: "#F9F9F9",
      zIndex: 1,
      boxShadow: "0px 10px 10px -5px rgba(0,0,0,0.12)"
    },
    taskTitle: {
      fontSize: "1.025em",
      fontWeight: 600,
      color: '#585858',
      textDecoration: 'uppercase',
      padding: '0.25em'
    },
    taskDesc: {
      fontSize: "0.8em",
      color: '#585858',
      paddingTop: '0.4em'
    },
    newRequestPopupTitleDiv: {
      display: 'flex',
      justifyContent: 'space-between',
      background: '#FFF',
      border: '0.01em solid #999999',
    },
    newRequestPopupTitle: {
      color: '#585858',
      fontSize: "1em",
      fontWeight: 700,
      padding: '1em'
    },
    newRequestPopupClose: {
      fontSize: "1em",
      fontWeight: 600,
      color: "#EA3434",
      cursor: "pointer",
      padding: '1em',
    },
    closeHover: {
      "&:hover": {
        borderBottom: "1px solid #EA3434",
        // textDecoration: "underline"
      }
    },
    hiddenInput: {
      display: "flex",
      // alignItems: "center",
      justifyContent: "flex-start",
      background: "#F9F9F9",
      color: "#000",
      transform: "translateX(-100%)",
      padding: '1em',
      border: '1px solid red'
    },
    visibleInput: {
      transform: "translateX(0%)"
    },
    sample: {
      color: 'pink'
    },
    taskListDiv: (props: { showTasksList: boolean }) => ({
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      // borderBottom: '0.01em solid #585858',
      width: '100%',
      padding: props.showTasksList ? '0em' : '0.5em 0.8em',
      position: "sticky",
      top: 0,
      background: "#E7F5FF",
      zIndex: 1,
      boxShadow: "0px 10px 10px -5px rgba(0,0,0,0.12)"
    }),
    taskListItem: {
      display: 'flex',
      justifyContent: 'space-between',
      border: `0.0525em solid #D9D9D9`,
      borderRadius: '0.35em',
      width: '100%',
      margin: '0.25em 0em',
      padding: '0.3em',
      fontSize: '0.825em',
      background: '#FFF',
      "&:hover": {
        cursor: 'pointer',
        boxShadow: "0px 5px 10px -5px rgba(0,0,0,0.12)",
        // border: `0.0525em solid #005EFF`, 
      }
    },
    showHideAction: {
      display: 'flex',
      justifyContent: 'center',
      fontSize: '0.7em',
      cursor: 'pointer',
      width: '100%',
      // background: "#FFF",
      // border:"0.01em solid #E9E9E9",
      padding: 0,
      opacity: 0.7,
    },
    main: (props: { showTasksList: boolean }) => ({
      display: props.showTasksList ? 'none' : 'flex',
      flexDirection: 'column',
      width: 'inherit'
    }),
    arrow: (props: { showTasksList: boolean }) => ({
      width: '1.5em',
      transform: props.showTasksList ? 'rotate(0deg)' : 'rotate(180deg)'
    }),
    listArrow: (props: { showTasksList: boolean }) => ({
      // width: '1.5em',
      transform: 'rotate(270deg)'
    }),
    rejectMessage: {
      color: '#FA5252',
      fontSize: '0.7525em',
      alignSelf: 'flex-end',
      marginRight: '1.5em'
    },
    dropzoneRoot: {
      width: '100%',
      border: '0.075em dashed #ced4da',
      '&[data-accept]': {
        border: '0.075em dashed #005eff',
      }
    },
    requiredAsterisk: {
      textAlign: "right",
      color: "#fa5252",
      fontSize: "1em",
      fontWeight: 600,
      lineHeight: 1.55,
      fontFamily: "-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI emoji",
    },
  }
});

export default useStyles;
