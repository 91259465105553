import React, { useEffect, useState, useMemo, FunctionComponent, MemoExoticComponent, useCallback } from "react";
import { useLocation, useHistory, useParams } from "react-router";
import moment from "moment";
import { compose, transduce, map, filter, toArray, count } from "transducist";
import { ApolloClient } from "apollo-client";
import { NormalizedCacheObject } from "apollo-cache-inmemory";
import client from "../../utils/apolloClient";
import { GET_CHECKLIST_REPORTS, GET_CHECKLIST_REPORTS_HEADERS, GET_CHECKLIST_REPORTS_COUNT, GET_CHECKLIST_ALL_REPORTS } from "../../utils/queries";
import FatTable from "../../components/fatTable/FatTable";
import TableMultiselect from "../../components/fatTable/TableMultiselect";
/* import TableBoolean from "../../components/fatTable/TableBoolean"; */
import TableDate from "../../components/fatTable/TableDate";
import Paginate from "../../components/fatTable/Pagination";
import DefaultCells from "../../components/DefaultCells";
/* import BlockCell from "../../components/BlockCell"; */
import { useSingleValueURLParam } from "../../utils/hooks";
import LoadingSpinner from "../../components/loadingSkelaton/LoadingSpinner";
import { RenderedDate } from '../../components/table/Commonfunctions';
import useStyles from '../../components/fatTable/FatTableStyle'
import { useStore } from '../../models/ProvideModel';
import { useObserver } from "mobx-react-lite";
import ViewPdf from "../../components/fatTable/ViewPdf";
import SelectCheckbox from "../../components/fatTable/SelectCheckbox";
import { reaction } from "mobx";
import { getSnapshot, getEnv } from "mobx-state-tree";
import PageSelectCheckbox from "../../components/fatTable/PageSelectCheckbox";
import ChecklistReportsSummary from "../../components/ChecklistReportsSummary";
import ChecklistReportsPopup from "../../components/ChecklistReports/ChecklistReportsPopup";
import TableSnagInformation from "../../components/unitInfoSummaryComponent/ChecklistReportsSummaryInformation";
import * as Sentry from "@sentry/browser";
import { captureEventCallback } from "../../utils/utils";

/* import { sortingWithPrecedence } from "../../utils/utils"; */

const __DEFAULT = 100;

interface IQueryFilterParams {
  where: Record<string, any>;
  order_by: { [k: string]: "asc" | "desc" }[];
}
interface IQueryPageParams {
  limit: number;
  offset: number;
}

type IQueryParams = IQueryFilterParams & Partial<IQueryPageParams>;

export interface IColumnOptions {
  id: string;
  name: string;
  filter?: Function;
  Component?: FunctionComponent<{ [K: string]: any; }>;
  Cell?: MemoExoticComponent<(props: any) => JSX.Element> | FunctionComponent<{ [K: string]: any; }>;
  print?: boolean;
  value?: string;
  isVisible?: boolean;
  accessor?: string;
  minWidth?: number;
  headerObj?: string;
  excelExport?: Function;
  isExcelField?: boolean;
  data?: { id: string; name: string; }[];
  sort?: (a, b) => number;
}

export function useCustomQueryParameters(
  checklistReportColumns: IColumnOptions[],
  filters: URLSearchParams
) {
  // const { blockId } = useParams<any>();               // Use when block_id is a fk
  const [param, setParam] = useState<IQueryParams>();
  const store = useStore();
  useEffect(() => {
    const sort = filters.getAll("sort");
    const descSort = filters.getAll("sortDesc");
    let variables: IQueryParams = {
      // currently disabled because block relation is not there. Either
      // reactivate it then or directly use property_id field
      /* where: { block: { property_id: { _in: store.projectInfo.currentProject.properties.map(({ id }) => id) } } }, */
      where: { property_id: { _in: store.projectInfo.currentProject.properties.map(({ id }) => id) } },
      order_by: descSort.length === 0 && sort.length === 0 ? [{ report_key: "asc" }, { block_id: "asc" }] : [{ report_key: "asc" }],
    };
    for (const { id: field, filter } of checklistReportColumns) {
      if (sort.length > 0 && sort.includes(field)) {
        variables["order_by"].push({ [field]: "asc" });
      }
      if (descSort.length > 0 && descSort.includes(field)) {
        variables["order_by"].push({ [field]: "desc" });
      }
      const value = filters.getAll(field);
      if (value.length > 0) {
        if (filter) {
          variables["where"] = filter === snagBool ? {
            ...(variables["where"] || {}),
            ...filter.apply(null, value)
          } : {
              ...(variables["where"] || {}),
              [field]: { ...filter.apply(null, value) },
            };
        } else {
          if (value.length === 1) {
            const singleVal = value[0];
            variables["where"] = {
              ...(variables["where"] || {}),
              [field]: { _eq: singleVal },
            };
          } else {
            variables["where"] = {
              ...(variables["where"] || {}),
              [field]: { _in: [...value] },
            };
          }
        }
      }
    }
    setParam({ ...variables });
  }, [filters]);
  return param;
}


function delta(input1: string, input2: string): { _lt: string; _gt: string } {
  if (!(input1 && input2)) {
    throw new Error("date type filters must define a range");
  }
  const dates = [input1, input2].map((d) => moment(d));
  return {
    _lt: moment.max(...dates).endOf('day').toISOString(),
    _gt: moment.min(...dates).toISOString(),
  };
}

function range(input: string): { _gt?: number; _lt?: number } {
  const [clause, value] = input.split(/([0-9]+)/);
  switch (clause) {
    case "lt":
      return { _lt: Number.parseInt(value) };
    case "gt":
    default:
      return { _gt: Number.parseInt(value) };
  }
}

//          Doesn't work because attachment is an empty string instead of null
/* function bool(input: string): { _is_null: boolean } {
 *   return { _is_null: input === "true" };
 * } */

function bool(input: string): { _neq: string; } | { _eq: string; } {
  return input === "false" ? { _eq: "" } : { _neq: "" };
}

function snagBool(input: string): { snag_counts?: {}; _not?: { snag_counts: {} } } {
  return input === "false" ? { _not: { snag_counts: {} } } : { snag_counts: {} };
}

export const ChecklistReportsColumns: IColumnOptions[] = [
  /* { id: "block_id", value: "block", accessor: "block.id", name: "Block", Component: TableMultiselect, print: true, Cell: BlockCell, headerObj: "block", minWidth: 80, isExcelField: true, excelExport: (elt: any) => elt['block']['title'] }, */
  { id: "select", value: "report_key", name: "Select", Component: PageSelectCheckbox, Cell: SelectCheckbox, minWidth: 10 },
  { id: "block_id", value: "block_name", name: "Block", Component: TableMultiselect, minWidth: 80 },
  { id: "floor_idx", value: "floor_name", name: "Floor", Component: TableMultiselect, print: false, minWidth: 80 },
  // maybe have activity precedence sorting?
  /* { id: "precedence", value: "precedence", name: "Precedence", isVisible: false, Component: TableMultiselect, print: false, minWidth: 100, isExcelField: false, excelExport: (elt: any) => elt['precedence'] }, */
  { id: "activity_type_id", value: "activity_type_name", name: "Activity Name", Component: TableMultiselect, print: false, minWidth: 100 },
  { id: "unit_activity_title", name: "Activity Title", Component: TableMultiselect, print: true, minWidth: 80, isExcelField: true, excelExport: (elt: any) => elt['unit_activity_title'] },
  { id: "unit_id", value: "unit_name", name: "Unit Title", Component: TableMultiselect, print: false, minWidth: 80, },
  { id: "unit_type_id", value: "unit_type_name", name: "Unit Type", Component: TableMultiselect, print: false, minWidth: 80 },
  // add back when phase is put back on
  /* { id: "phase_id", name: "Phase", value: "phase_title", Component: TableMultiselect, minWidth: 80, }, */
  { id: "inspection_type", name: "Inspection Type", Component: TableMultiselect, print: false },
  { id: "report_date", name: "Date Created", filter: delta, Component: TableDate, Cell: RenderedDate, minWidth: 80 },
  { id: "report_key", name: "Report", print: false, Cell: ViewPdf, minWidth: 50 }
];
const ChecklistReportsPage = () => {
  const [data, setData] = useState<any[]>([]);
  const [headersData, setHeadersData] = useState<{}>({});
  const [totalCount, setTotal] = useState<number>(0);
  const [countLoading, setCountLoading] = useState<boolean>(false);
  const store = useStore();
  useEffect(() => {
    if (!client.client) {
      client();
    }
  }, [client]);
  const { search, ...location } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const { push, replace } = useHistory();
  const params = useCustomQueryParameters(ChecklistReportsColumns, searchParams);
  // probably no need to make this pdf exportable.
  const [print] = useSingleValueURLParam("view", "normal", searchParams);
  const [loading, setloading] = useState<boolean>(false)
  // enable phase when it's added.
  /* const { tower, spaceType, phase }: { tower?: string | undefined, spaceType?: string | undefined, phase?: string | undefined } = useParams<any>();
   * const pathParams = useMemo(() => spaceType && phase ? tower ? ({ "space_type_id": { "_eq": spaceType }, "phase_id": { "_eq": phase }, "block_id": { "_eq": tower } }) : ({ "space_type_id": { "_eq": spaceType }, "phase_id": { "_eq": phase } }) : ({}), [tower, spaceType, phase]); */
  const { phase }: { phase?: string | undefined } = useParams<any>();
  const pathParams = useMemo(() => phase ? ({ "phase_id": { "_eq": phase } }) : ({}), [phase]);
  const { fullScreenMode } = store.responsiveUtils;
  const classes = useStyles({ print });
  const [dataLoading, setDataLoading] = useState<boolean>(false)
  const columnsConfig = useMemo(
    () =>
      transduce(
        ChecklistReportsColumns,
        compose(
          // don't filter by print or path params
          filter(({ print: printV }) => (print === "print" ? !!printV : true)),
          /* filter(({ id }) => {
           *   switch (id) {
           *     case 'block_id': return !tower;
           *     case 'space_type_id': return !spaceType;
           *     case 'phase_id': return !phase;
           *     default: return true;
           *   }
           * }), */
          filter(({ isVisible }) => {
            return isVisible === undefined ? true : isVisible;
          }),
          filter(({ id }) =>
            headersData[id] ? (headersData[id].length > 0 ? true : false) : true
          ),
          map(({ id, name, sort, Component, Cell, isVisible, accessor, headerObj, minWidth, ...rest }) => ({
            name,
            Header:
              Component && print !== "print"
                ? Component
                : name,
            minWidth: minWidth || 150,
            id,
            accessor: accessor || id,
            headerObj,
            Cell: !!Cell ? Cell : DefaultCells,
            data: headersData[id] ? sort ? headersData[id].slice().sort(sort) : headersData[id] : null,
            ...rest
          }))
        ),
        toArray()
        /* [headersData, print, tower, phase, spaceType] */
      ),
    [headersData, print]
  );
  const [offset, setPage] = useSingleValueURLParam<number>(
    "page",
    0,
    searchParams,
    Number.parseInt,
    push,
    location
  );
  const [limit, setSize] = useSingleValueURLParam<number>(
    "pageSize",
    __DEFAULT,
    searchParams,
    Number.parseInt,
    push,
    location
  );
  useEffect(() => {
    if (client.client && store.projectInfo.currentProject?.properties?.length) {
      // if we property is not a foreign key, use this.
      /* .watchQuery({ query: GET_CHECKLIST_REPORTS_HEADERS, variables: { where: { block: { property_id: { _in: store.projectInfo.currentProject.properties.map(({ id }) => id) } }, ...pathParams } } }) */
      const query = (client.client as ApolloClient<NormalizedCacheObject>)!
        .watchQuery({ query: GET_CHECKLIST_REPORTS_HEADERS, variables: { where: { property_id: { _in: store.projectInfo.currentProject.properties.map(({ id }) => id) }, ...pathParams } } })
        .subscribe(({ data, loading }) => {
          if (!loading) {
            setHeadersData(
              ChecklistReportsColumns.reduce(
                (acc, { id, Component }) =>
                  Component
                    ? { ...acc, [id]: data[id] && data[id].length > 0 ? data[id] : null }
                    : acc,
                {}
              )
            );
            setDataLoading(true);
          }
        });
      return () => {
        query.unsubscribe();
      };
    }
    // pathParams will probably need to be a dependency.
  }, [pathParams]);
  // Issue the query
  useEffect(() => {
    if (client.client && params) {
      setloading(true);
      setCountLoading(true);
      const variables: IQueryParams =
        print === "print"
          ? params
          : { ...params, limit, offset: offset * limit };
      const query = (client.client as ApolloClient<
        NormalizedCacheObject
      >)!.watchQuery<Array<any>, IQueryParams>({
        query: GET_CHECKLIST_REPORTS,
        variables: { ...variables, where: { ...variables.where, property_id: { _in: store.projectInfo.currentProject.properties.map(({ id }) => id) }, ...pathParams } },
      }).subscribe(({ data, loading, errors }) => {
        if (errors) { console.error(errors); setloading(false); }
        else if (!loading) {
          if (!data["checklist_reports"].length) {
            const metadata = { customer: store.auth.customerId, user: store.auth.userId, path: store.params.path, filters: JSON.stringify(params.where), section: store.params.section, phase: store.params.phase, spaceType: store.params.spaceType };
            getEnv(store).mixpanel.track("NoData", { ...metadata });
            Sentry.withScope(captureEventCallback(metadata));
          }

          setData(data["checklist_reports"]);
          setloading(false);
        }
      });
      const queryCount = (client.client as ApolloClient<
        NormalizedCacheObject
      >).watchQuery({
        query: GET_CHECKLIST_REPORTS_COUNT,
        variables: { where: { ...params.where, property_id: { _in: store.projectInfo.currentProject.properties.map(({ id }) => id) }, ...pathParams } },
        errorPolicy: "all"
      }).subscribe(({ data, loading, errors }) => {
        if (!loading) {
          setTotal(data["meta"]["total"]["count"]);
          setCountLoading(false);
        } else if (errors) {
          console.error(errors);
          setCountLoading(false);
        }
      });
      return () => {
        query.unsubscribe();
        queryCount.unsubscribe();

      };
    }
  }, [params, offset, limit, print, pathParams]);

  const getAllReports = useCallback(() => {
    if (client.client && params) {
      return (client.client as ApolloClient<NormalizedCacheObject>).query({
        query: GET_CHECKLIST_ALL_REPORTS,
        variables: { where: { ...params.where, property_id: { _in: store.projectInfo.currentProject.properties.map(({ id }) => id) }, ...pathParams } }
      });
    }
    else { return Promise.resolve(); }
  }, [params, pathParams]);

  useEffect(() => reaction(() => [store.params.spaceTypes.length, store.currentTower, store.params.phases.length, store.params.section, store.params.phase, store.params.screen, store.params.graphType, store.params.spaceType, store.menus, store.menus.blocks.length], (_) => {
    if (store.params.phase.toLowerCase() === "none" && store.params.phases.length) {
      const changes = {
        phase: store.params.phases[0],
      };
      replace(store.params.toPath({ ...getSnapshot(store.params), ...changes, section: 'checklist-reports' }));
    }
  }), []);

  const mobilefullScreenMode = (fullScreenMode) => {
    return !fullScreenMode ? classes.tablePanelSm : classes.tablePanelFs
  }

  useEffect(() => {
    store.exportTableFilters.setColumnConfig(columnsConfig)
  }, [dataLoading])

  useEffect(() => () => {
    store.exportTableFilters.clearTableFiltersData()
  }, [])

  useEffect(() => {
    if (store.checklistMultiSelect.checklistItems.length === 0) {
      store.checklistMultiSelect?.changeSelectedState(false)
    }
  }, [store.checklistMultiSelect.checklistItems.length]);

  return useObserver(() => (
    <div
      style={{
        backgroundColor: "#faf9f9",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        display: "flex",
        flexGrow: 1,
        flexBasis: "auto",
        flexShrink: 1,
      }}
    >
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "row",
          flexBasis: "auto",
          // overflow: "scroll",
          flexGrow: 1,
          flexShrink: 1,
          // height:"70vh"
        }}
      >
        <div className={store.responsiveUtils.currentViewport.isLg ? classes.tablePanel : mobilefullScreenMode(store.responsiveUtils.fullScreenMode)}>
          <TableSnagInformation totalCount={totalCount} pathParams={pathParams} params={params} loading={!countLoading} />
          {!loading && (
            <ChecklistReportsPopup loading={countLoading} disabled={store.checklistMultiSelect.disabled} />
          )}
          {loading ? <LoadingSpinner bgColor={'#faf9f9'} width={(print !== "print") ? "80%" : "100%"} /> : <FatTable columns={columnsConfig} data={data} print={print} />}
          {print !== "print" && !countLoading && (
            <div style={{ display: "flex" }}>
              {!loading && (
                <ChecklistReportsSummary count={totalCount} loading={countLoading} getData={getAllReports} />
              )}
              <Paginate
                totalCount={totalCount}
                page={offset}
                size={limit}
                setPage={setPage}
                setSize={setSize}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  ));
};

export default ChecklistReportsPage;
