import { makeStyles, Theme } from "@material-ui/core/styles";

export interface INotifyItemStyle {
    state : boolean;
}

const useStyles = makeStyles<Theme, INotifyItemStyle>((theme: Theme) => ({
  item: (props) => ({ 
    display: 'flex', 
    padding: '0.5em 0.5em', 
    justifyContent: 'flex-start', 
    alignItem: 'center', 
    fontSize: '0.875em',
    "&:hover": {
      }
}),
notiIcon: {
    "&:hover": {
      color: "#005eff",
      cursor: 'pointer'
    }
  },
}));

export default useStyles;
