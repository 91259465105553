import React from 'react';
import eerorImg from '../../../../assets/images/brwsrSupprt/errorImage.png';

const quotes = [
    "A goal without a plan is just a wish",
    "Failing to plan is planning to fail",
    `“A good plan today is better than a perfect plan tomorrow.” – George S. Patton`,
    `“Plans are of little importance, but planning is essential.” – Winston Churchill`,
    `“Give me six hours to chop down a tree and I will spend the first four sharpening the axe.” – Abraham Lincoln`,
    `“Someone’s sitting in the shade today because someone planted a tree a long time ago.” – Warren Buffett`
]

const getRandomIndex = () =>  Math.floor(Math.random() * quotes.length)

const NoDataForTargets = () => {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 450 }}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <img src={eerorImg} 
                // height={store.responsiveUtils.currentViewport.isLg ? "" : 95} width={store.responsiveUtils.currentViewport.isLg ? "" : 74} 
                />
                <span style={{ fontSize: '1em', fontStyle: 'italic'}}>{quotes[getRandomIndex()]}</span>
                <span style={{ fontSize: '0.8em', }}>No activities planned for selected period.</span>
            </div>
        </div>
    )
}

export default NoDataForTargets