import React, { useMemo } from "react";
import { useLocation, useHistory } from "react-router";
import ResetIcon from "../../assets/images/infoPanel/CloseIcon.svg";
import useStyles from '../fatTable/FatTableStyle'
import { Column } from 'react-table';
import moment from "moment";
import { getStatusName } from '../../utils/utils';
import { useObserver } from "mobx-react-lite";


export interface IFatTableProps<T extends object = {}> {
    columns: Array<Column<T>>;
    data?: T[];
    print?: string;
    itemSelected: any;
    name: string;
    id: string;
    open: boolean
};

const DropDown = ({ columns, itemSelected, name, id, open }: IFatTableProps) => {
  const classes = useStyles({});
    const location = useLocation();
    const { search } = useMemo(() => location, [
        location.search,
        location.pathname,
    ]);
    const searchParam = useMemo(() => new URLSearchParams(search), [search]);
    const { push } = useHistory();
    const dates = [
        { name: "Last 7 days", id: "today-7", value: [moment(), moment().startOf('day').subtract(8, 'day')] },
        { name: "This Week", id: "today-week", value: [moment(), moment().startOf('week').subtract(1, 'day')] },
        { name: "Last week", id: "week-week", value: [moment().subtract(1, 'weeks').endOf('week').add(1, 'day'), moment().subtract(1, 'weeks').startOf('week').subtract(1, 'day')] },
        { name: "Last 30 days", id: "30", value: [moment(), moment().startOf('day').subtract(31, 'day')] },
        { name: "This Month", id: "today-month", value: [moment(), moment().subtract(1, 'months').endOf('month')] },
        { name: "Last Month", id: "month-month", value: [moment().subtract(1, 'months').endOf('month').add(1, 'day'), moment().subtract(2, 'months').endOf('month')] },
        { name: "This quarter", id: "quarter", value: [moment(), moment().startOf('quarter').subtract(1, 'day')] },
        { name: "Last quarter", id: "90", value: [moment().subtract(1, 'quarter').endOf('quarter').add(1, 'day'), moment().subtract(1, 'quarter').startOf('quarter').subtract(1, 'day')] },
    ];
    const resetParticularItem = (id: string, element: string) => {
        const list = searchParam.getAll(id)
        searchParam.delete(id)
        list.forEach(val => val === element ? null : searchParam.append(id, val))
        push({ ...location, search: searchParam.toString() });
    }
    const resetHandler = (id: string) => {
        searchParam.delete(id);
        push({ ...location, search: searchParam.toString() });
    }

    //Regex for format - '4 Mar 1991'
    var date_regex = /^(([0-9])|([0-2][0-9])|([3][0-1]))\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s\d{4}$/
    //Function to format custom date picker
    const formatCustomDates = (input_array: any) => {
        var from_date = "", to_date = ""
        from_date = input_array[1]
        to_date = input_array[0]
        return  <span className={classes.columnText1}>{from_date} to {to_date}</span>
    }

    return useObserver(() => (
        <div>
            {Date.parse(itemSelected[0]) ? itemSelected[0].length > 11 ?
                dates.map((option: any, i) =>
                    <div>
                        {(itemSelected[0].slice(0, 10) === option.value[0].subtract(1, 'day').format('YYYY-MM-DD')) &&
                            (itemSelected[1].slice(0, 10) === option.value[1].format('YYYY-MM-DD')) ?
                            open && <div style={{ display: "-webkit-box", flexWrap: "wrap", marginBottom: "12px" }}>
                                <div key={name} className={classes.filterSecondRectangle}>
                                    <span className={classes.columnText1}>{option.name} - ({option.value[1].add(1, 'day').format("DD MMM YY")} to {option.value[0].add(1, 'day').format("DD MMM YY")})</span>
                                    <img src={ResetIcon} alt="Reset" onClick={() => resetHandler(id)} style={{ cursor: "pointer", marginRight: "3px" }} />
                                </div>
                            </div>
                            : null}
                    </div>
                )
                :
                open && <div style={{ display: "-webkit-box", flexWrap: "wrap", marginBottom: "12px" }}>
                    <div className={classes.filterSecondRectangle}>
                        {/* <span className={classes.columnText1}>{itemSelected[1]} to {itemSelected[0]}</span> */}
                        {itemSelected.map((item, idx, itemSelectedCopy) => {
                            if(date_regex.test(item) && idx === 0) { return formatCustomDates(itemSelectedCopy) } // format custom date-picker string
                            else if(date_regex.test(item) && idx > 0) { return } //ignore second date-picker date string
                            else { return  <span key={idx} className={classes.columnText1}>{item}</span>} // anything other than custom date-picker
                        })}
                        <img src={ResetIcon} alt="Reset" onClick={() => resetHandler(id)} style={{ cursor: "pointer", marginRight: "3px" }} />
                    </div>
                </div>
                :
                open && <div style={{ display: "-webkit-box", flexWrap: "wrap", marginBottom: "12px" }}>{itemSelected.map((element, i) => {
                    return (columns.map(data => {
                        if (data.name === name) {
                            return (data.data?.map(key => {
                                if (element.toString() === key?.id?.toString()) {
                                    return <div key={name} className={classes.filterSecondRectangle}>
                                        <span className={classes.columnText1}>{key.name ? key.name :
                                            (key.id === "1_planned" || key.id === "3_active" || key.id === "8_done") ? getStatusName(key.id.toString()) : key.id}</span>
                                        <img src={ResetIcon} alt="Reset" onClick={() => resetParticularItem(id, element)} style={{ cursor: "pointer", marginRight: "3px" }} /></div>
                                }
                                else if (element.toString() === key?.block?.id?.toString()) {
                                    return <div key={name} className={classes.filterSecondRectangle}>
                                        <span className={classes.columnText1}>{key.block.name ? key.block.name : key.block.id}</span>
                                        <img src={ResetIcon} alt="Reset" onClick={() => resetParticularItem(id, element)} style={{ cursor: "pointer", marginRight: "3px" }} /></div>
                                }
                            })
                            )
                        }
                    }))
                })}</div>}</div>
    ));
};

export default DropDown;
