import React from 'react'
import { useStore } from '../models/ProvideModel';
import { useHistory } from 'react-router';
import { Link } from '@material-ui/core';
import { getSnapshot } from 'mobx-state-tree';

const SnagCell = (props) => {
  const { push } = useHistory()
  const store = useStore();
  const params = store.params;
  const { unit_id, block_id, activity_type_id, unit_activity_id } = props.row.original;
  // const Urlpath: any = store.params.toPath({ ...getSnapshot(store.params), section: "snags" }) + `?block_id=${block_id}&unit_id=${unit_id}&activity_type_id=${activity_type_id}`
  const Urlpath: any = store.params.toPath({ ...getSnapshot(store.params), section: "snags" }) + `?block_id=${block_id}&unit_id=${unit_id}&activity_type_id=${activity_type_id}&unit_activity_id=${unit_activity_id}`
  const onSnagClick = () => {
    document?.getElementById(`${unit_id}_${block_id}_${activity_type_id}`)?.removeAttribute("href");
    push(Urlpath);
  }
  if (!props.row.original[props.column.value]) {
    return (<div> — </div>)
  }
  else {
    const { count_open, count_closed, count_for_review } = props.row.original[props.column.value]

    return (
      <div>
        <Link id={`${unit_id}_${block_id}_${activity_type_id}`}
          href={`${window.location.href.split('/', 3).join('/')}${Urlpath}`}
          target="popup"
          style={{ textAlign: "left", color: "#005eff" }}
          onClick={() => { onSnagClick(); return false }}
        >
          {`${count_open} / ${Number.parseInt(count_open) + Number.parseInt(count_closed) + Number.parseInt(count_for_review)}`}
        </Link>
      </div>
    )
  }
}

export default SnagCell
