import React, { memo } from "react";
import { TicketStatusCategories } from "../../../models/enums";
import { materialStatusColours } from "../../../utils/constants";
import {
  MRT_Cell, MRT_Row,
} from 'mantine-react-table';

const RequestStatus = ({ row: { original } }: { row: MRT_Row<any>; cell: MRT_Cell<any>; }) => (
  <div style={{
    display: 'flex',
    height: "100%",
    alignItems: "center",
    flexDirection: 'row',
    fontFamily: "Source Sans Pro, sans-serif !important",
  }}>
    <div>
      <div style={{
        width: 10,
        height: 10,
        backgroundColor: materialStatusColours[original["statusCategory"]]?.bg || "white",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        boxSizing: "content-box",
        border: `1px solid ${materialStatusColours[original["statusCategory"]]?.border || "white"}`,
        borderRadius: "50%"
      }}
      >
      </div>
    </div>
    <div style={{ marginLeft: "1em", display: 'flex', flexDirection: 'column', alignSelf: 'start' }}>
      <span style={{ fontSize: "0.875em", fontWeight: 500 }}>{original["statusTitle"]?.toUpperCase()}</span>
    </div>
  </div>
);

export default RequestStatus;
