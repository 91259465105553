import React, { memo } from 'react';
import { Radio } from '@mantine/core';
import { IFormDropdownInputProps } from '../filter/FormDropdown';

const labelStyle = { display: 'inline-block', cursor: 'default', lineHeight: '1.55', fontSize: '0.8525em', fontWeight: 600, color: '#585858', letterSpacing: '0.01071em', fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji' }

const RadioInput = ({ field, dispatch, state, label, options, disabled, description, required }: IFormDropdownInputProps) => (
  <Radio.Group
    value={state}
    onChange={val => { dispatch({ type: 'REPLACE_GENERIC', payload: { input: val, field } }); }}
    label={<span style={labelStyle}>{label}</span>}
    description={description || ""}
    required={!!required}
  >
    {options.map(opt =>
      <Radio
        style={{ marginTop: '0.5em' }}
        key={opt.id}
        value={opt.id}
        label={<span style={{ fontSize: '0.75em', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{opt.name || opt.id}</span>}
        disabled={!!disabled}
      />
    )}
  </Radio.Group>
);

export default memo(RadioInput);
