import React from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from "../../../models/ProvideModel";

const Header = observer(({ id, header }: { id: string; header: string; }) => {
  const store = useStore();
  return (<em>{store.ticket.columnOverridenLabels[id] || header}</em>);
});

const CustomHeader = ({ column }) => <Header
  header={column.columnDef.header}
  id={column.columnDef.id}
/>;

export default CustomHeader;
