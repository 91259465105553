import React, { PropsWithChildren, useEffect, ReactNode, useMemo } from 'react';
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_Row,
  MRT_TableInstance,
  MRT_TableOptions,
  useMantineReactTable,
} from 'mantine-react-table';
import { observer } from 'mobx-react-lite';

export interface IMantineTableProps<T extends { [K: string]: any; }> {
  data: T[];
  rowCallback: (row: MRT_Row<T>) => any;
  columns: MRT_ColumnDef<T>[];
  ToolbarComponent: (props: { table: MRT_TableInstance<T> }) => ReactNode;
  useFilterListeners: (table: MRT_TableInstance<T>) => void;
  setRowCount(count: number): void;
  tableOptions?: Partial<MRT_TableOptions<T>>;
};

const defaultTableOptions: <T extends { [K: string]: any; }>() => Partial<MRT_TableOptions<T>> = () => ({
  enableColumnFilterModes: true,
  enableColumnOrdering: true,
  enableFacetedValues: true,
  enableGrouping: false,
  enablePinning: false,
  enableRowActions: false,
  enableRowSelection: false,
  enableStickyHeader: true,
  enableStickyFooter: false,
  enableMultiSort: true,
  initialState: {
    showColumnFilters: true, density: 'xs',
    showGlobalFilter: true,
    /* columnFilters: specialFilters.filter(({ isActive }) => isActive), */
    columnVisibility: { unread: false }
  },
  // Highlighting doesn't work
  /* memoMode: 'cells', */
  enablePagination: false,
  mantinePaginationProps: {
    variant: 'mantine',
  },
  positionToolbarAlertBanner: 'bottom',
  /* renderRowActionMenuItems: () => (
   *   <>
   *     <Menu.Item icon={<IconUserCircle />}>View Profile</Menu.Item>
   *     <Menu.Item icon={<IconSend />}>Send Email</Menu.Item>
   *   </>
   * ), */
  enableToolbarInternalActions: false,
  /* renderToolbarInternalActions: () => (<></>), */
  mantinePaperProps: () => ({
    sx: {
      padding: '0em 1em',
    }
  }),
  mantineTableContainerProps: () => ({
    sx: {
      height: "100%",
    }
  }),
  /* renderTopToolbarCustomActions: () => {
   *   return (
   *     <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
   *       {children}
   *     </div>
   *   );
   * }, */
  mantineTopToolbarProps: () => ({
    sx: {
      display: 'flex',
      "> div": { alignItems: "flex-end" }
    }
  }),
});

function MantineTable<T extends { [K: string]: any; }>({ data, rowCallback, setRowCount, columns, ToolbarComponent, useFilterListeners, tableOptions }: PropsWithChildren<IMantineTableProps<T>>) {

  const opts = useMemo(() => ({ ...defaultTableOptions<T>(), ...tableOptions }), [tableOptions]);
  const table = useMantineReactTable({
    columns,
    data, //must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    renderTopToolbarCustomActions: ToolbarComponent,
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: () => { rowCallback(row) },
      sx: {
        cursor: 'pointer',
      },
    }),
    ...opts,
  });
  useFilterListeners(table);

  /*
     useEffect(() => {
     table.setState(({ columnFilters: cF, ...rest }) => ({ ...rest, columnFilters: columnFiltersMinusInactive(specialFilters, cF) }));
     }, [specialFilters]);
   */

  useEffect(() => {
    setRowCount(table.getFilteredRowModel().rows.length)
  }, [table.getFilteredRowModel().rows.length])
  return (
    <MantineReactTable
      table={table}
    />
  )
    ;
};

export default observer(MantineTable);
