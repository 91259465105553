import React, { memo, MouseEvent } from "react";
import { Badge } from '@mantine/core';
import { adjustColour, prepareHSLString, stringToColour } from "../../../utils/utils";
import { IconX } from "@tabler/icons-react";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../models/ProvideModel";
import { applyAction } from "mobx-state-tree";

const Icon = memo(({ handleClick, onRemove }: { handleClick: (e: MouseEvent<HTMLDivElement>) => void; onRemove?: () => void; }) =>
  <div
    onClick={handleClick}
    style={{
      display: "flex",
    }}
    onMouseDown={onRemove}
  >
    <IconX size={11} />
  </div >);

/* sx={{
 *   backgroundColor: stringToColour(tag) || "#7D7D7D",
 *   borderColor: stringToColour(tag) || "#7D7D7D",
 *   color: "#BBB",
 *   margin: ".3em"
 * }} */

export interface ITagComponent {
  path: string;
  tag: string;
  actionable: boolean;
  onRemove?: () => void;
  isSelected?: boolean;
  shouldHighlight?: boolean;
  handleClick?: (add: boolean) => void;
};

const Tag = ({ path, tag, actionable, onRemove, isSelected, shouldHighlight, handleClick }: ITagComponent) => {
  const store = useStore();
  return (
    <Badge
      key={tag}
      size="xs"
      onClick={e => {
        e.stopPropagation();
        if (shouldHighlight && handleClick) {
          handleClick(!isSelected);
        }
      }}
      styles={{
        rightSection: {
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          borderRadius: 15,
          borderWidth: 1,
          borderStyle: "solid",
          borderColor: prepareHSLString(adjustColour({
            mode: 'h', amount: -10, ...adjustColour({
              mode: 's', amount: -35, ...adjustColour({
                ...stringToColour(tag).hsl, mode: 'l', amount: 30
              })
            })
          })) || "#9D9D9D",
          "&:hover": {
            borderRadius: 15,
            borderColor: "#FFF",
            backgroundColor: prepareHSLString(adjustColour({
              mode: 'h', amount: -5, ...adjustColour({
                mode: 's', amount: 10, ...adjustColour({
                  ...stringToColour(tag).hsl, mode: 'l', amount: -10
                })
              })
            })) || "#9D9D9D",
          }
        },
        root: shouldHighlight ? isSelected ?
          {
            color: "#FFF",
            cursor: "pointer",
            borderWidth: 2,
            borderColor: prepareHSLString(adjustColour({
              mode: 'l', amount: 5, ...adjustColour({
                ...stringToColour(tag).hsl, mode: 's', amount: -10
              })
            })) || "#6D6D6D",
            backgroundColor: prepareHSLString(adjustColour({
              mode: 's', amount: 13, ...adjustColour({
                ...stringToColour(tag).hsl, mode: 'l', amount: 8
              })
            })) || "#9D9D9D",
            "&:hover": {
              color: "#FFF",
              cursor: "pointer",
              borderWidth: 3,
              borderColor: prepareHSLString(adjustColour({
                mode: 'l', amount: 2, ...adjustColour({
                  ...stringToColour(tag).hsl, mode: 's', amount: -6
                })
              })) || "#6D6D6D",
              backgroundColor: prepareHSLString(adjustColour({
                mode: 's', amount: 15, ...adjustColour({
                  ...stringToColour(tag).hsl, mode: 'l', amount: 11
                })
              })) || "#9D9D9D",
            }
          }
          :
          {
            backgroundColor: prepareHSLString(stringToColour(tag).hsl) || "#7D7D7D",
            borderColor: prepareHSLString(adjustColour({
              mode: 'l', amount: -10, ...adjustColour({
                ...stringToColour(tag).hsl, mode: 's', amount: -20
              })
            })) || "#4D4D4D",
            color: prepareHSLString(adjustColour({
              mode: 'l', amount: 50, ...adjustColour({
                ...stringToColour(tag).hsl, mode: 's', amount: -stringToColour(tag).hsl.s
              })
            })) || "#DDD",
            cursor: "pointer",
            margin: ".2em",
            "&:hover": {
              color: "#FFF",
              borderWidth: 2,
              borderColor: prepareHSLString(adjustColour({
                mode: 'l', amount: 5, ...adjustColour({
                  ...stringToColour(tag).hsl, mode: 's', amount: -10
                })
              })) || "#6D6D6D",
              backgroundColor: prepareHSLString(adjustColour({
                mode: 's', amount: 13, ...adjustColour({
                  ...stringToColour(tag).hsl, mode: 'l', amount: 8
                })
              })) || "#9D9D9D"
            }
          } : {
          backgroundColor: prepareHSLString(stringToColour(tag).hsl) || "#7D7D7D",
          borderColor: prepareHSLString(adjustColour({
            mode: 'l', amount: -10, ...adjustColour({
              ...stringToColour(tag).hsl, mode: 's', amount: -20
            })
          })) || "#4D4D4D",
          color: "#FFF",
          cursor: "default",
          margin: ".2em",
        }
      }}
      rightSection={
        actionable ?
          <Icon
            handleClick={(e) => { e.stopPropagation(); applyAction(store, [{ path, args: [tag], name: 'removeTagFromTicket' }]); }}
            onRemove={onRemove}
          /> : undefined}
    >{tag}</Badge>
  );
};

export default observer(Tag);
