import React from 'react'
import Retool from 'react-retool'
import { useStore } from '../../../models/ProvideModel';
import { observer } from 'mobx-react-lite'

const DPRCapture = () => {
  const store = useStore();
  const environment = process.env.REACT_APP_ENV === 'prd' ? 'production' : process.env.REACT_APP_ENV
  const portalData = { userId: store.auth.userName || 'user', currentProject: store.projectInfo.currentProject?.id, token: localStorage.getItem('Authorization'), loggedInUser: store.auth.userId, customerId: store.auth.customerId }
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <Retool url={`https://falconbrick.retool.com/embedded/public/2544f092-2631-4526-8a14-609eb7881fec?_environment=${environment}`} key={portalData.token} data={portalData} />
    </div>
  )
}

export default observer(DPRCapture)