import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { IconBuilding } from '@tabler/icons-react';

const useStyles = makeStyles({
    root: {
        borderRadius: 0,
        width: 400
    },
    media: {
        height: 140,
    },
    title: {
        color: "#585858",
        fontWeight: 600,
        fontSize: "0.8em"
    },
    body: {
        color: "#808080",
        fontSize: "0.8rem"
    },
    status: {
        color: "#585858",
        fontWeight: 600,
        fontSize: "0.5em"
    },
    statusDiv: {
        paddingTop: 5
    },
    cardContent: {
        border: "0.01em solid #C9C9C9",
        padding: 10
    }
});

export default function NotificationNone() {
    const classes = useStyles();
    return (
        <Card className={classes.root}>
            <CardActionArea>
                <CardContent className={classes.cardContent}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems:'center', flexDirection:'column' }}>
                            <IconBuilding style={{opacity: 0.5}}/>
                            <Typography gutterBottom variant="subtitle2" component="h1" style={{marginBottom: '0em'}}>
                                <span className={classes.body}>You have no notifications.</span>
                            </Typography>
                    </div>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}
