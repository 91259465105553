import React, { useState, useEffect, useMemo } from 'react';
import DataGrid from '../../../../components/DataSheetGrid';
import { textColumn, keyColumn, Column } from 'react-datasheet-grid';
import { Button, Select } from '@mantine/core';
import useStyles from './MonthTargetsStyles';
import { commonFetch, fetchWBSActivities, upsertTargets } from '../../../../api/transactionServer';
import { useStore } from '../../../../models/ProvideModel';
import { CommonApiEntities } from '../../../../models/enums';
import { Instance } from 'mobx-state-tree';
import { StoreModel } from '../../../../models/DataStore';
import moment from 'moment';
import { observer } from 'mobx-react-lite';
import { IconRefresh, IconDeviceFloppy } from  '@tabler/icons-react';
import LoadingSpinner from '../../../../components/loadingSkelaton/LoadingSpinner';
import NoDataForTargets from '../noData/NoDataForTargets';

const transformWBSData = async ({ store, year }: { store: Instance<typeof StoreModel>; year: number; }) => {
    let data: any[] = [];
    const { data: unitActivityData } = await commonFetch({
        projectId: store.projectInfo.currentProject?.id,
        entity: CommonApiEntities.UNIT_ACTIVITIES,
        filters: { isDPR: true, planStart: `${year}-12-31`, planEnd: `${year}-01-01` }
    }).catch((err) => { console.error(err); throw new Error() });

    if (unitActivityData?.length) {
        const { data: wbsDataForTheFrequency } = await fetchWBSActivities({
            projectId: store.projectInfo.currentProject?.id,
            unitActivityIds: unitActivityData.map((ua) => ua.id),
            frequency: 'MONTH'
        }).catch((err) => { console.error(err); throw new Error() });

        const activityMap = {};

        unitActivityData.forEach((unitAct) => {
            const unitActivityId = unitAct.id;
            const existingWBS = wbsDataForTheFrequency?.filter((wbs) => wbs.unitActivityId === unitActivityId) || [];

            activityMap[unitActivityId] = {
                unitTitle: unitAct.unitTitle,
                unitActivity: unitAct.id,
                activity: unitAct.title,
                plannedStart: moment(unitAct.planStart).format('DD-MM-YYYY'),
                plannedEnd: moment(unitAct.planEnd).format('DD-MM-YYYY'),
                plannedTotalQty: unitAct.plannedTotalQuantity,
                unitOfMeasure: unitAct.unitOfMeasure
            };

            for (let month = 1; month <= 12; month++) {
                const targetMonth = `${year}-${month.toString().padStart(2, '0')}`;
                const monthlyData = existingWBS.find((wbs) => wbs.targetPeriod.startsWith(targetMonth));
                activityMap[unitActivityId][`month${month}`] = monthlyData ? monthlyData.quantity : null;
            }
        });

        const sortedData = Object.values(activityMap).sort((a: any, b: any) => {
            // Sort by unitTitle
            const unitTitleComparison = a.unitTitle.localeCompare(b.unitTitle);
            if (unitTitleComparison !== 0) return unitTitleComparison;
        
            // Sort by activity
            const activityComparison = a.activity.localeCompare(b.activity);
            if (activityComparison !== 0) return activityComparison;
        
            // Sort by plannedStart (handle missing or invalid dates)
            const dateA = a.plannedStart ? new Date(a.plannedStart).getTime() : 0;
            const dateB = b.plannedStart ? new Date(b.plannedStart).getTime() : 0;
            return dateA - dateB;
        });
        // data = Object.values(activityMap);
        data = sortedData;
    }

    return data;
};

const MonthlyTargets = () => {
    const classes = useStyles();
    const store = useStore();
    const [year, setYear] = useState<number>(new Date().getFullYear());
    const [data, setData] = useState<any[]>([]);
    const [changes, setChanges] = useState({});
    const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
    const [loading, setLoading] = useState(false);
    const currentYear = new Date().getFullYear();

    const baseColumns: Column<any>[] = [
        {
            ...keyColumn('unitTitle', textColumn),
            title: 'Unit',
            minWidth: 200,
            disabled: true,
        },
        {
            ...keyColumn('activity', textColumn),
            title: 'Activity Name',
            minWidth: 250,
            disabled: true,
        },
        {
            ...keyColumn('plannedStart', textColumn),
            title: 'Planned start',
            minWidth: 120,
            disabled: true,
        },
        {
            ...keyColumn('plannedEnd', textColumn),
            title: 'Planned end',
            minWidth: 120,
            disabled: true,
        },
        {
            ...keyColumn('plannedTotalQty', textColumn),
            title: 'Total QTY',
            minWidth: 90,
            disabled: true,
        },
        {
            ...keyColumn('unitOfMeasure', textColumn),
            title: 'UoM',
            minWidth: 90,
            disabled: true,
        },
    ];

    const columns = useMemo(() => {
        const monthColumns: Column<any>[] = Array.from({ length: 12 }, (_, index) => ({
            ...keyColumn(`month${index + 1}`, textColumn),
            title: new Date(0, index).toLocaleString('en', { month: 'long' }),
            cellClassName: ({rowData, rowIndex}) => changes[rowIndex] && changes[rowIndex][`month${index + 1}`] ? classes.cellChanged : '',
        }));
        return [...baseColumns, ...monthColumns];
    }, [year, changes]);

    const loadData = async () => {
        setLoading(true);
        setIsSaveButtonDisabled(true);
        const transformedData = await transformWBSData({ store, year });
        setData(transformedData);
        // setChanges({});
        setChanges(transformedData);
        setLoading(false);
    };

    useEffect(() => {
        loadData();
    }, [year]);

    const handleGridChange = (updatedData) => {
        setChanges((prevChanges) => {
            const newChanges = { ...prevChanges };
            updatedData.forEach((row, rowIndex) => {
                Object.keys(row).forEach((key) => {
                    if (row[key] !== prevChanges[rowIndex]?.[key]) {
                        if (!newChanges[rowIndex]) newChanges[rowIndex] = {};
                        newChanges[rowIndex][key] = row[key];
                    }
                });
            });
            setIsSaveButtonDisabled(false);
            return newChanges;
        });
    };

    const handleSaveChanges = async () => {
        const scheduleList: any = [];

        Object.entries(changes).forEach(([rowIndex, rowData]) => {
            const originalData = data[rowIndex];
            const unitActivityId = originalData.unitActivity;

            Object.entries(rowData as Record<string, any>).forEach(([key, value]) => {
                if (key.startsWith('month') && value) {
                    const month = key.replace('month', '').padStart(2, '0');
                    const targetPeriod = `${year}-${month}`;

                    scheduleList.push({
                        unitActivityId: unitActivityId,
                        frequency: 'MONTH',
                        targetPeriod: targetPeriod,
                        quantity: !Number.isNaN(value) ? Number.parseInt(value) : null,
                    });
                }
            });
        });

        try {
            await upsertTargets({ projectId: store.projectInfo.currentProject?.id, scheduleList })
            .catch((err) => { console.error(err); throw new Error() });
            // console.log('Changes saved successfully:', scheduleList);
            setIsSaveButtonDisabled(true);
            await loadData();
        } catch (error) {
            console.error('Failed to save changes:', error);
        }
    };

    // const yearOptions = [
    //     { value: '2023', label: '2023' },
    //     { value: '2024', label: '2024' },
    //     { value: '2025', label: '2025' },
    // ];

    const yearOptions = Array.from({ length: 3 }, (_, i) => {
        const year = currentYear - 1 + i;
        return {
            value: `${year}`,
            label: `${year}`,
        };
    });

    return (
        <div className={classes.root}>
            <h3 className={classes.title}>Targets by Month</h3>

            <div className={classes.dropdownDiv}>
            <div className={classes.dropdownsContainer}>
            <Select
                    label="Choose Year"
                    value={String(year)}
                    onChange={(value) => setYear(Number(value))}
                    data={yearOptions}
                    placeholder="Select year"
                    styles={{ dropdown: { maxWidth: 200 } }}
                    className={classes.dropdown}
                    sx={{
                        ["& .mantine-Select-input"]: {  borderRadius: 0 }
                    }}
                />
            </div>
            <div className={classes.buttonsContainer}>
            <Button 
                    onClick={handleSaveChanges} 
                    className={classes.saveButton} 
                    disabled={isSaveButtonDisabled}
                >
                    Save <IconDeviceFloppy width={'1em'} height={'1em'} style={{marginLeft: '0.25em'}}/>
                </Button>

                <Button 
                    onClick={loadData} 
                    className={classes.saveButton} 
                >
                    Refresh <IconRefresh width={'1em'} height={'1em'} style={{marginLeft: '0.25em'}}/>
                </Button>
            </div>
            </div>

            {/* <DataGrid key={year} data={data} columns={columns} onChange={handleGridChange} /> */}
            {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
                    <LoadingSpinner />
                </div>
            ) :  !data.length ? (
                <NoDataForTargets />
            ) : (
                <div style={{ height: '100%', overflow: 'auto', display: 'flex', flexDirection: 'column' }}>
                <DataGrid key={year} data={data} columns={columns} onChange={handleGridChange} />
                </div>
            )}
        </div>
    );
};

export default observer(MonthlyTargets);
