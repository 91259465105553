import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
    button: {
        background: '#7d725d',
        color: "#FFF",
        fontSize: "0.9em",
        fontWeight: 600,
        borderRadius: 0,
        cursor: 'pointer',
        padding: '1em',
        marginTop: '1em',
        textDecoration: 'none',
        '&:hover' : {
            background: '#645b4a'
        }
    },

}));

export default useStyles;