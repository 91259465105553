import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'sticky',
  },
  tab: {
    display: "flex",
    fontSize: '0.8575em'
  },
  panel: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    background: '#FFF0F6',
    flexBasis: "auto",
    justifyContent: 'center',
    height: '100%',
    /* overflow: 'scroll' */
  }
}))

export default useStyles;
