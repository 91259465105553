import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  commentsDiv: {
    width: "100%",
    background: "#fcfdfd",
    display: "flex",
    justifyContent: "center",
    flexBasis: "auto",
    flexDirection: "column",
    height: "100%"
  },
  handleContainer: (props: { isLg: boolean }) => ({
    alignItems: "center",
    height: "100%",
    overflow: "hidden",
    flexShrink: 1,
    // padding: "10px 20px"
  }),
  handleScrollLayer: {
    display: "flex",
    flexDirection: "column",
    flexShrink: 1,
    flexGrow: 1,
    overflow: "auto",
    width: "100%",
    alignItems: "center",
    // position: "relative",
    [theme.breakpoints.up('md')]: {
      height: '100%'
    }
  },
  rejectMessage: {
    color: '#FA5252',
    fontSize: '0.7525em',
    alignSelf: 'flex-end',
    marginRight: '1.5em'
  },
  dropzoneRoot: {
    width: '100%',
    border: '0.075em dashed #ced4da',
    '&[data-accept]': {
      border: '0.075em dashed #005eff',
    }
  },
  submitCommentDiv: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "flex-end",
    width: "100%",
    padding: "5px, 10px !important",
  },
  submitCommentButton: {
    display: "flex",
    height: "2.4em",
    flexGrow: 0,
    fontSize: "0.80em",
    color: "white",
    background: " #005eff",
    textAlign: "center",
    flexDirection: "column",
    justifyContent: "center",
    cursor: "pointer",
    borderRadius: "0",
    fontWeight: 600,
    "&:hover": {
      backgroundColor: "#1a6fef",
      color: "#faf9f9"
    },
    "&:disabled": {
      backgroundColor: "#d1d1d1",
      color: "#808080"
    },
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: '0.8em 1.25em',
    background: '#FFF',
    borderBottom: '0.01em solid #d9d9d9',
    [theme.breakpoints.down('sm')]: {
      borderTop: '0.01em solid #d9d9d9',
    }
  },
  actionsTitle: {
    fontSize: '0.9em',
    fontWeight: 600
  },
  actionsList: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  deleteIcon: {
    "&:hover": {
      // backgroundColor: "#1a6fef",
      color: "red",
      cursor: 'pointer'
    }
  }
}))

export default useStyles;
