import React, { useEffect, useReducer } from 'react'
import { CloseButton, Button, Tooltip, Popover } from '@mantine/core';
import { IconTrash } from '@tabler/icons-react';
import useStyles from './HeaderStyles';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { ITicket } from '../../../models/Ticket';
import { IChan, putAsync } from 'csp-with-ts';
import GenericInput from '../../../components/FormInputs/GenericInput';
import { notifications } from '@mantine/notifications';

export interface IRequestPopupCore {
  id?: string;
  title: string;
  closeAction: () => void;
  ticket: ITicket;
  coreFields: { [K: string]: any; };
  ch: IChan;
}

const initialState = ({ coreFields, ...ticket }) => {
  var ret = { delta: {} };
  for (const [id, { isEditable }] of Object.entries<{ [K: string]: any; }>(coreFields)) {
    if (isEditable) {
      if (id === 'title') {
        ret['title'] = ticket.name;
      }
      ret[id] = ticket[id];
    }
  }
  return ret;
};

function reducer(state: { [K: string]: any; }, action: { type: string; payload: { field: string; input: string; type: string }; }) {
  return {
    ...state, [action.payload.field]: action.payload.type === "date" ? moment(action.payload.input).toISOString() : action.payload.input,
    delta: { field: action.payload.field, value: action.payload.type === "date" ? moment(action.payload.input).toISOString() : action.payload.input }
  } as { [K: string]: any; };
}

function RequestPopupCore({ id, title, closeAction, ticket, coreFields, ch }: IRequestPopupCore) {
  const classes = useStyles();
  const [state, dispatch] = useReducer(reducer, { ...ticket, coreFields }, initialState);

  useEffect(() => {
    putAsync(ch, { ...state.delta });
  }, [state]);

  return (
    <div className={classes.root}>
      {/* <span className={classes.title}>{title}</span> */}
      <div style={{ display: 'flex', flexGrow: 1 }}>
        <GenericInput
          field={'title'}
          dispatch={dispatch}
          state={state['title'] || (ticket && ticket.name) || ""}
          type='text'
          label='Title'
          size={'xs'}
          disabled={!coreFields['title'].isEditable}
          titleStyleException={true}
          debounce={true}
          coreField={true}
        />
      </div>
      {/* <div className={classes.saveDiv}><span className={classes.saveButton} aria-disabled={true}>Save</span></div> */}
      {ticket.canDelete ? 
      <span style={{display: 'flex', alignItems: 'center', marginRight: ticket.canDelete ? '1em' : '0em'}}>
      {/* <Tooltip label="Delete Ticket">
          <IconTrash 
          onClick={() => { ticket.delete(); }}
          width={'1.25em'}
          height={'1.25em'}
          className={classes.deleteIcon}
          />
      </Tooltip> */}

      <Popover position="bottom" withArrow shadow="md">
        <Tooltip label="Delete ticket" position='left'>
        <Popover.Target>
        <IconTrash 
          // onClick={() => { ticket.delete(); }}
          width={'1.25em'}
          height={'1.25em'}
          className={classes.deleteIcon}
          />
        </Popover.Target>
        </Tooltip>
        <Popover.Dropdown>
            <div style={{display: 'flex', flexDirection: 'column', fontSize: '0.875em'}}>
            <span className={classes.deleteActionText} style={{color: '#fa5252'}}><strong>Action is irreversible!</strong></span>
            <span className={classes.deleteActionText}>Are you sure you want to delete this ticket ? </span>
            <span className={classes.deleteSure} onClick={() => { ticket.delete(); }}>Delete</span>
            </div>
        </Popover.Dropdown>
      </Popover>
    </span> 
      : null}
      <div className={classes.closeDiv}>{id ? <span className={classes.id}>(Request ID: {id})</span> : null}
        <span><CloseButton color='red' title='Close' onClick={closeAction} /></span></div>
    </div>
  )
}

export default observer(RequestPopupCore);
