import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        // background: '#F3F0FF',
        background: '#fcfdfd',
        flexBasis: "auto",
        justifyContent: 'center'
    },
    head: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '1em 1.5em',
        // boxShadow: "0px -10px 10px -5px rgba(0,0,0,0.12)"
    },
    title: {
        display: 'flex',
        fontSize: '0.9575em',
        color: '#585858',
        fontWeight: 600,
        alignItems: 'center'
    },
    addButton: {
        fontSize: '0.7575em',
        color: '#585858',
        padding: '0.2em',
        height: 'auto',
        borderRadius: 0,
        marginLeft: '0.5em',
        '&:hover': {
            cursor: 'pointer',
            color: '#585858',
            fontWeight: 600
        }
    },
    itemContainer: {
        height: "100%",
        overflow: "overlay",
        flexShrink: 1,
        alignItems: "center",
    },
    itemScrollLayer: {
        display: "flex",
        flexDirection: "column",
        flexShrink: 1,
        flexGrow: 1,
        // overflow: "scroll",
        width: "100%",
        padding: '0.8em 1.5em'
        // alignItems: "center",
      },
      itemCardScrollLayer: {
        display: "flex",
        width: "100%",
        padding: '0.8em 1.5em'
      },
    item: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        padding: '0.5em 1.5em',
        background: '#FFF',
        borderBottom: '0.5px solid #D9D9D9',
        width: '100%',
        marginBottom: '0.5em'
    },
    tablerIcon: {
        width: '1.1em',
        height: '1.1em',
        alignSelf: 'center',
        '&:hover': {
            cursor: 'pointer',
            fontWeight: 'bold'
        }
    },
    toggle: {
        fontSize: '0.7575em',
        color: '#585858',
        opacity: 0.7,
        display: 'flex',
        '&:hover': {
            cursor: 'pointer',
            opacity: 1,
        }
    },
    downloadText: {
        fontSize: '0.7575em',
        color: '#585858',
        opacity: 0.7,
        display: 'flex',
        '&:hover': {
            cursor: 'pointer',
            opacity: 1,
        }
    },
    arrow: (props: { showAttachments: boolean }) => ({
        width: '1.5em',
        alignSelf: 'center',
        transform: props.showAttachments ? 'rotate(180deg)' : 'rotate(0deg)'
    }),
    noAttach: {
        fontSize: '0.7575em',
        color: '#585858',
        opacity: 0.7,
        display: 'flex',
    },
    cell: {
        display: 'flex',
        flexDirection: 'column', 
        justifyContent: 'flex-start',
    },
    cellHead: {
        fontSize: '0.6em',
        color: '#585858',
        opacity: 0.6
    },
    cellBody: {
        paddingTop: '0.25em',
        fontSize: '0.8575em',
        color: '#585858',
        maxWidth: 150,
        wordBreak: 'break-word',
        '&:hover': {
            maxWidth: '100%',
            whiteSpace: 'normal' ,
        }
    },
    fileTypeTextDiv: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        height: 100,
        flexDirection: 'column',
        alignItems: 'center',
        background: '#f9f9f9'
    },
    fileTypeText: {
        fontSize: '0.7575em',
        fontWeight: 700,
        display: 'flex',
        // alignItems: 'center'
    },
    fileIcon: {
        width: '3em',
        height: '3em',
        opacity: 0.2
    }, 
    switch: {
        fontSize: '0.85em',
        marginLeft: '1.5em',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer'
    },
    image: {
        '&:hover' : {
            cursor: 'pointer',
            opacity: 0.8,
        }
    },

    imageComponent: {
        transition: 'opacity 0.3s ease',
      },
      imageHoverEffect: {
        opacity: 1,
      },
      viewText: {
        content: 'View',
        color: 'white', 
        fontSize: '14px',
        opacity: 0.7,
        cursor: 'pointer',
        transition: 'opacity 0.3s ease',
        zIndex: 1
      },
      fileName: {
        fontSize: '0.85em',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        textDecoration: 'none',
        width: '80%',
        overflow: 'hidden',
        '&:hover' : {
            whiteSpace: 'normal' ,
        }
    }
}))

export default useStyles;