import React, { useEffect } from 'react';
import { MRT_TableInstance } from 'mantine-react-table';
import { UnitActivityDetails } from './gridColumns';
import { onPatch } from 'mobx-state-tree';
import { useStore } from '../../models/ProvideModel';

export default function useMantineFiltersListener(table: MRT_TableInstance<UnitActivityDetails>) {
  const store = useStore();
  useEffect(() => {
    const p1 = onPatch(store.planning, ({ path, value, op }, { value: oldVal }) => {
      if (path === "/loading" && op === "replace" && value !== oldVal) {
        table.setState(st => ({ ...st, isLoading: value }));
      }
    });
    return () => { p1(); };
  }, []);
}
