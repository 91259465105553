import React from 'react';
import useStyles from './GeneralFormSkeletonStyles';
import { Skeleton } from '@mantine/core';


const GeneralFormLoader = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.main}><Skeleton width={'20%'} height={'1rem'}  animate/></div>
            <div className={classes.main}><Skeleton width={'100%'} height={'6rem'}  animate/></div>
            {/* <div className={classes.main}><Skeleton width={'20%'} height={'1rem'}  animate/></div>
            <div className={classes.main}><Skeleton width={'100%'} height={'3rem'}  animate/></div> */}
        </div>
    )
}

export default GeneralFormLoader