import React from 'react';
import { Box, Text } from '@mantine/core';
import { MRT_Cell } from 'mantine-react-table';
import { RequestTicketDetails } from '../../../pages/requestsModulePage/gridColumns';

const RichTextCell = ({ cell }: { cell: MRT_Cell<RequestTicketDetails>; }) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      gap: '16px',
      textWrap: "balance",
    }}
  >
    <Text
      lineClamp={5}
      dangerouslySetInnerHTML={{ __html: cell.getValue() as string }}></Text>
  </Box >
);

export default RichTextCell;
