import React from 'react';
import { DataSheetGrid, Column } from 'react-datasheet-grid';
import 'react-datasheet-grid/dist/style.css';

interface IDataGridProps<T> {
  data: T[];
  columns: Column<T>[];
  onChange?: (updatedData: T[]) => void;
}

const DataGrid = <T,>({ data, columns, onChange }: IDataGridProps<T>) => {
  return (
      <DataSheetGrid
      value={data}
      onChange={onChange}
      columns={columns}
      // height={450}
      style={{ height: '100%', overflow: 'auto', display: 'flex', flexDirection: 'column' }}
      height={1085}
      rowHeight={35}
      lockRows
    />
  );
};

export default DataGrid;
