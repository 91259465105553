import React, { memo } from 'react';
import { useTable, Column } from 'react-table';
import { TableRow, TableCell, TableBody, TableHead } from '@material-ui/core';
import MaUTable from '@material-ui/core/Table'
import useStyles from './FatTableStyle'
import { useStore } from "../../models/ProvideModel";
import NoDataFound from '../noDataFound/NoDataFound';
import { useObserver } from 'mobx-react-lite';
import Calendar from '../calender/Calendar'
export interface IFatTableProps<T extends object = {}> {
  columns: Array<Column<T>>;
  data: T[];
  print: string;
  blockCount?: number
};

const FatTable = memo(({ columns, data, print, blockCount }: IFatTableProps) => {
  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    rows,
    headers
  } = useTable(
    {
      columns,
      data
    },
  );

  const store = useStore();
  const classes = useStyles({ print });

  return useObserver(() => (
    <div className={classes.table}>
      <MaUTable stickyHeader={store.responsiveUtils.fatDropdownFlag === false} {...getTableProps()} component="div">
        {store.filters.calenderOpen ? <Calendar /> : null}
        <TableHead component="div">
          <TableRow component="div">
            {headers.map((header) => (
              <TableCell {...header.getHeaderProps()} key={header.name} style={{ zIndex: 0 }} component="div">
                <div className={[(print === "print") ? classes.printHeader :
                  typeof (header.Header) === "string" ? classes.nonDropdownText : "", classes.stickyHeader].join(" ")}>
                  {(typeof (header.Header) === "string" || (print === "print")) ? <span style={{ marginTop: "16px", }}>{header.render('Header')}</span> : header.render('Header')}
                </div>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {(data.length !== 0) ? <TableBody {...getTableBodyProps()} component="div">
          {rows.map((row) => {
            prepareRow(row)
            return (
              <TableRow {...row.getRowProps()} key={row.id} style={{ height: row.cells[0].column.minHeight }} component="div">
                {row.cells.map(cell => {
                  return (
                    <TableCell {...cell.getCellProps()} key={`${cell.column.id}_${cell.value}`} component="div">
                      <div style={print === "print" ? { minWidth: 50, maxWidth: cell.column.minWidth, pageBreakInside: "avoid" } : { minWidth: cell.column.minWidth, textTransform: "capitalize" }} className={(print === "print") ? classes.printViewCell : ""}>{cell.render('Cell')}</div>
                    </TableCell>
                  )
                })}
              </TableRow>
            )
          })}
        </TableBody>
          :
          <TableBody component="div">
            <TableRow component="div">
              <TableCell align="center" colSpan={headers.length} style={{ background: "#faf9f9" }} component="div">
                <NoDataFound blockCount={blockCount} />
              </TableCell>
            </TableRow>
          </TableBody>
        }
      </MaUTable>
    </div>
  ))
});

export default FatTable;
