import React from 'react';
import useStyles from './HistoryCommentSkeletonStyles';
import { Skeleton } from '@mantine/core';


const HistoryCommentLoader = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.main}>
                <Skeleton width={'50%'} color='pink' height={'1rem'} animate />
                <Skeleton width={'20%'} color='pink' height={'1rem'} animate />
            </div>
            <div className={classes.main}>
                <Skeleton width={'40%'} color='pink' height={'1rem'} animate />
                <Skeleton width={'20%'} color='pink' height={'1rem'} animate />
            </div>
        </div>
    )
}

export default HistoryCommentLoader