import { useState, useEffect, useCallback, useReducer, useMemo } from 'react';
import { LocationDescriptorObject, LocationState } from 'history';
// import moment from 'moment';
import { DocumentNode } from 'graphql';
import client from './apolloClient';

export interface IURLParamHookReturn<T extends any = string> extends Array<any> {
    [0]: T;
    [1]: (val?: T) => void;
};


type ICustomMap<T extends any = string> = (val: string) => T extends Array<infer S> ? S : T;

export function useSingleValueURLParam<T extends any = string>(param: string, defaultState: T, searchParam: URLSearchParams, conversionFn?: T extends string ? undefined : ICustomMap<T>, router?: (loc: LocationDescriptorObject) => void, location?: LocationState, serialToState?: (val: URLSearchParams) => T): IURLParamHookReturn<T> {
    const convert = serialToState || ((val: URLSearchParams) => val.has(param) ? conversionFn ? conversionFn(val.get(param)!) as T : val.get(param) : defaultState);
    const [state, setState] = useState<T>(() => convert(searchParam) as T);
    useEffect(() => {
        const serializedState: string | null = searchParam.get(param);
        if (serializedState === null) { setState(defaultState); }
        else {
            if (conversionFn) { setState(_ => conversionFn(serializedState) as T); }
            else { setState(serializedState as unknown as T); }
        }
    }, [searchParam]);
    const reroute = useURLParamRerouter<T>(param, searchParam, router, location);
    return [state, reroute];
}

export function useURLParamRerouter<T extends any = string>(param: string, searchParam: URLSearchParams, router?: (loc: LocationDescriptorObject) => void, location?: LocationState): (val?: T) => void {
    const reroute = useCallback((val?: T) => {
        if (router && location) {
            searchParam.delete(param);
            if (val !== undefined) {
                searchParam.append(param, (val as any).toString());
            }
            router({ ...location, search: searchParam.toString() });
        }
    }, [searchParam, location]);
    return reroute;
}
/* Modal Shenanigans */
export enum ModalState {
    CLOSED = "CLOSED",
    SUBMIT_OPEN = "SUBMIT_OPEN",
    SUBMIT_ERROR = "SUBMIT_ERROR",
    SUBMIT_SUCCESS = "SUBMIT_SUCCESS",
    SAVE_OPEN = "SAVE_OPEN",
    SAVE_ERROR = "SAVE_ERROR",
    SAVE_SUCCESS = "SAVE_SUCCESS",
    OUTCOME_OPEN = "OUTCOME_OPEN",
    OUTCOME_ERROR = "OUTCOME_ERROR",
    OUTCOME_SUCCESS = "OUTCOME_SUCCESS",
};

export enum ModalActions {
    OPEN_MODAL = "OPEN_MODAL",
    CLOSE_MODAL = "CLOSE_MODAL",
    CHANGE_MODAL_STATE = "CHANGE_MODAL_STATE",
    SET_LOADING = "SET_LOADING",
    CLOSE_MODAL_WITH_EFFECT = "CLOSE_MODAL_WITH_EFFECT",
};

export enum ModalEffects {
    SUBMIT = "SUBMIT",
    SAVE = "SAVE",
    OUTCOME = "OUTCOME"
};

interface IModalAction {
    type: string;
    onClick: { effect?: ModalEffects; action?: ModalActions; }
};

interface IModalConfig {
    [K: string]: {
        state: ModalState;
        isOpen: boolean;
        title: string;
        text: string;
        outcome?: string;
    };
};

interface IModalActionsConfig {
    [K: string]: IModalAction[];
};


export const modalsActionsConfig: IModalActionsConfig = {
    [ModalState.CLOSED]: [],
    [ModalState.SUBMIT_OPEN]: [{
        type: "OK",
        onClick: {
            effect: ModalEffects.SUBMIT,
        }
    },
    {
        type: "Cancel",
        onClick: { action: ModalActions.CLOSE_MODAL }
    }],
    [ModalState.SUBMIT_ERROR]: [{
        type: "Close",
        onClick: { action: ModalActions.CLOSE_MODAL }
    }],
    [ModalState.SUBMIT_SUCCESS]: [{
        type: "OK",
        onClick: { action: ModalActions.CLOSE_MODAL_WITH_EFFECT }
    }],
    // [ModalState.SAVE_OPEN]: [{
    //     type: "OK",
    //     onClick: { effect: ModalEffects.SAVE }
    // }, {
    //     type: "Cancel",
    //     onClick: { action: ModalActions.CLOSE_MODAL }
    // }],
    [ModalState.SAVE_ERROR]: [{
        type: "Close",
        onClick: { action: ModalActions.CLOSE_MODAL }
    }],
    [ModalState.SAVE_SUCCESS]: [{
        type: "OK",
        onClick: { action: ModalActions.CLOSE_MODAL }
    }],
    [ModalState.OUTCOME_OPEN]: [{
        type: "OK",
        onClick: { effect: ModalEffects.OUTCOME }
    }, {
        type: "Cancel",
        onClick: { action: ModalActions.CLOSE_MODAL }
    }],
    [ModalState.OUTCOME_ERROR]: [{
        type: "Close",
        onClick: { action: ModalActions.CLOSE_MODAL }
    }],
    [ModalState.OUTCOME_SUCCESS]: [{
        type: "OK",
        onClick: { action: ModalActions.CLOSE_MODAL_WITH_EFFECT }
    }],
};

const modalsConfig: IModalConfig = {
    [ModalState.CLOSED]: {
        state: ModalState.CLOSED,
        isOpen: false,
        title: "",
        text: ""
    },
    [ModalState.SUBMIT_OPEN]: {
        state: ModalState.SUBMIT_OPEN,
        isOpen: true,
        title: "Submit Changes",
        text: "Are you sure you want to submit your changes?",
    },
    [ModalState.SUBMIT_ERROR]: {
        state: ModalState.SUBMIT_ERROR,
        isOpen: false,
        title: "An Error Occurred while attempting to Submit Changes.",
        text: "Error Message: ",
    },
    [ModalState.SUBMIT_SUCCESS]: {
        state: ModalState.SUBMIT_SUCCESS,
        isOpen: false,
        title: "Success!",
        text: "Your changes were submitted successfully!",
    },
    // [ModalState.SAVE_OPEN]: {
    //     state: ModalState.SAVE_OPEN,
    //     isOpen: true,
    //     title: "Save Changes",
    //     text: "Are you sure you want to save your changes?",
    // },
    [ModalState.SAVE_ERROR]: {
        state: ModalState.SAVE_ERROR,
        isOpen: false,
        title: "An Error Occurred while attempting to Save Changes.",
        text: "Error Message: ",
    },
    [ModalState.SAVE_SUCCESS]: {
        state: ModalState.SAVE_SUCCESS,
        isOpen: false,
        title: "Success!",
        text: "Your changes were saved successfully!",
    },
    [ModalState.OUTCOME_OPEN]: {
        state: ModalState.OUTCOME_OPEN,
        isOpen: true,
        title: "",
        text: "Are you sure you want to submit your changes?",
    },
    [ModalState.OUTCOME_ERROR]: {
        state: ModalState.OUTCOME_ERROR,
        isOpen: false,
        title: "An Error Occurred while attempting to Submit with Outcome: ",
        text: "Error Message: ",
    },
    [ModalState.OUTCOME_SUCCESS]: {
        state: ModalState.OUTCOME_SUCCESS,
        isOpen: false,
        title: "",
        text: "You have successfully submitted your changes with outcome ",
    }
};

// const makeOutcomeModals = (outcome: string) => ({
//     [ModalState.OUTCOME_OPEN]: {
//         state: ModalState.OUTCOME_OPEN,
//         isOpen: true,
//         outcome,
//         title: outcome,
//         text: "Are you sure you want to save your changes?",
//     },
//     [ModalState.OUTCOME_ERROR]: {
//         state: ModalState.OUTCOME_ERROR,
//         isOpen: true,
//         outcome: null,
//         title: "An Error Occurred while attempting to Submit with Outcome: " + outcome,
//         text: "Error Message: ",
//     },
//     [ModalState.OUTCOME_SUCCESS]: {
//         state: ModalState.OUTCOME_SUCCESS,
//         outcome: null,
//         isOpen: true,
//         title: "Success!",
//         text: "You have successfully submitted your changes with outcome " + outcome + "!",
//     }
// });

const modalReducer = (state, action) => {
    switch (action.type) {
        case ModalActions.SET_LOADING: return { ...state, loading: action.payload.loading || true };

        case ModalActions.CLOSE_MODAL_WITH_EFFECT:
        case ModalActions.CLOSE_MODAL: return { ...modalsConfig[ModalState.CLOSED], loading: false };
        case ModalActions.OPEN_MODAL: return { ...modalsConfig[action.payload.state], outcome: action.payload.outcome || null, title: modalsConfig[action.payload.state].title + (action.payload.outcome || ""), loading: false };
        case ModalActions.CHANGE_MODAL_STATE: if((action.payload.state !== ModalState.OUTCOME_ERROR) &&  (action.payload.state !== ModalState.OUTCOME_SUCCESS) && (action.payload.state !== ModalState.SUBMIT_ERROR) && (action.payload.state !== ModalState.SUBMIT_SUCCESS) && (action.payload.state !== ModalState.SAVE_ERROR) && (action.payload.state !== ModalState.SAVE_SUCCESS)) {
            return {
            ...modalsConfig[action.payload.state],
            text: modalsConfig[action.payload.state].text + (action.payload.outcome ? ` ${action.payload.outcome}` : "") + (action.payload.message ? `\n${action.payload.message}` : ""),
            title: action.payload.outcome ? modalsConfig[action.payload.state].title + action.payload.outcome : modalsConfig[action.payload.state].title,
            outcome: action.payload.outcome,
            loading: false
        };
    }
        default: return { ...modalsConfig[ModalState.CLOSED], loading: false };
    }
};

export const useFormDialogModal = (effects: { [K in ModalEffects]?: (outcome?: any) => Promise<any>; }, onCloseHook?: () => void) => {
    const [state, dispatch] = useReducer(modalReducer, modalsConfig[ModalState.CLOSED]);
    const closeModal = () => { dispatch({ type: ModalActions.CLOSE_MODAL }); };
    const closeModalWithEffect = () => { dispatch({ type: ModalActions.CLOSE_MODAL_WITH_EFFECT }); if (onCloseHook) { onCloseHook(); } };
    const mapping = useMemo(() => ({
        [ModalActions.CLOSE_MODAL]: closeModal,
        [ModalActions.CLOSE_MODAL_WITH_EFFECT]: closeModalWithEffect,
        ...Object.keys(effects).reduce((acc, effect: ModalEffects) => ({
            ...acc,
            [effect]: () => {
                dispatch({ type: ModalActions.SET_LOADING, payload: { loading: true } });
                effects[effect]!.apply(null, state.outcome ? [state.outcome] : [])
                    .then(() => {
                        dispatch({ type: ModalActions.CHANGE_MODAL_STATE, payload: { state: effect + "_SUCCESS", outcome: effect === ModalEffects.OUTCOME ? state.outcome : null } });
                    })
                    .catch(err => {
                        dispatch({ type: ModalActions.CHANGE_MODAL_STATE, payload: { state: effect + "_ERROR", message: err.response?.data?.errorMessage, outcome: effect === ModalEffects.OUTCOME ? state.outcome : null } });
                    })
            }
        }), {})
    }), [effects, state]);
    return [state, dispatch, mapping];
};

/* End of Modal Shenanigans */
export interface IMultiPanelState {
    currentPanel: string | null;
    selection: { [K: string]: string[] };
};

export enum MultiPanelActions {
    REPLACE_STATE = "REPLACE_STATE",
    PUSH_STATE = "PUSH_STATE",
    SET_CURRENT_PANEL = "SET_CURRENT_PANEL",
    RESET_STATE = "RESET_STATE"
};

export function multiPanelSelectReducer(state: IMultiPanelState, { type, payload }: { type: MultiPanelActions; payload: { [K: string]: any; } }) {
    switch (type) {
        case MultiPanelActions.REPLACE_STATE: return { ...state, selection: { ...state.selection, [payload.section]: payload.selection } };
        case MultiPanelActions.PUSH_STATE:
            const ind = state.selection[payload.section].findIndex(id => id === payload.id);
            return { ...state, currentPanel: payload.section, selection: { ...state.selection, [payload.section]: ind !== -1 ? [...state.selection[payload.section].slice(0, ind), ...state.selection[payload.section].slice(ind + 1)] : [...state.selection[payload.section], payload.id] } };
        case MultiPanelActions.SET_CURRENT_PANEL: return { ...state, currentPanel: payload.section };
        case MultiPanelActions.RESET_STATE: return { currentPanel: payload.panels[0], selection: payload.panels.reduce((acc, panel) => ({ ...acc, [panel]: [] }), {}) };
    }
}

export interface ILocationPanel {
    id: string;
    name: string;
    query: DocumentNode;
    queryVariable: string;
    variables?: { [K: string]: any; };
    accessor?: string;
    optionValue?: string;
};
export function useLocationPanels(locationPanels: ILocationPanel[], state: IMultiPanelState, init?: { [K: string]: { [K: string]: string; }; }) {
    const [optionsTree, setOptionsTree] = useState({});
    const [lookup, setLookup] = useState(() => init || {});
    useEffect(() => { if (!client.client) { client(); } }, [client]);
    const panels = useMemo(() =>
        locationPanels.map(({ id, name, query, variables, accessor }, idx) => ({
            id,
            name,
            button: {
                label: `View ${name}s`,
                callback: () => {
                    if (client.client && (!idx || state.selection[locationPanels[idx - 1].id].length)) {
                        client.client.query({
                            query,
                            variables: ({ ...locationPanels.slice(0, idx).reduce((acc, { id: idd, queryVariable, optionValue }) => state.selection[idd].length ? ({ ...acc, [queryVariable]: lookup[state.selection[idd][0]][optionValue!] }) : acc, {}), ...(variables || {}) })
                        }).then(({ data }) => {
                            setLookup(lk => ({ ...lk, ...(accessor ? data.options[accessor] : data.options).reduce((acc, { id, ...rest }) => ({ ...acc, [id]: { id, ...rest } }), {}) }));
                            setOptionsTree(ot => ({
                                ...ot, [id]: idx ? {
                                    ...ot[id],
                                    ...locationPanels.slice(0, idx).reverse().reduce((acc, { id: idd }, ind, arr) => ind ? ind === (arr.length - 1) ? ({ [state.selection[idd][0]]: acc }) : ({ [state.selection[idd][0]]: { ...ot[id][state.selection[idd][0]], ...acc } }) : ({ [state.selection[idd][0]]: [...((accessor ? data.options[accessor] : data.options) || [])] }), {})
                                } : [...(accessor ? data.options[accessor] : data.options)]
                            }));
                        }).catch(err => console.error(`nooo ${err}`))
                    }
                }
            }
        }))
        , [state, client, lookup]);
    const options: { [K: string]: { id: string; name: string; floor?: number; block?: string; }[]; } = useMemo(() => {
        let nodes: string[] = [], newOptions = {}, i = 0;
        for (const { id } of locationPanels) {
            if (state.selection[id].length) {
                nodes.push(state.selection[id][0]);
            }
            newOptions[id] = i && state.selection[locationPanels[i - 1].id].length > 1 ?
                []
                :
                new Array(i)
                    .fill(0)
                    .reduce((acc, _, idx) =>
                        acc[nodes[idx]] || [], optionsTree[id] || []);
            i++;
        }
        return newOptions;
    }, [optionsTree, state]);
    return { panels, options, lookup };
}
