import { observer } from 'mobx-react-lite'
import React from 'react'
import { restructuredTenderData } from '../../utils/utils';
import { makeStyles, Theme } from "@material-ui/core/styles";
import { useStore } from "../../models/ProvideModel";
import moment from 'moment';
import { getDropdownOption, getTicketsForMultipleProjects } from '../../api/transactionServer';
import LoadingSpinner from '../../components/loadingSkelaton/LoadingSpinner';

const useStyles = makeStyles((theme: Theme) => ({
  tableContainer: {
    overflowX: 'auto',
    height: '100%',
    width: '100%'
  },
  projectTable: {
    tableLayout: 'fixed',
    borderCollapse: 'collapse',
    '& td, th': {
      padding: '0.5em',
      border: '1px solid #ddd',
      textAlign: 'left',
      minWidth: '120px'
    }
  },
  tableCell: {
    padding: '0.5em',
    verticalAlign: 'top',
    border: '1px solid #ddd',
    textAlign: 'left',
  },
  tableRow: {
    display: 'flex',
    marginBottom: '1.5em',
    textAlign: 'left',
    verticalAlign: 'top',
    borderTop: '1px solid #ddd',
  },
  rowBorder: {
    borderBottom: '0.11em solid #999',
  },
  mainHeader: {
    background: 'pink'
  },
  stickyColumn: {
    position: 'sticky',
    left: 0,
    backgroundColor: '#fff',
    zIndex: 1,
    boxShadow: '2px 0px 5px rgba(0, 0, 0, 0.1)',
    minWidth: '120px',
    border: '1px solid #ddd'
  },
  stickyColumn1: { left: 0 },
  stickyColumn9: {
    position: 'sticky',
    backgroundColor: '#fff',
    zIndex: 1,
    boxShadow: '2px 0px 5px rgba(0, 0, 0, 0.1)',
    minWidth: '120px',
    border: '1px solid #ddd',
  },
  tableHeader: {
    padding: '0.5em',
    zIndex: 2,
    border: '1px solid #ddd',
    textAlign: 'left',
    background: '#e9e9e9',
    position: 'sticky',
    top: 0
  },
  tableRowYVal: {
    fontWeight: 600,
    opacity: 0.5
  }

}));

enum tenderStatus {
  IN_PROCESS = 'In Process',
  DETAILS_AWAITED = 'Tender Details Awaited',
  COMPLETED = 'Completed'
}

const TenderTracker = () => {
  // const [tenderData, setTenderData] = React.useState([]);
  const tableRef = React.useRef<HTMLDivElement>(null);
  const [isSticky, setIsSticky] = React.useState(false);
  const classes = useStyles();
  const firstColumnWidth = 120;
  const store = useStore();
  const [tenderDetails, setTenderDetails] = React.useState<{ [K: string]: any }>({});
  const [loading, setLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    const makeAllCallsForTenderData = async () => {
      setLoading(prev => !prev);
      try {
        const getPackages = await getDropdownOption({ customerId: store.auth.customerId, projectId: store.projectInfo.currentProject?.id || '', variableType: 'packagesList' }).catch(error => console.error(error));
        const getPackageDurations = await getDropdownOption({ customerId: store.auth.customerId, projectId: store.projectInfo.currentProject?.id || '', variableType: 'packagesDuration' }).catch(error => console.error(error));
        const getProjectsMetadata = await getDropdownOption({ customerId: store.auth.customerId, projectId: store.projectInfo.currentProject?.id || '', variableType: 'projectsMetadata' }).catch(error => console.error(error));
        const tickets = await getTicketsForMultipleProjects({ tenant: store.auth.customerId, projectIds: store.projectInfo.projects.map(p => p.id), ticketType: 'prestigeTender' }).catch(error => console.error(error));
        const data: any = restructuredTenderData(tickets.data, getPackages.data.data || [], getPackageDurations.data.data || {}, getProjectsMetadata.data.data || {})
        setTenderDetails(prev => ({ ...prev, packages: getPackages.data.data || [], durations: getPackageDurations.data.data || {}, metadata: getProjectsMetadata.data.data || {}, tenderData: data }));
        setLoading(prev => !prev);
      } catch (e) {
        console.log(e);
        setLoading(prev => !prev);
      }
    }

    makeAllCallsForTenderData();

  }, [])

  React.useEffect(() => {
    const handleScroll = () => {
      const tableContainer = tableRef.current;
      if (tableContainer) {
        const scrollLeft = tableRef.current.scrollLeft!;
        const threshold = 840; // Sum of min-widths of first 8 columns (120px each)
        if (scrollLeft >= threshold) {
          setIsSticky(true);
        } else {
          // console.log('Before ----- > scrollLeft', scrollLeft, 'threshold', threshold )
          setIsSticky(false);
        }
      }
    };

    const tableContainer = tableRef.current;
    if (tableContainer) {
      tableContainer?.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (tableContainer) {
        tableContainer.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  // console.log('tenderDetails', tenderDetails)
  if (loading) {
    return <div><LoadingSpinner message={'Preparing report...'} /></div>
  }
  return (
    <div className={classes.tableContainer} style={{ display: 'flex' }} ref={tableRef}>
      <table className={classes.projectTable}>
        <thead>
          <tr>
            <th className={`${classes.stickyColumn} ${classes.tableHeader}`} style={{ zIndex: 3 }}>Project</th>
            <th className={classes.tableHeader}>Location</th>
            <th className={classes.tableHeader}>Dev. Category</th>
            <th className={classes.tableHeader}>VP</th>
            <th className={classes.tableHeader}>ContractLead</th>
            <th className={classes.tableHeader}>Arch</th>
            <th className={classes.tableHeader}>Struc</th>
            <th className={classes.tableHeader}>Ser</th>
            <th className={`${classes.tableHeader} ${classes.stickyColumn} ${isSticky ? classes.stickyColumn9 : ''}`}
              style={isSticky ? { left: `${firstColumnWidth}px`, zIndex: 3 } : {}}
            ></th>
            {tenderDetails.packages && tenderDetails.packages.sort((a, b) => a.id.localeCompare(b.id)).map((xVal, index) => (
              <th
                key={index}
                className={`${classes.tableHeader}`}
              >{xVal.name}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tenderDetails.tenderData && tenderDetails.tenderData.map((projectData: any, projectIndex) => (
            <tr key={projectIndex} className={`${classes.rowBorder}`}>
              <td className={`${classes.tableCell} ${classes.stickyColumn}`}>{store.projectInfo.getCurrentProjectName(projectData.projectName)}</td>
              <td className={`${classes.tableCell}`}>{projectData.location}</td>
              <td className={`${classes.tableCell}`}>{projectData.devCategory}</td>
              <td className={`${classes.tableCell}`}>{projectData.vp}</td>
              <td className={`${classes.tableCell}`}>{projectData.contractLead}</td>
              <td className={`${classes.tableCell}`}>{projectData.arch}</td>
              <td className={`${classes.tableCell}`}>{projectData.struc}</td>
              <td className={`${classes.tableCell}`}>{projectData.ser}</td>
              <td className={`${classes.tableCell} ${classes.stickyColumn} ${isSticky ? classes.stickyColumn9 : ''}`}
                style={isSticky ? { left: `${firstColumnWidth}px` } : {}}
              >
                <div className={`${classes.tableRow} ${classes.tableRowYVal}`}>Duration (M)</div>
                <div className={`${classes.tableRow} ${classes.tableRowYVal}`}>Target Date</div>
                <div className={`${classes.tableRow} ${classes.tableRowYVal}`}>Planned BOQ Upload Date</div>
                <div className={`${classes.tableRow} ${classes.tableRowYVal}`}>BOQ Recvd.</div>
                <div className={`${classes.tableRow} ${classes.tableRowYVal}`}>Closed Date</div>
                <div className={`${classes.tableRow} ${classes.tableRowYVal}`}>Contractor</div>
                <div className={`${classes.tableRow} ${classes.tableRowYVal}`}>Status</div>
                <div className={`${classes.tableRow} ${classes.tableRowYVal}`}>Sub-Status</div>
              </td>
              {tenderDetails.packages && tenderDetails.packages.sort((a, b) => a.id.localeCompare(b.id)).map((xVal, index) => {
                const packageKey = xVal.id.replace(/\s|\./g, "").replace("/", "");
                const packageData = projectData.packagesMap[packageKey] || {};
                return (
                  <td key={index} className={classes.tableCell}>
                    <div className={`${classes.tableRow}`}>{packageData.duration || (tenderDetails.durations[packageKey] / 30) || '-'}</div>
                    <div className={`${classes.tableRow}`}>{(packageData.targetDate && moment(packageData.targetDate).format('DD-MMM-YYYY')) || '-'}</div>
                    <div className={`${classes.tableRow}`}>{(packageData.boqUploadTargetDate && moment(packageData.boqUploadTargetDate).format('DD-MMM-YYYY')) || '-'}</div>
                    <div className={`${classes.tableRow}`}>{(packageData.boqReceivedDate && moment(packageData.boqReceivedDate).format('DD-MMM-YYYY')) || '-'}</div>
                    <div className={`${classes.tableRow}`}>{(packageData.closedDate && moment(packageData.closedDate).format('DD-MMM-YYYY')) || '-'}</div>
                    <div className={`${classes.tableRow}`}>{packageData.contractorName || '-'}</div>
                    <div className={`${classes.tableRow}`} style={{ color: packageData.status === tenderStatus.IN_PROCESS ? '#347acb' : packageData.status === tenderStatus.DETAILS_AWAITED ? '#f2780d' : packageData.status === tenderStatus.COMPLETED ? '#86a244' : '#000', fontWeight: 600 }}>{packageData.status || '-'}</div>
                    <div className={`${classes.tableRow}`}>{packageData.subStatus || '-'}</div>
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default observer(TenderTracker)
