import React from 'react';
import useStyles from './NotCurrentAssigneeStyles';
import { observer } from 'mobx-react-lite';
import { IconUser,IconUserExclamation, IconEyeOff, IconExclamationCircle } from '@tabler/icons-react';


interface INotCurrentAssignee {
    taskName?: string
    userName?: string
    completedTask?: boolean
    completedTasksLength? : boolean
}

const NotCurrentAssignee = ({ taskName, userName, completedTask, completedTasksLength } : INotCurrentAssignee) => {
const classes = useStyles();

return (
    <div className={classes.root}>
        {completedTask && completedTasksLength ? 
        <div className={classes.main}>
        <IconEyeOff style={{opacity: .2, width: '2rem', height: '2rem'}}/>
        <div className={classes.taskDiv}><span className={classes.task}>{taskName}</span> task was completed by <span className={classes.task}>{userName}</span></div>
        <div className={classes.messageDiv}>You cannot view this task currently!</div>
        </div>
        : completedTask && !completedTasksLength ?
        <div className={classes.main}>
        <IconExclamationCircle style={{opacity: .2, width: '2rem', height: '2rem'}}/>
        <div className={classes.taskDiv}>No tasks completed!</div>
        </div>
        :  <div className={classes.main}>
        <IconUserExclamation style={{opacity: .2, width: '2rem', height: '2rem'}}/>
        <div className={classes.taskDiv}><span className={classes.task}>{taskName}</span> task is assigned to <span className={classes.task}>{userName}</span></div>
        <div className={classes.messageDiv}>No action can be taken here!</div>
        </div>}
    </div>
)
}

export default observer(NotCurrentAssignee)