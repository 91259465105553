import React, { memo } from "react";
import { Badge } from '@mantine/core';
import {
  MRT_Cell, MRT_Row,
} from 'mantine-react-table';
import { requestPrioritiesColors } from "../../../utils/constants";

const RequestPriority = ({ row: { original }, cell }: { row: MRT_Row<any>; cell: MRT_Cell<any>; }) => {
  const value = original['priority'];
  return <Badge
    color={requestPrioritiesColors[value]?.bg || "#FFF"}
    size="xs"
    sx={{
      backgroundColor: requestPrioritiesColors[value]?.bg || "#FFF",
      borderColor: requestPrioritiesColors[value]?.border || "#FFF",
      color: requestPrioritiesColors[value]?.color || "#FFF"
    }}
  >{cell.getValue() || value}</Badge>
}

export default RequestPriority;
