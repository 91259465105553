import React from 'react'
import Retool from 'react-retool'
import { useStore } from '../../../models/ProvideModel';
import { observer } from 'mobx-react-lite'

interface IRenderRetoolProps {
    url: string,
    customerId: string
}

const RenderRetool = ({ url, customerId } : IRenderRetoolProps) => {
  const store = useStore();
  const environment = process.env.REACT_APP_ENV === 'prd' ? 'production' : process.env.REACT_APP_ENV;
  const environmentFilter = `?_environment=${environment}`;
  const fullURL = `${url}${environmentFilter}`

  React.useEffect(() => {
    // console.log(`current customer - ${customerId}`)
  },[customerId])
  
  const metadata = { 
    userId: store.auth.userName || 'user', 
    currentProject: store.projectInfo.currentProject?.id, 
    token: store.auth.token || localStorage.getItem('Authorization'), 
    loggedInUser: store.auth.userId, 
    customerId 
}
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <Retool url={fullURL} key={metadata.token} data={metadata} />
    </div>
  )
}

export default observer(RenderRetool)