import React, { useEffect, useCallback, useRef, memo } from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory, useLocation, useParams } from 'react-router';
import { chan } from 'csp-with-ts';
import { IconCalendar, IconFlag, IconBuilding } from '@tabler/icons-react';
import { onPatch, resolveIdentifier, resolvePath } from 'mobx-state-tree';
import { useStore } from '../../models/ProvideModel';
import { getStartProcessDefinitionKeys, getFeatureConfig2 } from '../../api/transactionServer';
import useStyles from "./RequestsModuleStyles";
import GenericModal from '../../containers/GenericModal';
import client from '../../utils/apolloClient';
import { GET_TICKET_GLOBAL_PARAMS } from '../../utils/queries';
import NewTicketPopup from '../../containers/requestsFormPopup/NewTicketPopup';
import LoadingTowers from '../../components/LoadingTowers';
import RequestTable from '../../components/requests/MantineTable';
import { useGetState } from '../../components/filter/UseAccordionHook';
import GenericInput from '../../components/FormInputs/GenericInput';
import FormDropdown from '../../components/filter/FormDropdown';
import DateTimeInput from '../../components/FormInputs/DateTimeInput';
import TicketPopup from '../../containers/ticketPopup';
import { FeatureGranularity, NotificationEventTypes } from '../../models/enums';
import { useColumnsDef, gridKeys, useTableOptions } from './gridColumns';
import RequestsModuleToolbar from './RequestsModuleToolbar';
import { useMarkNotificationsAs } from '@novu/notification-center';
import { INotification, NotificationState } from '../../models/Notifications';
import { Flex } from '@mantine/core';
import { MRT_GlobalFilterTextInput } from 'mantine-react-table';
import { Ticket } from '../../models/Ticket';
import useFilterListener from './RequestsModuleFiltersListener';
import TicketExcelImport from '../../containers/TicketExcelImport/TicketExcelImport';
import { reaction, trace } from 'mobx';

// TODO: Remove hardcode sometime
const __module = "requests";
const __watchersVariable = "allowedWatchers";
// TODO: Eventually refactor feature configs to make calls and execute logic dynamically

const __features = {
  editableAssignee: { feature: "editableAssignee", pathParam: "editableAssignee", configKeys: ["targetPool", "isEditable"], granularity: FeatureGranularity.TICKET_TYPE, },
  ticketDefMetadata: { feature: "ticketDefMetadata", pathParam: "ticketDefMetaInfo", configKeys: ["metadata"], granularity: FeatureGranularity.TICKET_TYPE, },
  coreFieldControl: { feature: "coreFieldControl", pathParam: "coreFieldControl", configKeys: ["attributes", "isEditable"], granularity: FeatureGranularity.TICKET_TYPE, },
  filteredViews: { feature: "filteredViews", pathParam: "filteredViews", configKeys: ["views", "columnOverrides"], granularity: FeatureGranularity.MODULE, },
  moduleDefinition: { feature: "definition", pathParam: "definition", configKeys: ["categoryList"], granularity: FeatureGranularity.MODULE, },
};

const __coreFields = {
  title: { key: "name", name: "Title", Component: GenericInput, type: "text", description: "Title of the request. Keep it brief", isMandatory: true },
  description: { name: "Description", Component: GenericInput, type: "multi-line-text", description: "Can be a multi-line description about the request", isMandatory: false },
  priority: { name: "Priority", Component: FormDropdown, type: "dropdown", description: "Priority of the request", isMandatory: true, icon: <IconFlag width={'1em'} height={'1em'} /> },
  dueDate: { name: "Due Date", Component: DateTimeInput, type: "date", description: "Due date for the request", isMandatory: false, icon: <IconCalendar width={'1em'} height={'1em'} /> },
  category: { name: "Category", Component: FormDropdown, type: "dropdown", description: "Category of the request", isMandatory: false },
  propertyId: { name: "Property", Component: FormDropdown, type: "dropdown", description: "Property for which the request is being generated", isMandatory: false, icon: <IconBuilding width={'1em'} height={'1em'} /> },
  processStatus: { key: "status", name: "Status", Component: FormDropdown, type: "dropdown", description: "Status", isMandatory: false },
  watchersCC: { key: "watchers", name: "Watchers (CC)", Component: FormDropdown, type: "dropdown", description: "Watchers (CC)", isMandatory: false, skip: true, },
  location: { key: "location", name: "Location", Component: FormDropdown, type: "dropdown", description: "Location", isMandatory: false, skip: true, },
  startDate: { key: "startDate", name: "Start Date", Component: DateTimeInput, type: "date", description: "Scheduled Start Date", isMandatory: false, },
};

const __specialFilters = [
  { id: "assignedToMe", label: "Assigned to Me", filterIds: ["assignee"] },
  { id: "unread", label: "New or Updated tickets", filterIds: ["unread"] },
  { id: "delayed", label: "Delayed", filterIds: ["delayed"] },
];
// Are the filters composable?
/* const setSpecialFilters = (store, { params, ...rest }) => {
 *   store.ticket.setGridFilters(__specialFilters.map(({ id, filterIds, ...resht }) => ({ isActive: !!params.get(id) ? true : false, id, filterIds, value: filterIds.map(fId => ({ id: fId, value: rest[fId] })), ...resht })));
 * }; */

const formatSpecialFilters = ({ params, ...rest }) =>
  __specialFilters.map(({ id, filterIds, ...resht }) => ({
    isActive: !!params.get(id) ? true : false,
    id,
    filterIds,
    value: filterIds.map(fId => ({ id: fId, value: rest[fId] })), ...resht
  }));


const ToolbarComponent = () => <RequestsModuleToolbar />;
/* TODO: Currently not filtering process definitions by module id since section name is screwed. */
const RequestsModule = () => {
  const store = useStore();
  const columnsDef = useColumnsDef(store);
  const tableOptions = useTableOptions(store);
  const classes = useStyles();
  const { view } = useParams<any>();
  const { search } = useLocation();
  const { push } = useHistory();
  const path = useRef<{ search: URLSearchParams; }>({
    search: new URLSearchParams(search),
  });
  const ch = useRef(chan<{ [K: string]: string; }>());
  const coreFieldsValue = useGetState(ch.current);
  /* useEffect(() => { store.ticket.setCurrentView(view); }, [view]); */
  const { mutateAsync } = useMarkNotificationsAs({});

  const getTickets = useCallback(() => {
    return store.ticket.getTicketsList();
  }, []);

  useEffect(() => {
    // TODO: Revert if slower
    /* store.ticket.setCoreFields(__coreFields);
     * store.ticket.setFeatureConfig(__features);
     * store.ticket.addGridKeys(gridKeys); */
    /* setSpecialFilters(store, { params: path.current?.search, assignee: store.auth.userId, unread: true }); */

    // Init client
    if (!client.client) { client(); }
    // Patches and path related stuff
    store.ticket.setCurrentView(view);
    const p1 = onPatch(store.ticket, ({ path: pth, value }, { value: oldVal }) => {
      if (pth === '/currentTicket' || pth === '/currentTicketDefinition') {
        const urlQueryParam = pth === '/currentTicket' ? 'ticketId' : 'ticketType';
        if (value !== oldVal && path.current?.search.get(urlQueryParam) != value) {
          path.current?.search.delete(urlQueryParam);
          if (value !== null && value !== undefined) {
            path.current?.search.set(urlQueryParam, value);
          }
          push({ pathname: `${store.params.path}${store.ticket.currentView}`, search: path.current?.search.toString() });
        }
      }
      else if (pth === '/currentView' && value !== oldVal) {
        push({ pathname: `${store.params.path}${store.ticket.currentView}`, search: path.current?.search.toString() });
      }
    });
    const p2 = onPatch(store.notifications.notificationsQ, ({ op, path, value }) => {
      if (op === 'add') {
        if (store.ticket.currentTicket?.id === value.ticketId) {
          switch (value.eventType) {
            case NotificationEventTypes.COMMENT_ADDED:
              store.ticket.currentTicket?.refreshComments();
              store.ticket.currentTicket?.setLastUpdated();
              break;
            case NotificationEventTypes.TASK_ASSIGNED:
              /* store.ticket.currentTicket?.updateAssignee(value.taskId, value.userId, store.ticket.currentTicket!.tasks.find(({ taskId }) => taskId === value.taskId)?.assignee[0] || ""); */
              if (store.ticket.currentTicket && !store.ticket.currentTicket.tasks.includes(value.taskId)) {
                store.ticket.currentTicket.setStale(true);
                break;
              }
              store.ticket.currentTicket?.replaceAssigneeForTask(value.taskId, value.userId);
              break;
            case NotificationEventTypes.CORE_FIELD_CHANGED:
              if (value.updatedUserId !== store.auth.userId) {
                store.ticket.currentTicket?.setCoreField(value.updatedField, value.new); store.ticket.currentTicket?.refreshHistory();
              }
              break;
            case NotificationEventTypes.TASK_COMPLETED:
              break;
            case NotificationEventTypes.TICKET_DELETED:
              store.ticket.currentTicket?.delete();
              break;
            default: break;
          }
        }
        else if (value.eventType === NotificationEventTypes.TICKET_DELETED) {
          const t = resolveIdentifier(Ticket, store.ticket.tickets, value.ticketId);
          if (t) {
            store.ticket.removeTicket(t);
          }
        }
        else {
          store.ticket.refreshCoreTicket(value.ticketId, true);
        }
      }
      else if (op === 'replace' && (path.indexOf("notificationStatus") > -1)) {
        const noti: INotification = store.notifications.notificationsQ[path.slice(1, path.slice(1).indexOf('/') + 1)];
        mutateAsync({ messageId: noti.notificationId, seen: store.notifications.novuMap.get(noti.notificationId)?.seen || false, read: value === NotificationState.READ })
          .catch(err => { console.error(err); })
      }
    });


    // inits
    if (store.ticket.ticketTypes.length) {
      if (path.current?.search.has('ticketType')) {
        store.ticket.setCurrentTicketDefinition(path.current?.search.get('ticketType'));
      }
    }
    else {
      store.ticket.setModuleMetadata({
        coreFields: __coreFields,
        featureConfig: __features,
        gridKeys,
        gridFilters: formatSpecialFilters({ params: path.current?.search, assignee: [store.auth.userName], unread: true, delayed: true })
      });
      store.ticket.getAllowedWatchers(__watchersVariable);
      getStartProcessDefinitionKeys({
        user: store.auth.userId, customer: store.auth.customerId, project: store.projectInfo.currentProject?.id
      }).then(({ data }) => {
        store.ticket.setTicketTypes((data.processDefinitions || []).reduce((acc, obj) => obj["module_id"] === __module /*&&  obj["module_enabled"] */ ? [...acc, {
          ...Object.keys(obj)
            .reduce((acc, k) => ({
              ...acc,
              [k.split('_')
                .map((str, i) => i ?
                  `${str[0].toUpperCase()}${str.slice(1)}`
                  : str)
                .join('')]: obj[k]
            })
              , {})
        }] : acc, []));
        if (path.current?.search.has('ticketType')) {
          store.ticket.setCurrentTicketDefinition(path.current?.search.get('ticketType'));
        }
        // Get all TicketDefinition level granularity features here.
        getFeatureConfig2({
          tenant: store.auth.customerId,
          payload: {
            projectIdList: [store.projectInfo.currentProject.id],
            moduleIdList: [__module],
          }
        }).then(({ data }) => {
          store.ticket.setFeaturesForModule(data[store.projectInfo.currentProject.id][__module]);
        }).catch(error => console.error(error));
        return getFeatureConfig2({
          tenant: store.auth.customerId,
          payload: {
            projectIdList: [store.projectInfo.currentProject.id],
            moduleIdList: [__module],
            ticketDefinitionList: store.ticket.ticketTypes.map(tT => tT.typeId)
          }
        });
      }).then(({ data }) => {
        store.ticket.setFeaturesForAllTicketTypes(data[store.projectInfo.currentProject.id][__module]);
      }).catch(error => console.error(error));
    }
    // get Filtered Views Permissions
    store.ticket.getPermissions();
    // get tags
    store.ticket.getTags();
    // get tickets
    getTickets().then(() => {
      if (path.current?.search.has('ticketId')) {
        store.ticket.setCurrentTicket(path.current?.search.get('ticketId'));
      }
    });
    if (!(store.ticket.priorities.length && store.ticket.statuses.length)) {
      // Replace the hasura query if possible
      client.client?.query({
        query: GET_TICKET_GLOBAL_PARAMS,
        errorPolicy: "all",
        fetchPolicy: "network-only"
      }).then(({ data: { priorities, statuses } }) => {
        store.ticket.setPriorities(priorities);
        store.ticket.setStatuses(statuses);
      }).catch(err => { console.error(err); });
    }

    if (store.projectInfo.currentProject &&
      !store.projectInfo.currentProject.loading &&
      !store.userReportDetails.powerbiDetails.length &&
      !store.userReportDetails.activityManagerDetails.length &&
      !store.userReportDetails.materialToolDetails.length &&
      !store.userReportDetails.adminToolDetails.length) {
      store.userReportDetails.getUserReportDetails();
    }
    return () => {
      ch.current.close();
      p1();
      p2();
    }
  }, []);


  useEffect(() => {
    const srch = new URLSearchParams(search);
    path.current.search = srch;
    store.ticket.gridFilters.forEach(filt => {
      if (!!srch.has(filt.id) !== filt.isActive) {
        filt.setActive(!!srch.has(filt.id));
      }
    });
    store.ticket.setCurrentTicket(srch.get('ticketId') || undefined);
    store.ticket.setCurrentTicketDefinition(srch.get('ticketType') || undefined);
  }, [search]);

  useEffect(() => {
    if (view !== store.ticket.currentView) {
      store.ticket.setCurrentView(view);
    }
  }, [view, store.projectInfo.currentProject]);

  const onClose = useCallback(() => {
    if (store.ticket.currentTicket) { store.ticket.setCurrentTicket(); }
    if (store.ticket.currentTicketDefinition) { store.ticket.setCurrentTicketDefinition(); }
    if (store.ticket.bulkImport.isOpen) { store.ticket.bulkImport.setOpen(false); }
    /* if (store.ticket.currentTicket) {
     *   path.current?.search.delete('ticketId');
     * }
     * if (store.ticket.currentTicketDefinition) {
     *   path.current?.search.delete('ticketType');
     * }
     * push({ ...(path.current?.location || {}), search: path.current?.search.toString() }); */
  }, []);
  const refreshTicket = useCallback((id: string) => {
    return store.ticket.refreshCoreTicket(id);
  }, []);


  const rowCallback = useCallback((row: any) => {
    if (store.ticket.currentTicket?.id !== row.original["id"]) {
      store.ticket.setCurrentTicket(row.original["id"]);
    }
  }, []);

  const updateCoreField = useCallback((delta) => {
    if (store.ticket.currentTicket && delta.field && (delta.value !== null || delta.value !== undefined)) {
      if (delta.field === "assignee") {
        const assignee = store.ticket.currentTicket.currentTask?.assignee;
        if (delta.value !== (assignee && assignee[0])) {
          return store.ticket.currentTicket.updateAssignee({
            taskId: store.ticket.currentTicket.currentTask?.taskId || "",
            newAssignee: delta.value,
            oldAssignee: ((assignee && assignee[0]) || ""),
            userId: store.auth.userId
          });
        }
        return Promise.resolve();
      }
      if (store.ticket.currentTicket[store.ticket.coreFields[delta.field].key || delta.field] !== delta.value) {
        return store.ticket.currentTicket.updateCoreField(delta.field, delta.value);
      }
    }
    return Promise.resolve();
  }, []);

  useEffect(() => {
    updateCoreField(coreFieldsValue)
      .catch(err => { console.error(err); });
  }, [coreFieldsValue]);

  /**Special filter remove handler */
  //ToDO: Generalize this function. Currently it is not

  const getFilteredRowCount = useCallback((rowCount: number) => {
    store.ticket.setFilterRowsCount(rowCount);
    /* setFilteredRowsCount(rowCount) */
  }, []);

  return (
    <div className={classes.root}>
      <div style={{ display: "flex", flexDirection: "column", height: "100%", width: "100%" }}>

        {/* <div className={classes.title}>Requests/Approvals</div> */}
        <GenericModal
          loading={store.ticket.currentTicket?.popupLoading || store.ticket.currentTicketDefinition?.loading || false}
          isOpen={store.ticket.popupIsOpen}
          onClose={onClose}
          actions={[]}
          fullWidth
          maxWidth={store.ticket.currentTicketDefinition ? "sm" : "lg"}
          // bgColor={materialStatusColours[store.ticket.currentTicket?.statusCategory || "open"]?.bg}
          bgColor={"#999999"}
        >
          {store.ticket.currentTicket
            ? (
              <div
                className={classes.popupRoot}
              >
                <TicketPopup
                  ticket={store.ticket.currentTicket}
                  priorities={store.ticket.priorities}
                  coreFields={store.ticket.coreFields}
                  statuses={store.ticket.statusesByType[store.ticket.currentTicket?.type || ""] || []}
                  ch={ch.current}
                  onClose={onClose}
                  customerId={store.auth.customerId}
                  userId={store.auth.userId}
                  lg={store.responsiveUtils.currentViewport}
                  projectId={store.projectInfo.currentProject.id}
                  refreshTicket={refreshTicket}
                  tags={store.ticket.tags}
                  categories={store.ticket.categories}
                />
              </div>
            )
            :
            store.ticket.currentTicketDefinition
              ?
              (<div className={classes.popup}>
                <NewTicketPopup
                  properties={store.projectInfo.currentProject.properties.map(({ id }) => id)}
                  projectId={store.projectInfo.currentProject.id}
                  priorities={store.ticket.priorities}
                  lg={store.responsiveUtils.currentViewport}
                  customerId={store.auth.customerId}
                  userId={store.auth.userId}
                  onClose={onClose}
                  refreshTickets={getTickets}
                  coreFields={store.ticket.coreFields}
                  ticketType={store.ticket.currentTicketDefinition}
                  allowedWatchers={store.ticket.allowedWatchersArray}
                  watchersMap={store.ticket.allowedWatchers}
                  categories={store.ticket.categories}
                />
              </div>)
              :
              store.ticket.bulkImport.isOpen
                ?
                (
                  <TicketExcelImport importModel={store.ticket.bulkImport} />
                )
                : null
          }
        </GenericModal>
        <div style={{ display: "flex", height: "100%", width: "100%", flexGrow: 1, flexShrink: 1 }}>

          {store.ticket.loading ?
            <LoadingTowers title={"Fetching requests"} /> :
            <RequestTable
              data={store.ticket.ticketsGrid}
              rowCallback={rowCallback}
              setRowCount={getFilteredRowCount}
              columns={columnsDef}
              ToolbarComponent={ToolbarComponent}
              useFilterListeners={useFilterListener}
              tableOptions={tableOptions}
            >
            </RequestTable>}
        </div>
      </div>
    </div >
  );
};


export default observer(RequestsModule);
