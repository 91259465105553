import React, { memo, useEffect, useReducer, MemoExoticComponent, FunctionComponent } from 'react';
import useStyles from './DetailsStyles';
import moment from 'moment';
import GenericInput, { IGenericInputProps } from '../../../../components/FormInputs/GenericInput';
import { ITicket } from '../../../../models/Ticket';
import { IChan, putAsync } from 'csp-with-ts';
import { observer } from 'mobx-react-lite';
import DateTimeInput, { IDateTimeInputProps } from '../../../../components/FormInputs/DateTimeInput';
import FormDropdown, { IFormDropdownInputProps } from '../../../../components/filter/FormDropdown';
import { transformTime, transformDateTime, transformPercent, formEditReducer, transformNumeric } from '../../../../utils/utils';
import MantineImageField from '../../../../components/FormInputs/MantineImageField';
import { IImagePickerProps } from '../../../../components/FormInputs/ImagePicker';
import GeneralFormLoader from '../../others/generalFormLoading/GeneralFormSkeleton';
import GeneralFormView from './GeneralForm'
import RichTextDescription from '../../../../components/richText/RichTextDescInput'
import RichTextOutput from '../../../../components/RichTextOutput';

export interface ITicketDetails {
  ticket: ITicket;
  coreFields: { [K: string]: any; };
  ch: IChan;
  activeTab?: string | null;
}

const initialState = ({ coreFields, ...ticket }) => {
  var ret = { delta: {} };
  for (const [id, { isEditable }] of Object.entries<{ [K: string]: any; }>(coreFields)) {
    if (isEditable) {
      if (id === 'title') {
        ret['title'] = ticket.name;
      }
      ret[id] = ticket[id];
    }
  }
  return ret;
};

const __transformMap = {
  'date': { transform: (val: string) => val.length ? moment(val).format('YYYY-MM-DD') : "", Component: DateTimeInput },
  'time': { transform: transformTime, Component: DateTimeInput },
  'datetime': { transform: transformDateTime, Component: DateTimeInput },
  'percent': { transform: transformPercent, Component: GenericInput },
  'numeric': { Component: GenericInput, transform: transformNumeric },
  'integer': { Component: GenericInput, transform: transformNumeric },
  'decimal': { Component: GenericInput, transform: transformNumeric },
  'text': { Component: GenericInput, transform: transformNumeric },
  'multi-line-text': { Component: GenericInput, transform: transformNumeric },
  'dropdown': { Component: FormDropdown, transform: val => val },
  'upload': { Component: MantineImageField, transform: (val) => val.length ? val[0] : null }
  /* 'photo': { transform: transformPhoto, Component: ImagePicker } */
};

const TempFallback = memo(({ label, ...rest }: IGenericInputProps) => (<div>{label}</div>));

const transformMap = (prop: string) => {
  const orig = Reflect.get(__transformMap, prop);
  if (orig !== undefined) {
    return orig;
  }
  return { Component: TempFallback, transform: transformNumeric };
};


/**Reducer */
function reducer(state: { [K: string]: any; }, action: { type: string; payload: { field: string; input: string; type: string }; }) {
  return {
    ...state, [action.payload.field]: action.payload.type === "date" ? moment(action.payload.input).toISOString() : action.payload.input,
    delta: { field: action.payload.field, value: action.payload.type === "date" ? moment(action.payload.input).toISOString() : action.payload.input }
  } as { [K: string]: any; };
}

const TicketDetails = ({ ticket, coreFields, ch }: ITicketDetails) => {
  const classes = useStyles()
  const [state, dispatch] = useReducer(reducer, { ...ticket, coreFields }, initialState);
  useEffect(() => {
    putAsync(ch, { ...state.delta });
  }, [state]);
  useEffect(() => {
    ticket.getGeneralForm();
  }, []);
  return (
    <div className={classes.root}>
      <div className={classes.handleContainer}>
        {!!coreFields["description"]?.hidden ? null : (
          <>
          <span style={{padding: '1em 0em', fontSize: '0.9575em', fontWeight: 600, color: '#585858'}}>{coreFields['description']?.name || "Description"}</span>
        {
          !coreFields['description'].isEditable ?
          <RichTextOutput content={state['description'] || (ticket && ticket.description) || "-"}/> :
          <RichTextDescription
            field={'description'}
            dispatch={dispatch}
            state={state['description'] || (ticket && ticket.description) || ""}
            type='text'
            label={coreFields['description'].name}
            size={'xs'}
disabled={!coreFields['description'].isEditable}
       debounce={true}
       required={undefined}
       description={undefined}
       icon={undefined}          
       placeholder={""}
       />}
          </>
        )
        }
        {ticket.generalForm?.fields.length ?
         <GeneralFormView fields={ticket.generalForm?.fields} />
        : ticket.loading ? <GeneralFormLoader /> : null}
</div>
    </div>
  )
};

export default observer(TicketDetails);
