import { Button } from "@mantine/core";
import { observer } from "mobx-react-lite";
import React from "react";
import { ActivityStatuses } from "../../../models/enums";
import { IUnitActivity } from "../../../models/Planning";
import { activityStatusColours } from "../../../utils/constants";

const ActivityStatus = ({ row }: { row: IUnitActivity }) => (
  <div style={{
    display: 'flex',
    height: "100%",
    alignItems: "center",
    flexDirection: 'row',
    fontFamily: "Source Sans Pro, sans-serif !important",
  }}>
    <div>
      <div style={{
        width: 10,
        height: 10,
        backgroundColor: activityStatusColours[row.statusId]?.bg || "white",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        boxSizing: "content-box",
        border: `1px solid ${activityStatusColours[row.statusId]?.border || "white"}`,
        borderRadius: "50%"
      }}
      >
      </div>
    </div>
    <div style={{ marginLeft: "1em", display: 'flex', flexDirection: 'column', alignSelf: 'center' }}>
      <span style={{ fontSize: "0.875em", fontWeight: 500 }}>{row.statusName}</span>
    </div>
    {(row.statusId === ActivityStatuses.PLANNED || row.statusId === ActivityStatuses.CONFIGURED) ?
      <div style={{ marginLeft: "1em", display: 'flex', flexDirection: 'column', alignSelf: 'center' }}>
        <Button onClick={() => { row.startActivity(); }}>
          <span style={{ fontSize: "0.875em", fontWeight: 600 }}>Start</span>
        </Button>
      </div>
      : null
    }
  </div>
);

export default observer(ActivityStatus);
