import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        padding: '0em 1.5em'
    },
    main: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        marginBottom: '1em'
    }
}))

export default useStyles;