import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    background: '#FFF',
    justifyContent: 'flex-start',
    padding: '0.8em 1.5em',
    marginBottom: "4%"
  },
  handleContainer: {
    display: "flex",
    overflow: "auto",
    flexBasis: "auto",
    flexDirection: 'column',
    width: "100%"
  }
}))

export default useStyles;
