import { IMessage } from '@novu/notification-center';
import { types, Instance, getRoot, SnapshotOrInstance } from 'mobx-state-tree';
import moment from 'moment';
import { LateStoreModel } from './DataStore';
import { capitalizeNames, NotificationEventTypes } from './enums';

export enum NotificationState {
    READ = "READ",
    UNREAD = "UNREAD"
};

// id = message.id,
// customerId = message.customerId,
// eventType = message.eventType,
// status = message.status,
// assignee = message.assignee,
// assigneeName = message.assigneeName,
// taskId = if(message.taskId.isNullOrEmpty()) "" else message.taskId,
// taskName = if(message.taskName.isNullOrEmpty()) "" else message.taskName,
// ticketId = message.ticketId,
// ticketTitle = if(message.ticketTitle.isNullOrEmpty()) "" else message.ticketTitle,
// createdAt = message.createdAt,
// usersList = message.usersList,
// newDueDate = message.newDueDate,
// oldDueDate = message.oldDueDate,
// oldPriority = message.oldPriority,
// newPriority = message.newPriority,
// comment = message.comment,
// attachment = message.attachment,
// message = message.message


/*
  enum class BusinessEventTypes {
  task_assigned,
  comment_added,
  due_date_changed,
  priority_changed,
  due_date_crossed,
  task_pending,
  task_completed,
  custom_notification
  }
*/

// TODO Refactor or worry about it after Novu?
//TODO make this enum?
// TODO: Eventually remove hardcoding of core field ids.
const Notification = types.model("Notification", {
    notificationId: types.identifier,
    id: types.string,
    title: types.optional(types.string, ""),
    body: types.optional(types.string, ""),
    taskId: types.optional(types.string, ""),
    status: types.string,
    eventType: types.string,
    notificationStatus: types.frozen<NotificationState>(),
    ticketId: types.string,
    createdAt: types.string,
    ticketTitle: types.optional(types.string, ""),
    taskName: types.optional(types.string, ""),
    userName: types.optional(types.string, ""),
    comment: types.optional(types.string, ""),
    dueDate: types.optional(types.string, ""),
    old: types.optional(types.string, ""),
    new: types.optional(types.string, ""),
    message: types.optional(types.string, ""),
    updatedField: types.maybeNull(types.string),
    updatedUserId: types.maybeNull(types.string),
    updatedUserName: types.maybeNull(types.string),
    userId: types.optional(types.string, ""),
    moduleId: types.optional(types.string, "requests"),
    ticketType: types.optional(types.string, ""),
    commentedUserId: types.optional(types.string, ""),
    commentedUserName: types.optional(types.string, ""),
    deletedUserName: types.optional(types.string, ""),
    createdUserName: types.optional(types.string, ""),
    projectId: types.optional(types.string, ""),
    ticketTypeName: types.optional(types.string, ""),
    claimedUserId: types.maybeNull(types.string),
    claimedUserName: types.maybeNull(types.string),
}).views(self => ({
    get statusName() {
        return (getRoot(self) as Instance<typeof LateStoreModel>).ticket.statusesByType[self.ticketType]?.find(({ id }) => id === self.status)?.name || self.status;
    }
})).views(self => ({
    get notificationCardBody() {
        switch (self.eventType) {
            case NotificationEventTypes.TASK_ASSIGNED: return `${self.taskName ? `Task ${self.taskName} assigned to` : `Task assigned to`} ${self.userId === (getRoot(self) as Instance<typeof LateStoreModel>).auth.userId ? "You" : self.userName}!`;
            case NotificationEventTypes.COMMENT_ADDED: return `${self.commentedUserName} added a new comment!`;
            case NotificationEventTypes.CORE_FIELD_CHANGED:
                let oldName = self.old, newName = self.new;
                if (self.updatedField === "priority") {
                    oldName = (getRoot(self) as Instance<typeof LateStoreModel>).ticket.priorities.find(({ id }) => id === self.old)?.name || self.old;
                    newName = (getRoot(self) as Instance<typeof LateStoreModel>).ticket.priorities.find(({ id }) => id === self.new)?.name || self.new;
                }
                // TODO: wtf why is this with space???
                else if (self.updatedField === "due date") {
                    oldName = self.old == "" ? 'Nill' : moment(self.old).format("DD MMM YY");
                    newName = self.new == "" ? 'Nill' : moment(self.new).format("DD MMM YY");
                }
                else if (self.updatedField === "status") {
                    oldName = (getRoot(self) as Instance<typeof LateStoreModel>).ticket.statusesByType[self.ticketType]?.find(({ id }) => id === self.old)?.name || self.old;
                    newName = (getRoot(self) as Instance<typeof LateStoreModel>).ticket.statusesByType[self.ticketType]?.find(({ id }) => id === self.new)?.name || self.new;
                }
                return `${self.updatedUserName?.length ? `${self.updatedUserName} changed  ${self.updatedField} ` : `${self.updatedField ? capitalizeNames(self.updatedField) : ""} was updated `} from ${oldName} to ${newName}`;
            case NotificationEventTypes.TASK_COMPLETED: return self.userName.length ? `${self.userName} completed Subtask ${self.taskName}` : `Subtask ${self.taskName} was completed!`;
            case NotificationEventTypes.DUE_DATE_CROSSED: return `Due Date ${moment(self.dueDate).format("DD MMM YY")} was crossed!`;
            case NotificationEventTypes.TASK_PENDING: return "Task is Pending!";
            case NotificationEventTypes.MESSAGE_NOTIFICATION: return self.message.length ? self.message : self.body.length ? self.body : `Message in ${self.taskName}`;
            case NotificationEventTypes.TICKET_CREATED: return `${self.createdUserName} created a ticket`;
            case NotificationEventTypes.TICKET_DELETED: return `${self.deletedUserName} deleted a ticket`;
            case NotificationEventTypes.TASK_CLAIMED: return `${self.claimedUserName} assigned task ${self.taskName} to themselves.`;
            case NotificationEventTypes.TASK_ASSIGNED_TO_BE_CLAIMED: return `${self.taskName} is currently unassigned.`;
            default: return `A new notification received!;
`;
        }
    }
})).actions(self => ({
    mark() {
        if (self.notificationStatus === NotificationState.UNREAD) {
            self.notificationStatus = NotificationState.READ
        }
    },
    getProjectNameAndTicketTypeTitle(projectId: string, ticketTypeName: string) { //gets <Project Name> - <Ticket Type>
        const projectName = (getRoot(self) as Instance<typeof LateStoreModel>).projectInfo.getCurrentProjectName(projectId);
        return ticketTypeName ? `${projectName} - ${ticketTypeName}` : projectName;
    }
}));

export const Notifications = types.model("Notifications", {
    token: types.optional(types.string, ""),
    appId: types.optional(types.string, ""),
    notificationsQ: types.array(Notification),
    novuMap: types.map(types.frozen<Omit<IMessage, 'payload'>>())
}).views(self => ({
    get unread(): number {
        return self.notificationsQ.filter(({ notificationStatus }) => notificationStatus === NotificationState.UNREAD).length;
    }
})).actions(self => ({
    setToken(token: string) { self.token = token; },
    setAppId(id: string) {
        if (id !== self.appId) {
            self.appId = id;
        }
    },
    // addNotification(notification: SnapshotOrInstance<INotification>) {
    //     self.notificationsQ.push(notification);
    // },
    addNotification({ payload, _id, ...message }: IMessage) {
        console.log('addNotification was called ->  payload -> ', payload)
        self.notificationsQ.push({
            ...(payload as Omit<SnapshotOrInstance<INotification>, 'notificationId' | 'notificationStatus'>), notificationId: _id, notificationStatus: message.read ? NotificationState.READ : NotificationState.UNREAD
        });
        self.novuMap.set(_id, { _id, ...message });
    },
    markNotificationAsRead(task: string) {
        self.notificationsQ.find(({ taskId }) => task === taskId)?.mark();
    }
})).actions(self => ({
    afterAttach() {
        if (localStorage.getItem('notificationsQ')) {
            self.notificationsQ.push(...JSON.parse(localStorage.getItem('notificationsQ') || '[]'));
        }
    }
}));


export interface INotification extends Instance<typeof Notification> { };
export interface INotifications extends Instance<typeof Notifications> { };
