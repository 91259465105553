import { Box, Indicator } from '@mantine/core';
import { MRT_Row } from 'mantine-react-table';
import React, { ReactNode } from 'react';

const TitleCell = ({ renderedCellValue, row, }: { renderedCellValue: number | string | ReactNode; row: MRT_Row<any>; }) => {
  if (row.original["unread"]) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '16px',
          textWrap: "balance",
        }
        }
      >
        <Indicator inline processing size={8} position="top-start" zIndex={0}>
          <span >{renderedCellValue}<span style={{ color: "#005eff", fontSize: "0.75em", opacity: 0.6 }}>{` (${row.original['taskId'].length} ${row.original['taskId'].length > 1 ? 'tasks' : 'task'})`}</span></span>
        </Indicator>
      </Box >
    )
  }
  else {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '16px',
          textWrap: "balance",
        }}
      >
        <span>{renderedCellValue}<span style={{ color: "#005eff", fontSize: "0.75em", opacity: 0.6 }}>{` (${row.original['taskId'].length} ${row.original['taskId'].length > 1 ? 'tasks' : 'task'})`}</span></span>
      </Box >
    )
  }
};

export default TitleCell;
