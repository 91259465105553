import React, { useEffect } from 'react';
import { MRT_TableInstance } from 'mantine-react-table';
import { RequestTicketDetails } from './gridColumns';
import { onPatch } from 'mobx-state-tree';
import { useStore } from '../../models/ProvideModel';

export default function useMantineFiltersListener(table: MRT_TableInstance<RequestTicketDetails>) {
  const store = useStore();
  useEffect(() => {
    if (store.ticket.ticketsGrid.length) {
      const columnFilters = store.ticket.gridSpecialFilters.reduce((acc, { isActive, id, value: v }) => isActive ? [...acc, { id, value: v }] : acc, [])
      table.setState(({ ...rest }) => ({
        ...rest,
        columnFilters
      }));
      columnFilters.forEach(({ id }) => {
        const col = table.getColumn(id);
        col.columnDef.enableColumnFilter = false;
      });
    }
    const p = onPatch(store.ticket.gridFilters, ({ path, value, op }, { value: oldVal }) => {
      if (op === 'replace') {
        const filt = store.ticket.gridFilters.get(path.split('/')[1]).value;
        filt.forEach(({ id, value: v }) => {
          const col = table.getColumn(id);
          if (value) {
            col.columnDef.enableColumnFilter = false;
            col.setFilterValue(() => v);
          }
          else {
            col.columnDef.enableColumnFilter = true;
            col.setFilterValue(() => { return undefined; });
          }
        });
      }
    });
    const p2 = onPatch(store.ticket, ({ op, path }) => {
      if (path === "/currentView" && op === 'replace') {
        table.resetColumnFilters();
        table.resetSorting();
        table.refs.tableContainerRef.current.scrollTo({ top: 0, left: 0, behavior: "auto" });
        table.setColumnVisibility((_) => store.ticket.columnsVisibility);
        /* const oldFV = store.ticket.filteredViewsMap.get(oldVal || "");
         * const currentFV = store.ticket.filteredViewsMap.get(value || "");
         * if (oldFV?.hideColumns || currentFV?.hideColumns) {
         *   table.setColumnVisibility(old => ({ unread: false, ...(currentFV?.hideColumns || {}) }));
         * } */
      }
    });
    return () => {
      p();
      p2();
    }
  }, []);
  /* useEffect(() => {
   *   const fV = store.ticket.filteredViewsMap.get(store.ticket.currentView || "");
   *   if(fV && fV.columnOverrides)
   * }, [store.ticket.consolidatedGridKeys]); */
}
