import {makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
    paper: props => ({
      borderStyle: "solid",
      borderColor: "#303E70",
      borderRadius: ".2em",
      borderWidth: "2px",
    }),
    root : {
      display: "flex", 
      justifyContent: "space-between", 
      alignItems: "center", 
      background: "#FFF", 
      borderTop: "0.01em solid #E9E9E9", 
      borderLeft: "0.01em solid #E9E9E9", 
      borderRight: "0.01em solid #E9E9E9", 
      padding: "0px 15px"
    },
    title: {
      fontSize: "0.95rem",
      fontWeight: 700,
      flexGrow: 9,
      color: '#585858'
    },
    id: {
      fontSize: "0.75em",
      fontWeight: 100,
      color: "#585858"
    },
    saveDiv: {
      paddingRight: 20
    },
    saveButton: {
      fontSize: "0.9em",
      // color: "#005eff",
      color: "#D9D9D9",
      // cursor: "pointer",
      fontWeight: 600,
      "&:hover" : {
        // borderBottom: "1px solid #005eff",
        // textDecoration: "underline"
      }
    },
    closeDiv: {
      paddingRight: 0,
      width: '12%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    closeButton: {
      fontSize: "0.9em",
      fontWeight: 600,
      color: "#EA3434",
      cursor: "pointer",
      "&:hover" : {
        borderBottom: "1px solid #EA3434",
        // textDecoration: "underline"
      }
    },
    deleteIcon: {
      "&:hover": {
        // backgroundColor: "#1a6fef",
        color: "red",
        cursor: 'pointer'
      }
    },
    deleteActionText: {
      padding: '0.25em 0.5em'
    },
    deleteSure: {
      marginTop: '0.25em',
      padding: '0.25em 0.5em',
      display: 'flex',
      background: '#005eff',
      color: '#FFF',
      opacity: 0.9,
      justifyContent: 'center',
      fontWeight: 'bold',
      "&:hover": {
        cursor: 'pointer',
        opacity: 1,
      }
    }
  }));

  export default useStyles