import React from 'react';
import { observer } from 'mobx-react-lite';
import { IconBellCheck, IconBellBolt } from '@tabler/icons-react';
import { ITicket } from '../../models/Ticket';
import { Tooltip, Popover } from '@mantine/core'
import useStyles from './ToggleWatcherV2Styles';

export interface IToggleWatcherProps {
  toggleState: boolean;
  ticket: ITicket;
};

const itemStyle = { display: 'flex', padding: '0.25em 0.5em', justifyContent: 'flex-start', alignItem: 'center' }

const ToggleWatcherV2 = ({ toggleState, ticket }: IToggleWatcherProps) => {
  const classes = useStyles({ state : toggleState })
  return (
  <div style={{ display: "flex", alignItems: 'center' }}>
    <Popover position="bottom" withArrow shadow="md">
        <Tooltip label={`${!toggleState ? "Notifying only on assignment." : "Notifying on all activity for this ticket"}`} position='left'>
        <Popover.Target>
          <span
            style={{ cursor: 'pointer', display: "flex", alignItems: 'center' }}
            // onClick={() => { ticket.addOrRemoveWatcher({ op: toggleState ? 'remove' : 'add' }); }}
            >
            {toggleState ? <IconBellCheck width={"1.25em"} height={"1.25em"} className={classes.notiIcon} /> : <IconBellBolt width={"1.25em"} height={"1.25em"} className={classes.notiIcon} />}
          </span>
        </Popover.Target>
        </Tooltip>
        <Popover.Dropdown>
            <div style={{display: 'flex', flexDirection: 'column'}}>
            <span className={classes.item} onClick={() => { ticket.addOrRemoveWatcher({ op: 'add' }); }} style={{fontWeight: toggleState ? 600 : 500, cursor: toggleState ? 'not-allowed' : 'pointer'}} > <IconBellCheck width={'1.25em'} height={'1.25em'} style={{marginRight: '0.5em'}} /> Notify on all activity</span>
            <span className={classes.item} onClick={() => { ticket.addOrRemoveWatcher({ op: 'remove' }); }} style={{fontWeight: toggleState ? 500 : 600, cursor: toggleState ? 'pointer' : 'not-allowed'}}> <IconBellBolt width={'1.25em'} height={'1.25em'} style={{marginRight: '0.5em'}} /> Notify only on assignment</span>
            </div>
        </Popover.Dropdown>
      </Popover>
    {/* <span>{`You are${toggleState ? "" : " not"} watching this ticket`}</span> */}
    {/* <span style={{ fontSize: '0.9em', marginLeft: '0.1em', opacity: 0.5 }}>{`${toggleState ? "  (On all acitivities)" : "  (Only on assignment)"}`}</span> */}
  </div>
)};

export default observer(ToggleWatcherV2);
