import React, { memo, PropsWithChildren }  from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { Divider } from '@material-ui/core';
import { Avatar, Tooltip } from '@mantine/core';
import { getInitials } from '../utils/utils';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    // alignItems: 'flex-start',
    justifyContent: "center",
    width: '100%',
    padding: '0.5rem'
  },
  ellipse: {
    display: 'flex',
    justifyContent: 'center',
    marginRight: '0.150rem',
    paddingTop: '0.3em'
  },
  letter: {
    fontSize: '0.875em',
    fontWeight: 600,
  },
  rectangle: {
    width: '90%',
    // height: '100px',
    backgroundColor: '#FFFFFF',
    padding: '10px',
    wordBreak: 'break-word',
    borderRadius: '4px',
    border: 'solid 0.001em #cfd6dc'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '0.4rem'
  },
  name: {
    textAlign: 'left',
    fontWeight: 600,
    fontSize: '0.85em'
  },
  date: {
    textAlign: 'right',
    fontSize: '0.775em',
    color: '#808080',
    opacity: 0.6
  },
  body: {
    /* Body Styles */
    paddingTop: '0.6rem',
    fontSize: '0.8rem'
  },
});

const thirtyDaysInMilliseconds = 2592000000

const CommentCard = ({ user, createdAt, you, children, type }: PropsWithChildren<{ user: string; createdAt: Date; you: boolean; type: string | undefined }>) => {
  const classes = useStyles();
  const dateTime = moment(moment.now()).diff(moment(createdAt)) > thirtyDaysInMilliseconds ? moment(createdAt).format('MMM DD') : moment(createdAt).fromNow();
  return (
    <div className={classes.container}>
      <div className={classes.ellipse}><Avatar color="cyan" size={'sm'} radius={"xl"}>{getInitials(user ? user : 'System')}</Avatar></div>
      <div className={classes.rectangle}>
        <div className={classes.header}>
          <div className={classes.name}>{user ? user : 'System'} <span style={{fontWeight: 500, opacity: 0.5}}>{you ? ' (You) ' : null}{type === "media" ? 'attached files' : 'commented'}</span></div>
          <Tooltip style={{fontSize: '0.85em'}} label={moment(createdAt).format('MMM DD, YYYY hh:mm A')}><div className={classes.date}>{dateTime}</div></Tooltip>
        </div>
        {/* <Divider /> */}
        <div className={classes.body}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default memo(CommentCard);
