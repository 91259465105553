import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import arrowActiveIcon from '../../assets/images/Arrow-Circle-active.svg'
import arrowDisabledIcon from '../../assets/images/Arrow-Circle-Disabled.svg'
import { extendStyles } from "../../utils/utils";

const hRight = {
  width: "80.5%",
  display: "flex",
  justifyContent: "space-between",
  paddingLeft: "24px",
  paddingRight: "24px",
  backgroundColor: "#f4f4f4",
  height: "50px",
};

const hRightSm = extendStyles(hRight, {
  width: '93%'
});

const hRightPowerbi = extendStyles(hRight, {
  width: '100%'
})
const hRightIp = extendStyles(hRight, {
  width: '95%'
});

interface IProps {
  width: string,
  backgroundColor: string,
  color: string,
  fullscreenpages: boolean,
  infopanelOpen: boolean
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexShrink: 0,
      '@media print': {
        display: "none"
      },
    },
    rightHeaderDiv: {
      display: "flex",
      alignItems: "center",
      width: "54%",
      justifyContent: "space-between"
    },
    rightHeaderDivsm: {
      display: "flex",
      alignItems: "center",
      width: "55%",
      justifyContent: "space-between"
    },
    blockVilla: {
      display: "flex",
      alignItems: "center",
      // width: "112px",
      height: "100%",
      // fontFamily: "SourceSansPro",
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.29,
      letterSpacing: "normal",
      textAlign: "center",
      color: "#2f3e6c",
      justifyContent: "space-between",
      flexGrow: 9
    },
    TabDetails: {
      display: "flex"
    },
    TabBlockdetails: {
      display: "flex",
      color: "#2f3e6c",
      alignItems: "center",
      width: "154px",
      justifyContent: "space-between"
    },
    miniverticalDivider: {
      width: "1px",
      height: "12px",
      marginTop: "1px",
      backgroundColor: "#2f3e6c",
    },
    TabName: {
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      marginRight: "4px",
      color: "#2f3e6c",
      textTransform: "capitalize"
    },
    userProfile: {
      display: "flex",
      justifyContent: "space-between",
      lineHeight: 2.75
    },
    userNameText: {
      alignSelf: "center",
      fontSize: "14px",
      textTransform: "capitalize"
    },
    profileBottom: {
      display: "flex",
      justifyContent: "space-between",
    },
    logout: {
      backgroundColor: "rgb(255, 255, 255)",
      color: "#005eff",
      textTransform: "capitalize",
      "&:hover": {
        // backgroundColor: "rgb(255, 255, 255)"
      },
    },
    paperWidth: {
      width: 275
    },
    propertySelect: {
      fontWeight: "bold",
      color: "#005eff",
      cursor: "pointer",
      "&:hover": {
        fontWeight: "bold",
        color: "#585858",
        cursor: "pointer",
        backgroundColor: "rgb(255, 255, 255)"
      }
    },
    headerRight: (props: IProps) => props.width === "100%" ? hRightPowerbi : hRight,
    infopanelheaderRight: hRightIp,
    headerRightSm: (props: IProps) => props.width === "100%" ? hRightPowerbi : hRightSm,
    headerRightWithoutInfo: (props: IProps) => ({
      width: props.width,
      display: "flex",
      justifyContent: "space-between",
      paddingLeft: "24px",
      paddingRight: "24px",
      height: "50px",
      backgroundColor: "#f4f4f4"
    }),
    header: {
      alignSelf: "center",
      fontSize: "14px",
      textTransform: "uppercase",
      color: "#2f3e6c",
      display: "flex",
      fontWeight: "bold",
      paddingLeft: "4px",
      fontFamily: "Source Sans Pro, sans-serif",
      maxWidth: "510px",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis"
    },
    headerSm: {
      alignSelf: "center",
      fontSize: "14px",
      textTransform: "uppercase",
      color: "#2f3e6c",
      display: "flex",
      fontWeight: "bold",
      overflow: "hidden",
      paddingLeft: "4px",
      fontFamily: "Source Sans Pro, sans-serif",
      maxWidth: "300px"
    },
    ellipsisText: {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden"
    },
    headerLeft: {
      width: "19.5%",
      display: "flex",
      justifyContent: "center",
      fontWeight: "bold",
      height: "50px",
      // paddingLeft: "16px",
      // paddingRight: "16px",
    },
    headerLeftSm: {
      width: "8%",
      display: "flex",
      justifyContent: "center",
      fontWeight: "bold",
      // paddingLeft: "1%",
      flexDirection: "column"
    },
    InfopanelheaderLeft: {
      width: "5%",
      display: "flex",
      justifyContent: "center",
      fontWeight: "bold"
      // paddingLeft: "16px",
      // paddingRight: "16px",
    },
    headerLeftWithoutInfo: {
      width: "20%",
      display: "flex",
      justifyContent: "center",
      fontWeight: "bold"
      // paddingLeft: "16px",
      // paddingRight: "16px",
    },
    propertyName: {
      margin: "7px 0 8px 8px",
      fontSize: "12px",
      fontWeight: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      color: "#808080"
    },
    right: {
      flex: 1
    },
    // headerActivityInfo: {
    //   // color: "#2f3e6c",
    //   display: "flex",
    //   fontSize: "14px",
    //   alignSelf: "center",
    //   textTransform: "uppercase",
    //   width: "100%",
    //   justifyContent: "center",
    //   fontWeight: "bold",
    //   paddingLeft: "4px"
    // },
    homeButton: {
      marginRight: "9px",
      outline: "solid 0px #005eff",
      transition: "outline-width 0.1s linear",
      width: "26px",
      height: "26px",
      objectFit: "contain",
      // "&:hover": {
      //   fontWeight: "bold",
      //   color: "#585858",
      //   cursor: "pointer",
      //   backgroundColor: "rgb(255, 255, 255)",
      //   outlineWidth: "1px"
      // }
    },
    homeButtonWOimg: {
      marginRight: "9px",
      outline: "solid 0px #005eff",
      transition: "outline-width 0.1s linear",
      width: "30px",
      height: "30px",
      objectFit: "contain",
      // "&:hover": {
      //   fontWeight: "bold",
      //   color: "#585858",
      //   cursor: "pointer",
      //   backgroundColor: "rgb(255, 255, 255)",
      //   outlineWidth: "1px"
      // }
    },
    divider: {
      backgroundColor: "#edebeb",
      position: "absolute",
      width: "246px",
      marginLeft: "81%",
      marginRight: "2%",
      // right: "20px",
      top: "94%"
    },
    dividerWithoutInfo: {
      backgroundColor: "#fff",
      position: "absolute",
      width: "246px",
      marginLeft: "81%",
      marginRight: "2%",
      // right: "20px",
      top: "94%",
      display: "none"
    },
    menuButton: {
      marginRight: -8,
      borderRadius: 0,
      top: 8,
      paddingTop: 6,
      height: 32,
      width: 32
    },
    ellipse: {
      width: "3px",
      height: "1px",
      backgroundColor: "black",
      // borderRadius:"50%", 
      alignSelf: "center",
      margin: "4px"
    },
    backdrop: {
      zIndex: 9999,
      background: '#fff',
      minWidth: "1024px",
      maxWidth: "1377px",
      marginLeft: "auto",
      marginRight: "auto",
      height: "99vh",
    },
    title: (props: IProps) => ({
      backgroundColor: props.backgroundColor,
      flexGrow: 1
    }),
    menuClick: {
      color: "#2f3e6c",
      overflow: "hidden",
      position: "relative",
      display: "flex",
      flexDirection: "row",
      flexWrap: "nowrap",
      justifyContent: "space-between",
    },
    left: (props: IProps) => ({
      flex: 1,
      display: "flex",
      marginLeft: props?.infopanelOpen ? "0px" : "15px",
      justifyContent: props?.infopanelOpen ? "center" : "unset",
      cursor: "pointer",
      transition: "0.5s",
      position: "relative",
      alignItems: "center",
      "&:after": {
        position: "absolute",
        opacity: 0,
        top: 0,
        right: "-15px",
        transition: "0.5s",
      },
      "&:hover": {
        color: "#005eff",
        paddingLeft: "8px",
        opacity: 1,
        right: 0,
      }
    }),
    verticalDivider: {
      width: "2px",
      height: "12px",
      backgroundColor: "#cecece"
    },
    backPageButton: {
      width: "37px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginRight: "20px"
    },
    BlockName: {
      color: "#2f3e6c",
      fontWeight: "bold"
    },
    listOfFloorsandActivities: {
      width: "197px",
      height: "18px",
      display: "flex",
      alignSelf: "center",
      justifyContent: "space-between",
      margin: "1rem",
      color: "#2f3e6c",
      lineHeight: 1.29,
      letterSpacing: "normal",
      fontFamily: 'Source Sans Pro, sans-serif',
    },
    changeImage: {
      width: "30px",
      height: "23px",
      display: "flex",
      alignItems: "center",
      color: '#005eff',
      background:
        `url(${arrowActiveIcon}) no-repeat`,
      // marginLeft: "16px",
      "&:hover": {
        "&:before": {
          background:
            `url(${arrowDisabledIcon}) no-repeat`,
        },
        background:
          `url(${arrowDisabledIcon}) no-repeat`,
      }
    },
    notification: {
        alignSelf: "center",
        fontSize: "14px",
        textTransform: "uppercase",
        color: "#2f3e6c",
        display: "flex",
        fontWeight: "bold",
        paddingLeft: "4px",
        fontFamily: "Source Sans Pro, sans-serif",
        // maxWidth: "510px",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis"
    }
  })
);

export default useStyles;
