import React, { Dispatch, memo, Reducer, ReducerAction, SetStateAction, useEffect, useReducer, useState } from 'react';
import { Button, Checkbox, Chip } from '@material-ui/core';
import { IChan, putAsync } from 'csp-with-ts';
import useStyles from './LocationPickerStyles';
import { ILocationPanel, IMultiPanelState, MultiPanelActions, multiPanelSelectReducer, useLocationPanels } from '../../utils/hooks';
import { Button as MantineButton, Skeleton, CloseButton, Grid } from '@mantine/core';

// TODO: Refactor out into its own file.
export interface IOption {
  id: string;
  name: string;
  isSelected: boolean;
  cb(): void;
};

/* const Option = memo(({ id, name, cb, isSelected }: IOption) => (
 *   <div style={{ cursor: "pointer", alignSelf: "center", justifyContent: "center", height: "2em", width: "100%", display: "flex", backgroundColor: isSelected ? "#005EFF" : "inherit", color: isSelected ? "white" : "black", }} onClick={cb}>
 *     <Checkbox
 *       checked={isSelected}
 *     />
 *     {name}
 *   </div>
 * )); */
const Option = memo(({ id, name, cb, isSelected }: IOption) => {
  const classes = useStyles();
  return (
    <div className={classes.listItem}
      onClick={cb}
    >
      <Checkbox
        checkedIcon={
          <span className={`${classes.icon} ${classes.checkedIcon}`}></span>
        }
        icon={
          <span className={classes.icon} />
        }
        checked={isSelected}
        value={id}
      />
      {name}
    </div>
  )
});
export interface IPanelProps {
  id: string;
  name: string;
  options: { id: string; name: string; floor?: number; block?: string }[];
  state: IMultiPanelState;
  dispatch: Dispatch<ReducerAction<Reducer<IMultiPanelState, { type: MultiPanelActions; payload: { [K: string]: any; } }>>>;
  button?: {
    label: string;
    callback?(): void;
  };
  onMount?(): void;
  prev: string | null;
};

const Panel = memo(({ id, name, options, state, dispatch, button, onMount, prev }: IPanelProps) => {
  useEffect(() => { if (onMount) { onMount(); } }, []);
  useEffect(() => { if (prev && state.selection[prev].length > 1 && state.selection[id].length) { dispatch({ type: MultiPanelActions.REPLACE_STATE, payload: { section: id, selection: [] } }); } }, [state.selection, prev]);
  const classes = useStyles();
  const multipleOptionName = name == "Floor" ? "Blocks" : name == "Unit" ? "Floors" : name
  return (
    <div className={classes.panelRoot}>
      <div className={classes.panelTitle}>{name}</div>
      {state.currentPanel === id || state.selection[id].length || options.length ?
        (<div
          className={classes.panel}
          // style={state.currentPanel === id ? {} : { backgroundColor: "#D3D3DF", filter: "blur(5px)" }}
          onClick={() => {
            if (state.currentPanel !== id) {
              dispatch({
                type: MultiPanelActions.SET_CURRENT_PANEL,
                payload: { section: id }
              });
            }
          }}
        >
          {options.map(({ id: idd, name }) => (
            <Option
              key={idd}
              id={idd}
              name={name}
              isSelected={!!state.selection[id]?.includes(idd)}
              cb={() => { dispatch({ type: MultiPanelActions.PUSH_STATE, payload: { section: id, id: idd } }); }}
            />
          ))}
        </div>)
        : prev && state.selection[prev].length < 2 ? button ? (
          <div
            className={classes.buttonPane}
            style={state.currentPanel === id ? {} : { backgroundColor: "#EDEDED" }}
          >
            <Button
              className={classes.button}
              disabled={!state.selection[prev].length}
              onClick={() => {
                dispatch({ type: MultiPanelActions.SET_CURRENT_PANEL, payload: { section: id } });
                if (button.callback) { button.callback(); }
              }}
            >{button.label}</Button>
          </div>
        ) : null
          : <div className={classes.multiSelectScenario}>
          <span>
            {name} cannot be selected since you have selected mutliple {multipleOptionName}. <br />
            You can still add [{state.selection[prev || 0].length}] selected {multipleOptionName}.
          </span>
          </div>
      }
    </div>
  );
});



export interface IPanel {
  id: string;
  name: string;
  button?: {
    label: string;
    callback?(): void;
  }
};

export interface ILocationPicker {
  panels: ILocationPanel[];
  ch: IChan<{ type: string; payload: any; }>;
  init: { [K: string]: string; }[];
};

const LocationPicker = ({ panels, ch, init }: ILocationPicker) => {
  const [state, dispatch] = useReducer(multiPanelSelectReducer, {
    currentPanel: panels[0]?.id, selection: panels.reduce((acc, { id }) => ({ ...acc, [id]: [] }), {})
  });
  const [finalState, setFinalState] = useState<string[]>(() => init.map(({ id }) => id));
  const { panels: locationPanels, options, lookup } = useLocationPanels(panels, state, init.reduce((acc: { [K: string]: { [K: string]: string }; }, val) => ({ ...acc, [val.id]: { ...val } }), {}));
  const classes = useStyles();
  useEffect(() => { putAsync(ch, { type: "panel", payload: panels.find(({ id }) => id === state.currentPanel)?.name || "" }); }, [state.currentPanel]);
  return (
    <div className={classes.root}>
      <div className={classes.panels}>
        {locationPanels.map(({ id, name, button }, idx) => (
          <Panel
            key={id}
            id={id}
            name={name}
            state={state}
            dispatch={dispatch}
            button={button}
            options={options[id]}
            onMount={idx ? undefined : button.callback}
            prev={idx ? locationPanels[idx - 1].id : null}
          />
        ))}
      </div>
      <div className={classes.addLocationButtonDiv}>
        <Button
          disabled={!!!state.selection[state.currentPanel].length}
          className={classes.button}
          onClick={() => {
            const finState = [...finalState, ...state.selection[state.currentPanel].filter(id => !finalState.includes(id))];
            setFinalState(() => [...finState]);
            putAsync(ch, {
              type: "state", payload: [...finState.map(id => lookup[id])]
            });
            dispatch({
              type: MultiPanelActions.RESET_STATE, payload: { panels: panels.map(({ id }) => id) }
            });
          }}
        >
          Add Locations
        </Button>
      </div>
      <div className={classes.selectionDiv}>
        <span style={{paddingBottom: "0.5em"}}>Selected Locations:</span>
        <div className={finalState.length ? classes.selectionArea : classes.selectionAreaNone}>
          {/* {finalState.length ?
            finalState.map(id => (
              <Chip
                key={id}
                label={lookup[id].name}
                onDelete={() => {
                  const finState = finalState.filter(fid => fid !== id);
                  setFinalState(() => [...finState]);
                  putAsync(ch, { type: "state", payload: [...finState.map(id => lookup[id])] });
                }}
                // deleteIcon={<span>⊗</span>}
                deleteIcon={<CloseButton />}
              />
            ))
            : (<span style={{color: "#585858", opacity: "0.5", fontSize: "0.8em"}}> You have not added any location. </span>)
          } */}

          {finalState.length ? <Grid style={{ width: "100%" }}>
            <Grid.Col className={classes.selectionGridColumns} span={4}>{finalState.map(id => {
              if (lookup[id].unit_type == 'block') {
                return (
                  <div className={classes.chip}>
                    <Chip
                      variant="outlined"
                      key={id}
                      label={lookup[id].name}
                      // style={{padding: "1em"}}
                      onDelete={() => {
                        const finState = finalState.filter(fid => fid !== id);
                        setFinalState(() => [...finState]);
                        putAsync(ch, { type: "state", payload: [...finState.map(id => lookup[id])] });
                      }}
                      deleteIcon={<CloseButton radius={'xl'} size={'xs'} color='red' variant='subtle'/>}
                    />
                  </div>
                )
              }
            })}</Grid.Col>
            <Grid.Col className={classes.selectionGridColumns} span={4}>{finalState.map(id => {
              if (lookup[id].unit_type == 'floor') {
                return (
                  <div className={classes.chip}>
                    <Chip
                      variant="outlined"
                      key={id}
                      label={lookup[id].name}
                      // style={{padding: "1em"}}
                      onDelete={() => {
                        const finState = finalState.filter(fid => fid !== id);
                        setFinalState(() => [...finState]);
                        putAsync(ch, { type: "state", payload: [...finState.map(id => lookup[id])] });
                      }}
                      deleteIcon={<CloseButton radius={'xl'} size={'xs'} color='red' variant='subtle'/>}
                    />
                  </div>
                )
              }
            })}</Grid.Col>
            <Grid.Col className={classes.selectionGridColumns} span={4}>{finalState.map(id => {
              if (lookup[id].unit_type !== 'block' && lookup[id].unit_type !== 'floor') {
                return (
                  <div className={classes.chip}>
                    <Chip
                      variant="outlined"
                      key={id}
                      label={lookup[id].name}
                      // style={{padding: "1em"}}
                      onDelete={() => {
                        const finState = finalState.filter(fid => fid !== id);
                        setFinalState(() => [...finState]);
                        putAsync(ch, { type: "state", payload: [...finState.map(id => lookup[id])] });
                      }}
                      deleteIcon={<CloseButton radius={'xl'} size={'xs'} color='red' variant='subtle'/>}
                    />
                  </div>
                )
              }
            })}</Grid.Col>
          </Grid> : (<span style={{ color: "#585858", opacity: "0.5", fontSize: "0.8em" }}> You have not added any location. </span>)
          }
        </div>
      </div>
    </div >
  );
};
export default memo(LocationPicker);
